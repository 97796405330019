import React, { useContext, useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AssignBenchmarkModal from "../../components/pageModals/AssignBenchmarkModal";
import { getMuiTheme, headerProps } from './commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";
import ViewAccount from "../../components/pageModals/ViewAccount";
import AddTechActivityModal from "../pageModals/AddTechActivityModal";
import moment from 'moment-timezone';
import Snackbar from '@material-ui/core/Snackbar';
import { connect, useDispatch, useSelector } from 'react-redux';
import {CommonsliceActions} from "../../store/Common"
import {benchmarksupdatetimings,benchmarksdeleteDemoRequest,poststartBenchmark,BenchmarkActions,getColumnsList} from "../../store/Benchmark"
import {ContactColumnUpdateddata} from "../../store/Common"
import TextField from '@material-ui/core/TextField';
import { styled  } from '@mui/system';

const CustomInput = styled('input')({
  marginTop: '5px',
  padding: '2px 13px !important', // Adjust the padding as needed
});

export const BenchmarkTable = React.memo(({user,showModal_assign,showModal_activity,show_demoModal,getbenchmarklistdata
  ,benchmarklist,banchmarktiming,postBenchmark,benchmarkdata,isFetching,benchmarkcolumnupdate,benchmarkid,assign_openModal,addActivityRowid,activityopenmodal
,viewaccountopenmodal,rowMetaData,rowId,getColumnsList,columnsList,updateColumnsListData}) => {

  const dispatch = useDispatch();
const Benchmarkdata = benchmarklist
  const userData = user.user;
  const permissions = user.permissions;
  const [leadTypeActive, setLeadTypeActive] = useState("all");
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState();

  useEffect(() => {
    getColumnsList();
  
  }, []);


  const onCellClickHandler = (meta) => {
    if (meta.colIndex === 1) {
      viewaccountopenmodal()
      rowMetaData(meta);
      rowId(tableData[meta.dataIndex].account_id?._id)
    }
  }
  const startBenchmark = async (id, element) => {

    var updatedData = {
      'status': "started",
      'benchmark_id': id
    }
    postBenchmark(updatedData)
  }
  
  const completedBenchmark = async (id, element) => {
    var updatedData = {
      'status': "completed",
      'benchmark_id': id
    } ///start toast
    banchmarktiming(updatedData);
  }



  const assignTechnical = async (event, data, meta) => {

    event.stopPropagation();
    assign_openModal()
    benchmarkid(meta) 
  }

  useEffect(() => {
    filterDemoType("pending")
    setLeadTypeActive("pending");
  }, [Benchmarkdata]);


  const updateActivity =  async (event, data, meta)=> {
    event.stopPropagation();
    addActivityRowid(meta)
      activityopenmodal()
}


const deleteDemoRequest =  async (event, data, meta) => {
  event.stopPropagation();
    var updatedData = {
      'benchmark_id': meta,
    }
benchmarkdata(updatedData)
}

  const columns = [
    {
      name: "_id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "account_id",
      label: "Account Name",
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value.company_name : "Organization not found"
        ),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
            color: '#243d6a',
            fontWeight: 'bold',
            maxWidth: '500px',
            overflow: 'auto',
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 1,
            background: "#e6e6e6",
            zIndex: 101,
            textColor: '#FFFFFF',
            maxWidth: '500px',
            overflow: 'auto',
          }
        })
      }
    },
    {
      name: "requestor_id",
      label: "Requested by",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('requestor_id') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value.contact_name : "contact name not found"
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "assigned_to",
      label: "Techie Name",
      options: {
        customBodyRender: (value) => (
          value ? value.first_name + ' ' + value.last_name : "Not assigned  yet"
        ),
        display: columnsList?.includes('assigned_to') ? true : false,
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "subject",
      label: "Subject",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('subject') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "project_name",
      label: "Benchmark Name",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('project_name') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "approval_status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        display: columnsList?.includes('approval_status') ? true : false,
        onRowClick: false,
        setCellHeaderProps: headerProps,
        customBodyRender: (value, tableMeta, updateValue) => {
          var status = tableMeta.rowData[7]
          if (permissions.includes('canAssignBenchmarks')) {
            return (
              <div style={{ display: "flex" }}>
                <a onClick={(e) => { assignTechnical(e, value, tableMeta.rowData[0]) }}>
                  <button className="btn-sm btn-group-sm btn btn-table-commone btn-success">{value === "pending" ? "Assign" : "Re-Assign"}</button>
                </a>

              </div>
            );
          } else {
            return <>
                {
                  permissions.includes('deleteOwnBenchmarks') && value === "pending" ? 
                    <button onClick={(e) => deleteDemoRequest(e, value, tableMeta.rowData[0])} className='btn-sm btn-group-sm btn btn-table-commone btn-danger'>Delete</button>
                    : <span style={{ textTransform: 'capitalize' }}>{value}</span>
                }
            </>
          }
        }
      }
    },
    {
      name: "status",
      label: "Actions",
      options: {
        filter: true,
        sort: false,
        empty: true,
        display: columnsList?.includes('status') ? true : false,
        onRowClick: false,
        setCellHeaderProps: headerProps,
        customBodyRender: (value, tableMeta, updateValue) => {
          var approval_status = tableMeta.rowData[6]
          if (value === "pending" && approval_status === "approved") {
            return (
              <div style={{ display: "flex" }}>
                {permissions.includes("updateOwnBenchmarksTimings") || permissions.includes("updateAnyBenchmarksTimings") ?
                  <a onClick={startBenchmark.bind(value, tableMeta.rowData[0])}>
                    <button className="btn-sm btn-group-sm btn btn-table-commone btn-success">Start</button>
                  </a>
                  : false}
              </div>
            );
          } else if (value === "started") {
            return (
              <div>
                {permissions.includes("updateOwnBenchmarksTimings") || permissions.includes("updateAnyBenchmarksTimings") ?
                  <a onClick={completedBenchmark.bind(value, tableMeta.rowData[0])}>
                    <button style={{ background: '#cd485c' }} className="btn-sm btn-group-sm btn btn-table-commone btn-danger">Complete</button>
                  </a>
                  : false}
              </div>

            )

          }
          else if (value === "completed") {
            return "Completed"
          } else {
            return "Approval Pending"
          }
        }

      }
    },
    {
      name: "status",
      label: "Add Activity",
      options: {
        filter: true,
        sort: false,
        empty: true,
        display: columnsList?.includes('approval_status') ? true : false,
        onRowClick: false,
        setCellHeaderProps: headerProps,
        customBodyRender: (value, tableMeta, updateValue) => {
          var status = tableMeta.rowData[6]
          if (value === "started" && status !== "completed") {
            return (

              
              <div style={{ display: "flex" }}>
                <a onClick={(e) => { updateActivity(e, value, tableMeta.rowData[0]) }}>
                  <button className="btn-sm btn-group-sm btn btn-table-commone btn-success">Add Activity</button>
                </a>
              </div>
            );
          }
          else {
            return <span style={{ textTransform: 'capitalize' }}>{value}</span>;
          }
        }

      }
    },
    {
      name: "inputs_given",
      label: "Input Given",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('inputs_given') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "started_at",
      label: "Started",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('started_at') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "completed_at",
      label: "Completed",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
        display: columnsList?.includes('completed_at') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "boundary_conditions",
      label: "Boundary Conditions",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('boundary_conditions') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "scope_of_work",
      label: "Scope of Work",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('scope_of_work') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "success_criteria",
      label: "Success Criteria",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('success_criteria') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "poc_timeline",
      label: "POC Timeline",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('poc_timeline') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "products",
      label: "Product",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('products') ? true : false,
        setCellHeaderProps: headerProps,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value.map(item => item?.name).join(', ') : "product not found"
        ),
      }
    },
    {
      name: "poc_execution_deliverables",
      label: "POC Execution & Deliverables",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('poc_execution_deliverables') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "initial_discussion",
      label: "Comments",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('initial_discussion') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
  ];

  const filterDemoType = async (type) => {

    setLeadTypeActive(type);
    var data = [];
    if (type === "all") {
      data = Benchmarkdata;
    } else {
      data = Benchmarkdata?.filter(function (item) {
        if (item.approval_status === type) {
          return item;
        }
      });
    }
    setTableData(data);
  }

  const LoaderText = () => {
    return   isFetching ? <div className="parent-div">
    <div className="loader"></div>
  </div>:"No Data Found.." 
 }


  const options = {
    textLabels: {
      body: {
        noMatch: LoaderText()
      }
    },
    rowsPerPage: window.innerHeight > 720 ? 20 : 15,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: (window.innerHeight - 130).toString() + 'px',
    tableBodyMaxHeight: (window.innerHeight - 130).toString() + 'px',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: "none",
    print: false,
    onViewColumnsChange: async (changedColumn, action) => {
      var columns = Object.assign([], columnsList)
      if (action === "add") {
        columns.push(changedColumn);
      }

      if (action === "remove") {

        var columns = columns.filter(item => item !== changedColumn);
    }

      const updatedData = {
        user_id: userData._id,
        table_name: "benchmarks",
        columns_list: columns,
      }
      benchmarkcolumnupdate(updatedData)
      updateColumnsListData(columns)

    },
    download: false,
    onCellClick: (colData, cellMeta) => onCellClickHandler(cellMeta),
  };

  const onDateChange = (e, type) => {
    setFilters({ ...filters, [e.target.name]: e.target.value }, []);
  }

  window.addEventListener('keydown', (e) => {

    // e.preventDefault();
    console.log("e.key", e.key)
    if (e.ctrlKey && (e.key === 's' || e.key === 'p')) {
        e.preventDefault();
    }
    // Prevent default actions for Print Screen (PrtScn)
    if (e.key === 'PrintScreen') {
          console.log("print screen")
          e.preventDefault();
    }
  
    // Prevent default actions for the Windows key
    if (e.key === 'Meta') { // Meta key represents the Windows key
      console.log("meta")
          e.preventDefault();
    }

  });

  // const classes = useStyles();

  // useEffect(() => {
  //   props?.filters(filters);
  // }, [filters])

  return (
    <>
    <div
            onContextMenu={(e) => {
                e.preventDefault(); // Prevent right-click menu
            }}
            style={{ position: 'relative' }} // Optional: Ensure proper layout
        >
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={<div style={{ display: "flex", flexDirection: "row" }} >
          <b>Benchmarks List</b>&nbsp;&nbsp;&nbsp;
          <span onClick={() => filterDemoType("all")} className={leadTypeActive === "all" ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> All </span>&nbsp;&nbsp;
          <span onClick={() => filterDemoType("pending")} className={leadTypeActive === "pending" ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> Pending </span>&nbsp;&nbsp;

          <small>
            {/* Start Date :  */}
                  <CustomInput variant="outlined" type="date"
                  style={{padding: '0px !important'}}
                      InputLabelProps={{
                          shrink: true,
                      }}
                      name="startDate" 
                      label="Start Date"
                      // className="form-control" 
                      // value={timesheetData.from_date} 
                      id="preliminary-review-start"
                      // className={classes.customInput}
                      onChange={(e) => onDateChange(e, "startDate")}
                  />
              </small>
              &nbsp;&nbsp;
              <small>
                  {/* End Date :  */}
                  <CustomInput variant="outlined" type="date"
                      InputLabelProps={{
                          shrink: true,
                      }}
                      label="End Date"
                      name="endDate" className="form-control" 
                      // value={timesheetData.to_date} 
                      id="preliminary-review-start"
                      onChange={(e) => onDateChange(e, "endDate")}
                  />
              </small>          
        </div>}
          data={tableData}
          columns={columns}
          options={options}
          className="no-select" 
        />


      </MuiThemeProvider>

      {showModal_assign ? <AssignBenchmarkModal/> : ""}
      {showModal_activity ? <AddTechActivityModal /> : ""}
      {show_demoModal ? <ViewAccount/> : ""}
</div>
    </>
  )


})

const mapStateToProps = (state) => ({

  user : state.auth.user,
  showModal_activity: state.Commonlist.showModal_activity,
    showModal_assign:state.Benchmarklist.showModal_assign,
    show_demoModal : state.Commonlist.demo_showModal,
     showModal_Viewcontact:state.Commonlist.showModal_Viewcontact,
     benchmarklist:state.Benchmarklist.benchmarklist,
     isFetching : state.Benchmarklist.isFetching,
     columnsList:state.Benchmarklist.columnsList
})

const mapDispatchToProps = (dispatch) => ({
  benchmarkcolumnupdate :(data) => dispatch(ContactColumnUpdateddata(data)),
  banchmarktiming :(data) => dispatch(benchmarksupdatetimings(data)),
  postBenchmark:(data) => dispatch(poststartBenchmark(data)),
  benchmarkdata:(data) => dispatch(benchmarksdeleteDemoRequest(data)),
  assign_openModal:(data) => dispatch(BenchmarkActions.assign_openModal(data)),
  addActivityRowid:(data) => dispatch(CommonsliceActions.setaddActivityRowId(data)),
  activityopenmodal:(data) => dispatch(CommonsliceActions.activity_openModal(data)),
  viewaccountopenmodal:(data) => dispatch(CommonsliceActions.demo_openModal(data)),
  rowMetaData:(data) => dispatch(CommonsliceActions.setrowMetaData(data)),
  rowId:(data) => dispatch(CommonsliceActions.setRowId(data)),
  benchmarkid:(data) => dispatch(BenchmarkActions.setbenchmarkListId(data)),
  getColumnsList:(data) => dispatch(getColumnsList()),
  updateColumnsListData:(data) => dispatch(BenchmarkActions.updateColumnsList(data))
});

  export default connect(mapStateToProps,mapDispatchToProps) (BenchmarkTable)