import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap'
import { DatePicker } from '@material-ui/pickers';
import { useForm } from "react-hook-form";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddNewContact from "../pageModals/AddNewContact";
import WonOpportunitiesPoModal from '../pageModals/WonOpportunitiesPoModal';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import TasksListTable from "../../components/commonColumns/TasksListTable";
import DemoTable from "../../components/commonColumns/DemoTable";
import BenchmarkTable from '../commonColumns/BenchmarkTable';
import TrainingTable from '../commonColumns/TrainingTable';
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import { IoIosArrowDropdown } from 'react-icons/io';
import DemoModal from "../../components/pageModals/DemoModal";
import BenchmarkModal from '../../components/pageModals/BenchmarkModal';
import TrainingModal from '../../components/pageModals/TrainingModal';
import Spinner from 'react-bootstrap/Spinner'
import MettingTable from "../../components/commonColumns/MettingTable"
import CustomizedSteppers from "./StagesMeeting";
import OpportunitiesViewChart from '../dashboard/OpportunitiesViewChart';
import { useSelector, connect } from 'react-redux';
import { toast } from 'react-toastify';
// import { FaUserEdit, FaUserAltSlash } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import { useDispatch } from 'react-redux';
import { AiOutlineCheck } from "react-icons/ai";

import {getLeadStagesList, getProductsList, getRegionsList, getBusinessTypesList, fetchTechiesList,getNewProductsList} from "../../store/dashboard";
import {getDrfStatusList, getEconomicBuyersList, getAccontManagersList} from "../../store/leads";
import {opportunitiesActions, getTrainingsList, getDemosListData,getBenchmarksList, addSalesActivityDetails,addNewOpportunityTask, updateForecast,UpdateOpportunityHandleDetails, getAnsysAccountManagersList, createNewOpportunity, getTechTeamManagers, getTechiesListByManager, reAssignTechie  } from "../../store/opportunities";

import {ContactActions } from "../../store/Contact"
import {CommonsliceActions} from "../../store/Common"

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from "axios";
import IsalesRequestModal from "../../components/pageModals/IsalesRequestModal.js"

const ViewOpportunities = React.memo(({newproductsLists,getNewProductsData,salesActivitiesList, leadSalesStagesList, businessTypesData, updateOpportunityModal, showOpportunityModal, viewAccountId, showOpportunitiesModal, viewRowId,rowid, getTrainingsData, getBenchmarksData,trainingsList,  benchmarksList, getDemosList, demosList, getLeadStagesData, getBusinessTypesData, regionsList, getRegionsData, productsList, getProductsListData, drfStatusList, getDrfStatusData, ansysAccountManagers, getAnsysAccountManagersData, getEconomicBuyersData, addSalesActivityData,UpdateOpportunityHandleData, updateForecastData, addNewOpportunityTaskData, accountManagersList, getAccontManagersListData, opportunityDetails,leadDetail,meetingsList,contactsList,opportunitiesTasks, chartData,AddcontactModal,openmodal, fetchTechiesListData, techiesList, createNewOpportunityModal,createNewOpportunityData, updateActiveTabData, activeTab, getTechTeamManagersData, techTeamManagersList, techiesListByManager, getTechiesListByManagerData, reAssignTechieData,missingProductName}) => {
  const dispatch = useDispatch();
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const permissions = user.permissions;
  const [OpportunityShow, setOpportunityShow] = useState(false);
  const [timeline, setTimeline] = useState(false);
  const [showPoDate, setShowPoDate] = useState(false);
  const [actionDate, setActionDate] = React.useState(moment(threedays).format('LLLL'));
  const [reminderDate, setReminderDate] = React.useState(moment(twodays).format('LLLL'));
  const [poDate, setPoDate] = React.useState(moment(twodays).format('LLLL'));
  const [open, setOpen] = React.useState(false);
  const [showNewActionItemDiv, setShowNewActionItemDiv] = useState(false);
  const [rotateChevron1, setRotateChevron1] = useState(false);
  const [showDemoModal, setShowDemoModal] = useState(false);
  const [showBenchmarkModal, setShowBenchmarkModal] = useState(false);
  const [showTrainingModal, setShowTrainingModal] = useState(false);
  const [disable, setDisable] = React.useState(false);
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [toggleState, settoggleTab] = useState(createNewOpportunityModal ? 1 : 2);
  const [productPoData, setProductPoData] = useState({})
  const [updated, setUpdated] = useState(false);
  const [companiesList, setCompaniesList] = useState();
  const [query, setQuery] = useState('');
  const [expandedCategories, setExpandedCategories] = useState([]);
  const [expandedProducts, setExpandedProducts] = useState([]);
  const [expandedSubproducts, setExpandedSubproducts] = useState([]);
  const [productsListData, setProductsListData] = useState(newproductsLists);
    const [isSearchListVisible, setIsSearchListVisible] = useState(true);
    const [subProductsList, setSubProductsList] = useState([]);
    const [showIsalesrequestModal, setShowIsalesrequestModal] = useState(false);
    const [showWonPoModal, setShowWonPoModal] = useState(false);
    const [pOAndQuationStatus,setPOAndQuationStatus] =useState(true)

    useEffect(() => {
      if(newproductsLists?.length>0){
          setProductsListData(newproductsLists)
      }
   },[newproductsLists])

   



  //  console.log("no of times it is printing")
   
   
   const handleSearch = (event) => {
    const searchTerm = event.target.value?.toLowerCase();
    setQuery(searchTerm);
  
    // Filter the data based on the search query
    const filteredData = newproductsLists.filter((category) =>
      category.name?.toLowerCase().includes(searchTerm) ||
      category.subproducts.some((subproduct) => subproduct.name?.toLowerCase().includes(searchTerm)) ||
      category.subproducts.some((subproduct) =>
        subproduct.childProducts.some((childProduct) => childProduct.name?.toLowerCase().includes(searchTerm))
      )
    );
//   console.log("filterd data are inside handle search", filteredData)
    // Update the state with the filtered data
    setProductsListData(filteredData);
  };
  useEffect(() => {
    if (query.trim() === '') {
      // If the query is empty, close all accordions
      setExpandedCategories([]);
      setExpandedProducts([]);
      setExpandedSubproducts([]);
    } else {
      // Open accordions based on search results
      const categoriesToExpand = productsListData
        .filter(
          (category) =>
            category.name?.toLowerCase().includes(query) ||
            category.subproducts.some((subproduct) => subproduct.name?.toLowerCase().includes(query)) ||
            category.subproducts.some((subproduct) =>
              subproduct.childProducts.some((childProduct) =>
                childProduct.name?.toLowerCase().includes(query)
              )
            )
        )
        .map((category) => category.name);

      setExpandedCategories(categoriesToExpand);
      // console.log('inside filter data are//', categoriesToExpand)
      const productsToExpand = productsListData.reduce((acc, category) => {
        category.subproducts.forEach((product) => {
          if (
            product.name?.toLowerCase().includes(query) ||
            product.childProducts.some((childProduct) =>
              childProduct.name?.toLowerCase().includes(query)
            )
          ) {
            acc.push(product.name);
          }
        });

        return acc;
      }, []);

      setExpandedProducts(productsToExpand);
   
      const subproductsToExpand = productsListData.reduce((acc, category) => {
        category.subproducts.forEach((subproduct) => {
          if (
            subproduct.name?.toLowerCase().includes(query) ||
            subproduct.childProducts.some((childProduct) =>
              childProduct.name?.toLowerCase().includes(query)
            )
          ) {
            acc.push(subproduct.name);
          }
        });

        return acc;
      }, []);

      setExpandedSubproducts(subproductsToExpand);
    }
  }, [query, productsListData]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Close the search list if the click is outside the search container
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setIsSearchListVisible(false);
        
        setExpandedCategories([]);
        setExpandedProducts([]);
        setExpandedSubproducts([]);
      }
    };

    // Attach the event listener to the document body
    document.body.addEventListener('click', handleOutsideClick);

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleCategoryAccordionChange = (categoryName) => (event, isExpanded) => {
    setExpandedCategories((prevExpanded) =>
      isExpanded
        ? [...prevExpanded, categoryName]
        : prevExpanded.filter((name) => name !== categoryName)
    );
  };

  const handleProductAccordionChange = (productName) => (event, isExpanded) => {
    setExpandedProducts((prevExpanded) =>
      isExpanded
        ? [...prevExpanded, productName]
        : prevExpanded.filter((name) => name !== productName)
    );
  };


// const childProductDataHandle = (listData, isChecked) => {
//     if (isChecked) {
//       // Checkbox is checked, add the data
//       setSubProductsList((prevSelected) => [...prevSelected, listData]);
//     } else {
//       // Checkbox is unchecked, remove the data
//       const updatedList = subProductsList.filter(item => item.product_id !== listData.product_id);
//       setSubProductsList(updatedList);
//     }
//   };

  const toggleTab = (index) => {
    // settoggleTab(index);
    dispatch(updateActiveTabData(index))
  };
  const formatWeekSelectLabel = (date, invalidLabel) => {
    return `${moment(date).format("MMM Do YYYY LT")}`
  };

  var today = new Date();
  var day = today.getDate() + 3;
  var months = today.getMonth() + 1; //January is 0!
  var year = today.getFullYear();
  var threedays = moment().add(3, 'days');
  var twodays = moment().add(2, 'days');

  var initialTimeLine = moment().subtract(1, 'years');
  var finalTimeLine = moment(initialTimeLine).add(30, 'M');

  const [meetingDate, setMeetingDate] = useState(today);
  const[currentSalesStage,setCurrentSalesStage] =useState("")
  var getDaysBetweenDates = function (startDate, endDate) {
    var now = startDate.clone(), dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format('MMM-YYYY'));
      now.add(1, 'M');
    }
    return dates;
  };
  var timeLineDateList = getDaysBetweenDates(initialTimeLine, finalTimeLine);

  const ta_task_name = useRef();
  const ta_task_description = useRef();
  const ta_action_date = useRef();
  const ta_reminder_date = useRef();

  const finalPoValue = useRef();
  const toKaizenatValue = useRef();
  const toOEMValue = useRef();
  const toOEMinDollarsValue = useRef();

  const contact_name = useRef();

  const searchContainerRef = useRef(null);

  var nf = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 })
  const { register: opportunityRegister, handleSubmit: opportunityHandleSubmit, setError: opportunitySetError, formState: { errors: opportunityErrors } } = useForm();
  const [opportunity, setOpportunity] = useState({
    op_company_name: "",
    op_drf_status: "",
    op_drf_expiry_date: "",
    op_product: "",
    op_principal_rupees: "",
    op_principal_dollars: "",
    op_month: "",
    op_revenue_to_kaizenat: "",
    op_sales_stage: "",
    op_sales_activity: "",
    op_closure: "",
    op_closure_qtr: "",
    op_expiry_date: "",
    op_sales_confidence: "",
    op_action_date: "",
    op_region: "",
    op_business_type: " ",
    op_ansys_account_manager: "",
    op_percentage_split_tokaizenat: 0,
    op_po_value: "",
    op_timeline: "",
    op_meeting_summary: "",
    internal_contact_person:"",
    meeting_type:"",
    op_account_id: "",
    account: "",
    op_po_date: "",
    op_meeting_date: "",
    op_multi_products: {},
    op_products: [],
    department:"",
    license_file_path:""
  });

  const [prevSalesStage, setPrevSalesStage] = useState(null);

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#000" : '#000080',
        color: "white",
        opacity: '1.5'
      };
    }
  };



  const { op_po_date, op_meeting_summary, meeting_type,op_company_name, op_timeline, op_drf_status, op_drf_expiry_date, op_product,

  op_ansys_account_manager, op_sales_stage, op_sales_activity, op_closure_qtr, op_po_value, op_percentage_split_tokaizenat, op_region, op_business_type, op_meeting_date, op_products, op_multi_products,department,op_account_id } = opportunity;
  
   useEffect(() => {
   const salesStateis =  leadSalesStagesList.filter((data) => data.value === op_sales_stage)
    setCurrentSalesStage(salesStateis[0]?.label)
   },[leadSalesStagesList,op_sales_stage])

  //  console.log("op_sales_confidence ", opportunity.op_sales_confidence)


    useEffect(() => {
      if(op_products){
        // console.log("products are set or not here", op_products)
        setSubProductsList(op_products)
      }
    }, [op_products])

useEffect(() => {

  if(opportunityDetails && techiesList?.length>0 && techTeamManagersList?.length>0){

    // console.log("opportunity details ", opportunityDetails, productsList, techiesList)
    var closure_month = null;
    var timeline_year = null;
    // console.log("opportunity dalsdkjf ", op_company_name)
    if (opportunityDetails.timeline !== null) {

      //("timelinetimeline", opportunityDetails?.timeline)

      var timeline_split = opportunityDetails?.timeline?.split('-')
      opportunity.op_month = timeline_split?.length>0 ? timeline_split[0] : null
      closure_month = timeline_split?.length >0 ? timeline_split[0] : null
      timeline_year = timeline_split?.length >0 ?  timeline_split[1] : null

      // console.log("closure month", closure_month)
      // console.log("closure month", opportunityDetails?.timeline)
      // var monthNumber = moment()?.month(closure_month)?.format("M");
      var monthNumber = 0;
      if(closure_month){
        monthNumber = moment().month(closure_month).format("M");
      }else{
        monthNumber = moment().month(3).format("M");
      }

      var closure_quarter = null;
      if (monthNumber <= 3) {
        closure_quarter = "Q1"
      } else if (monthNumber <= 6) {
        closure_quarter = "Q2"
      } else if (monthNumber <= 9) {
        closure_quarter = "Q3"
      } else if (monthNumber <= 12) {
        closure_quarter = "Q4"
      }
    } else {
      opportunity.op_month = ""
    }

    var selectedProducts = []
    if(opportunityDetails?.products?.length > 0){
      // //("opportunity detail s", opportunityDetails.products)
      // //("products list ", productsList)
      selectedProducts =  productsList?.filter((option) => opportunityDetails?.products.includes(option.value))
    }else{
      selectedProducts =  productsList?.filter((option) => [opportunityDetails?.product].includes(option.value))
    } 
    // //("products multi first", selectedProducts)
    // //("opportunityDetails.multi_products", opportunityDetails.multi_products)
    
    if(opportunityDetails?.multi_products && Object.keys(opportunityDetails?.multi_products)?.length>0){
      // //("inside multi pp roducts ", opportunityDetails.multi_products)

      setProductPoData(opportunityDetails.multi_products)
      

      var seleProd = Object.keys(opportunityDetails.multi_products)
      // console.log("seleProd", opportunityDetails.multi_products)
       selectedProducts = productsList?.filter((option) => seleProd.includes(option.value))
      //  console.log("inside multi products ", selectedProducts)
    }else{
      // setProductPoData(selectedProducts.map(item => {
      //   return {
      //       _id: item,
      //       po_value : 0,
      //       revenue_to_kaizenat : 0,
      //       revenue_to_principal : 0,
      //       percentage_split_tokaizenat : 0
      //   }
      // }))
    }
    setUpdated(!updated)

    // //("products multi", selectedProducts)

    setOpportunity({
      op_account_id: opportunityDetails?.account_id ? opportunityDetails?.account_id : viewAccountId,
      op_opportunity_id: viewRowId,
      op_opportunity_name: opportunityDetails.opportunity_name,
      op_company_name: opportunityDetails.company_name,
      op_drf_status: opportunityDetails.drf_status,
      op_product: opportunityDetails.product,
      op_principal_rupees: opportunityDetails.principal_in_rupees,
      op_principal_dollars: opportunityDetails.principal_in_dollars,
      op_revenue_to_kaizenat: opportunityDetails.revenue_to_kaizenat,
      op_sales_activity: opportunityDetails.sales_activity,
      op_sales_stage: opportunityDetails.sales_stage,
      op_po_value: opportunityDetails.po_value,
      op_percentage_split_tokaizenat: 0,
      op_closure: opportunityDetails.closure,
      op_closure_qtr: opportunityDetails.closure_qtr || closure_quarter,
      op_expiry_date: opportunityDetails.expiry_date,
      op_sales_confidence: opportunityDetails.sales_confidence,
      op_action_date: opportunityDetails.action_date,
      op_business_type: opportunityDetails.business_type,
      op_region: opportunityDetails.region,
      op_ansys_account_manager: opportunityDetails.ansys_account_manager,
      op_timeline: opportunityDetails.timeline,
      op_closure_month: opportunityDetails.month || closure_month,
      op_timeline_year: opportunityDetails.timeline_year || timeline_year,
      op_sales_confidence: opportunityDetails.sales_confidence,
      op_drf_expiry_date: opportunityDetails.drf_expiry_date,
      op_products : selectedProducts,
      op_multi_products : opportunityDetails.multi_products,
      department: opportunityDetails?.department,
      license_file_path:opportunityDetails?.license_file_path
    });
  }else{
    // console.log("opportunityDetails ", opportunityDetails)
    setOpportunity({op_products: []})
    setUpdated(!updated)
  }
    
}, [opportunityDetails, productsList, techiesList, techTeamManagersList])


  const addSalesActivity = async () => {

    var internal_contact = []
    if(opportunity.internal_contact_person?.length>0){
      internal_contact = opportunity.internal_contact_person.map(item => {
        return item.value
      })
    }

    toast.info("Uploading..")

    var updateData = {
      opportunity_id: opportunity.op_opportunity_id,
      sales_activity: opportunity.op_sales_activity,
      contact_id: opportunity.contact_id,
      meeting_summary: opportunity.op_meeting_summary,
      meeting_type: opportunity.meeting_type,
      internal_contact_person: internal_contact,
      meeting_date: meetingDate
    }

    try {

      addSalesActivityData(updateData)

    } catch (err) {
      setDisable(false);
      toast.error(err.response.data.msg)
      if (err.response.status === 400) {
        opportunitySetError("op_" + err.response.data.key, {
          type: "manual",
          message: err.response.data.message,
        });
      }
      if (err.response.status === 403) {
        toast.error(err.response.data.msg)
      }
    }

  }

  const addNewOpportunityTask = async () => {

    toast.info("Uploading...")

    var updateData = {
      opportunity_id: opportunity.op_opportunity_id,
      task_name: ta_task_name.current ? ta_task_name.current.value : "",
      task_description: ta_task_description.current ? ta_task_description.current.value : "",
      task_action_date: moment(actionDate).format('YYYY-MM-DD HH:mm:ss'),
      reminder_date: moment(reminderDate).format('YYYY-MM-DD HH:mm:ss'),
    }

    try {

      addNewOpportunityTaskData(updateData)
      // props.reFreshLeadTable();

    } catch (err) {
      setDisable(false);
      toast.error(err.response.data.msg)
      if (err.response.status === 400) {
        opportunitySetError("op_" + err.response.data.key, {
          type: "manual",
          message: err.response.data.message,
        });
      }
      if (err.response.status === 403) {
        toast.error(err.response.data.msg)
      }
    }
  }

  const updateForecastDetails = async () => {

    // console.log("insidessss update forecast details")

    if (!op_drf_expiry_date) {
      toast.error("Please Select DRF Expiry Date")
      return;
    }
    if(!pOAndQuationStatus){
      toast.error("Please Submit PO and Quotation")
      return;
    }
    var finalPoValue = Object.values(productPoData)
    .map(productData => Number(productData.po_value) || 0)
    .reduce((sum, currentValue) => sum + currentValue, 0)

    var toKaizenatValue = Object.values(productPoData)
      .map(productData => {
        const { percentage_split_tokaizenat, po_value } = productData;
        return Math.floor((percentage_split_tokaizenat / 100) * po_value) || 0;
      })
      .reduce((sum, currentValue) => sum + currentValue, 0)
      .toFixed(0)
      
    var principalInRupees = Object.values(productPoData)
        .map(productData => {
          const { percentage_split_tokaizenat, po_value } = productData;
          return Math.floor(po_value - ((percentage_split_tokaizenat / 100) * po_value)) || 0;
        })
        .reduce((sum, currentValue) => sum + currentValue, 0)
        .toFixed(0)

    var principalInDollars = Object.values(productPoData)
        .map(productData => {
          const { percentage_split_tokaizenat, po_value } = productData;
          return Math.floor((po_value - ((percentage_split_tokaizenat / 100) * po_value)) / 73.6) || 0;
        })
        .reduce((sum, currentValue) => sum + currentValue, 0)
        .toFixed(0)
      //("set products po data ", productPoData)
      // var object = productPoData.map(prevProducts => {
      //   const {percentage_split_tokaizenat, po_value} = prevProducts
      //   const revenue_to_kaizenat = Math.floor(percentage_split_tokaizenat / 100) * po_value
      //   const revenue_to_principal = Math.floor(po_value - ((percentage_split_tokaizenat / 100) * po_value))
      //   var id = prevProducts.value || prevProducts._id
      //   return {
      //     ...prevProducts,
      //     [id]: { ...prevProducts[id], revenue_to_kaizenat : revenue_to_kaizenat, revenue_to_principal : revenue_to_principal }
      //   };
      // })
      // //("object ")
      // setProductPoData(prevProducts => {
      //   const {percentage_split_tokaizenat, po_value} = prevProducts
      //   const revenue_to_kaizenat = Math.floor(percentage_split_tokaizenat / 100) * po_value
      //   const revenue_to_principal = Math.floor(po_value - ((percentage_split_tokaizenat / 100) * po_value))
      //   var id = prevProducts.value || prevProducts._id
      //   return {
      //     ...prevProducts,
      //     [id]: { ...prevProducts[id], revenue_to_kaizenat : revenue_to_kaizenat, revenue_to_principal : revenue_to_principal }
      //   };
      // });

      var updatedProductsObject = {};

      for (const productId in productPoData) {
        //("productId ", productId)
        if(productId !== undefined && productId !== "undefined"){
        const product = productPoData[productId];
        //("complete product ", product)

        const { percentage_split_tokaizenat, po_value, techie_id } = product;

        const revenue_to_kaizenat = Math.floor((percentage_split_tokaizenat / 100) * po_value);
        const revenue_to_principal = Math.floor(po_value - ((percentage_split_tokaizenat / 100) * po_value));
        const revenue_to_principal_dollars = Math.floor((po_value - ((percentage_split_tokaizenat / 100) * po_value)) / 73.60).toFixed(0)
        updatedProductsObject[productId] = {
          ...product,
          revenue_to_kaizenat: revenue_to_kaizenat,
          principal_in_rupees: revenue_to_principal,
          principal_in_dollars : Number(revenue_to_principal_dollars),
          techie_id: techie_id
        };
      }
      }

      // console.log("revenue to kaizenat ", productPoData)
      // console.log(" receklsjd ", updatedProductsObject)

      // //("lsdfjlsdfds ", updatedProductsObject)

    toast.info("Uploading...")
    var selectedProducts = opportunity.op_products?.map((option) => option.value);

    // console.log("opportunity data ", opportunity)
    
    var updateData = {
      company_name: opportunity.op_company_name || opportunity.company_name,
      opportunity_id: opportunity.op_opportunity_id,
      business_type: opportunity.op_business_type,
      drf_status: opportunity.op_drf_status,
      drf_expiry_date: moment(op_drf_expiry_date).format('YYYY-MM-DD'),
      
      // po_value: opportunity.op_po_value,
      po_value : finalPoValue,
      percentage_split_tokaizenat: 0,
      revenue_to_kaizenat : toKaizenatValue,
      principal_in_rupees : principalInRupees,
      principal_in_dollars : principalInDollars,
      // revenue_to_kaizenat: ((opportunity.op_percentage_split_tokaizenat / 100) * opportunity.op_po_value).toFixed(0),
      // principal_in_rupees: (opportunity.op_po_value - ((opportunity.op_percentage_split_tokaizenat / 100) * opportunity.op_po_value)).toFixed(0),
      // principal_in_dollars: ((opportunity.op_po_value - ((opportunity.op_percentage_split_tokaizenat / 100) * opportunity.op_po_value)) / 73.60).toFixed(0),
      closure_qtr: opportunity.op_closure_qtr,
      ansys_account_manager: opportunity.op_ansys_account_manager,
      sales_stage: opportunity.op_sales_stage,
      sales_confidence: opportunity.op_sales_confidence,
      region: opportunity.op_region,
      timeline: op_timeline,
      timeline_month: opportunity.op_closure_month,
      timeline_quarter: opportunity.op_closure_qtr,
      timeline_year: opportunity.op_timeline_year,
      month: opportunity.op_closure_month,
      multi_products : updatedProductsObject,
      department: opportunity?.department
    }

    if(selectedProducts?.length>0){
      updateData.products= selectedProducts
      updateData.product = selectedProducts[0]
    }

    if(opportunity?.account_id){
      updateData.account_id = opportunity.account_id
    }

    if (showPoDate === true) {
      updateData.po_date = moment(poDate).format('YYYY-MM-DD');
    }

    try {

      //("po datas ", setProductPoData)
      //("updateData ", updateData)
      if(!createNewOpportunityModal){
        updateForecastData(updateData)
      }else{
        createNewOpportunityData(updateData)
      }
      
      // props.reFreshLeadTable();

    } catch (err) {
      ////("err", err)
      setDisable(false);
      // setOpen(true);
    }

  }


  // const UpdateOpportunityHandle = async (e) => {

  //   console.log("insidessss update opportunity handle")

  //   if (op_drf_expiry_date === null) {
  //     toast.error('DRF expiry date is required')
  //     return;
  //   }
  //   setDisable(true);

  //   var selectedProducts = opportunity.op_products?.map((option) => option.value);
  //   const updatedData = {
  //     opportunity_id: opportunity.op_opportunity_id,
  //     company_name: opportunity.op_company_name,
  //     timeline: timeline,
  //     timeline_month: opportunity.op_closure_month,
  //     timeline_quarter: opportunity.op_closure_qtr,
  //     timeline_year: opportunity.op_timeline_year,
  //     month: opportunity.op_closure_month,
  //     drf_status: opportunity.op_drf_status,
  //     drf_expiry_date: moment(op_drf_expiry_date).format('YYYY-MM-DD'),
      
  //     po_value: opportunity.op_po_value,
  //     percentage_split_tokaizenat: 0,
  //     revenue_to_kaizenat: ((opportunity.op_percentage_split_tokaizenat / 100) * opportunity.op_po_value).toFixed(0),
  //     principal_in_rupees: (opportunity.op_po_value - ((opportunity.op_percentage_split_tokaizenat / 100) * opportunity.op_po_value)).toFixed(0),
  //     principal_in_dollars: ((opportunity.op_po_value - ((opportunity.op_percentage_split_tokaizenat / 100) * opportunity.op_po_value)) / 73.60).toFixed(0),
  //     sales_stage: opportunity.op_sales_stage,
  //     sales_activity: opportunity.op_sales_activity,
  //     closure: opportunity.op_closure,
  //     closure_qtr: opportunity.op_closure_qtr,
  //     expiry_date: opportunity.op_expiry_date,
  //     sales_confidence: opportunity.op_sales_confidence,
  //     action_date: opportunity.op_action_date,
  //     region: opportunity.op_region,
  //     business_type: opportunity.op_business_type,
  //     ansys_account_manager: opportunity.op_ansys_account_manager,
  //     task_name: ta_task_name.current ? ta_task_name.current.value : "",
  //     task_description: ta_task_description.current ? ta_task_description.current.value : "",
  //     task_action_date: moment(actionDate).format('YYYY-MM-DD HH:mm:ss'),
  //     reminder_date: moment(reminderDate).format('YYYY-MM-DD HH:mm:ss'),
  //     user_id: userData ? userData._id : null,
  //     contact_id: opportunity.contact_id,
  //     meeting_summary: opportunity.op_meeting_summary,
  //     meeting_date: meetingDate,
  //     products : selectedProducts,
  //     product: selectedProducts?.length>0 ? selectedProducts[0] : "null",
  //   }

  //   if (showPoDate === true) {
  //     updatedData.po_date = moment(poDate).format('YYYY-MM-DD');
  //   }
  //   try {
  //     UpdateOpportunityHandleData(updatedData)
  //     setDisable(true);
  //   } catch (err) {
  //     setDisable(false);
  //     if (err.response.status === 400) {
  //       opportunitySetError("op_" + err.response.data.key, {
  //         type: "manual",
  //         message: err.response.data.message,
  //       });
  //     }
  //     if (err.response.status === 403) {
  //       toast.error(err.response.data.msg)
  //     }
  //   }
  // };

  const refreshContactsList = async () => {
    getEconomicBuyersData(opportunity.op_account_id)
  }

  const returnValue = async (contactId) => {
    opportunity.contact_id = contactId;
  }

  var twodaysdate =  new Date();
  twodaysdate.setDate(twodaysdate.getDate() + 2);

  var twodayless = new Date();
  twodayless.setDate(twodayless.getDate() - 1);

  const handleMeetingDateChange = (date) => {
    var newDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
    setMeetingDate(newDate);
  }

  const handleSetContactName = (value) => {


    if (value.__isNew__ !== true) {
      opportunity.contact_id = value.value;
    }

    if (value.__isNew__ === true) {
      setShowAddContactModal(true);
         openmodal();
    }
  }


  const onInputChange = (e) => {
    const { name, value } = e.target;
    const label = e.currentTarget?.dataset.label;

    const confidence_level = e.currentTarget?.dataset.id;
    if (name === "op_sales_stage" && label === "Won" && !opportunity.license_file_path ) {
      setPrevSalesStage({sales_stage: opportunity.op_sales_stage, sales_confidence:opportunity.op_sales_confidence} )
      setShowPoDate(true);
      setShowWonPoModal(true);
      setOpportunity({ ...opportunity, [name]: value, op_sales_confidence : confidence_level });
    } else {
      setPrevSalesStage(null)
      setShowPoDate(false);
      setShowWonPoModal(false);
      setPOAndQuationStatus(true);
      setOpportunity({ ...opportunity, [name]: value });
    }
    
    // Update opportunity's sales confidence level if available
    
    if (confidence_level !== undefined) {
     opportunity.op_sales_confidence = confidence_level;
     setOpportunity({...opportunity, op_sales_confidence : confidence_level, [e.target.name]: e.target.value})
      // setOpportunity({ ...opportunity, op_sales_confidence: confidence_level });
    } else {
      // Manually trigger onInputChange if value remains the same
      setOpportunity({ ...opportunity, [name]: value }, () => {
        if (opportunity.op_sales_stage === value) {
          onInputChange(e);
        }
      });
    }

    // console.log("op_sales_confidence ", opportunity)

  };
  
//   const onInputChange = (e) => {
//     // if (
//     //   e.target.name === "op_sales_stage" &&
//     //   e.currentTarget?.dataset.label === "Won" &&
//     //   showWonPoModal
//     // ) {
//     //   // If modal is open and "Won" is selected, do not update op_sales_stage
//     //   return;
//     // }
//     // if(showWonPoModal ===true){
//     //   setOpportunity({ ...opportunity, [e.target.name]: e.target.value });
//     // }
//  console.log("one more time won is",e.currentTarget?.dataset.label)
//     if (e.target.name === "op_sales_stage" && e.currentTarget?.dataset.label === "Won" && !opportunity.license_file_path ) {
//       setShowPoDate(true);
//       setShowWonPoModal(true)
//       // setOpportunity({ ...opportunity, [e.target.name]: "" }); 
//       setOpportunity({ ...opportunity, [e.target.name]: e.target.value });
//     } else {
//       setShowPoDate(false);
//       setShowWonPoModal(false)
//       setPOAndQuationStatus(true)
//       setOpportunity({ ...opportunity, [e.target.name]: e.target.value });
//     }

//     // const confidence_level = e.currentTarget?.dataset.id;
//     // console.log("confidence_level", confidence_level)
//     // if (confidence_level !== undefined) {
//     //   setOpportunity({ ...opportunity, op_sales_confidence: confidence_level });
//     // }

//     var confidence_level = e.currentTarget?.dataset.id
//     console.log('confidence_level', confidence_level, e.target.name)

//     if (confidence_level !== undefined) {
//       opportunity.op_sales_confidence = confidence_level;
//       setOpportunity({...opportunity, op_sales_confidence : confidence_level, [e.target.name]: e.target.value})
//     } else {
//       setOpportunity({ ...opportunity, [e.target.name]: e.target.value }, []);
//     }
 

//   };

  const handlePoDateChange = (date) => {
    var newDate = moment(date).format('YYYY-MM-DD');

    setPoDate(newDate);
  }

  const handleActionDateChange = (date) => {
    var newDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
    var remidDate = moment(date).subtract(30, 'm').format('YYYY-MM-DD HH:mm:ss'); 
    setActionDate(newDate);
    setReminderDate(remidDate)
  }

  const changeDrfExpiryDate = (date) => {
    var newDate = moment(date).format('YYYY-MM-DD');
    setOpportunity({ ...opportunity, ["op_drf_expiry_date"]: newDate }, [])
  }

  const handleReminderDateChange = (date) => {
    var newDate = moment(date).format('YYYY-MM-DD HH:mm:ss')
    var action = moment(actionDate)
    let remind = moment(newDate)
    let diff = remind.diff(action, 'minutes')
    if(diff > 0){
      let remidDate = moment(actionDate).subtract(30, 'm').format('YYYY-MM-DD HH:mm:ss')
      setReminderDate(remidDate)
      alert("Reminder date should be earlier than action date")
      return;
    }else{
      setReminderDate(newDate)
    }
    
  };


  // below previous code for multi products 
  

  const timelineChange = (e) => {
    var timeline_split = e.target.value.split('-');
    opportunity.op_month = timeline_split[0];
    opportunity.op_closure_month = timeline_split[0];
    opportunity.op_timeline_year = timeline_split[1];
    opportunity.op_timeline = e.target.value

    var monthNumber = moment().month(timeline_split[0]).format("M");

    var closure_qtr = null;
    if (monthNumber <= 3) {
      closure_qtr = "Q1"
    } else if (monthNumber <= 6) {
      closure_qtr = "Q2"
    } else if (monthNumber <= 9) {
      closure_qtr = "Q3"
    } else if (monthNumber <= 12) {
      closure_qtr = "Q4"
    }

    opportunity.op_closure_qtr = closure_qtr
    setOpportunity({ ...opportunity, op_closure_month: timeline_split[0] })
  }

  const openTrainingModal = (e, stateSub = true, stateMain = false) => {
    setOpportunityShow(stateMain);
    setShowTrainingModal(stateSub);
  }
  
  const onProductPoValuesChange = (productId, name, value) => {

      // console.log("product ", productPoData)
      // console.log("product id ", productId, name, value)
      
      if(!value && productPoData.hasOwnProperty(productId) && (name == "po_value" || name == "percentage_split_tokaizenat")){
        setProductPoData(prevProducts => {
          return {
            ...prevProducts,
            [productId]: { ...prevProducts[productId], [name]: NaN }
          };
        });
        setUpdated(!updated)
      }
      if(!value){
        setProductPoData((prevProducts) => {
          const { [productId]: productToUpdate, ...rest } = prevProducts;

          if (productToUpdate) {
            const { techie_id, ...updatedProduct } = productToUpdate;
            return {
              ...rest,
              [productId]: updatedProduct,
            };
          }

          return prevProducts;
        });
        return;
      }
      // console.log("previous techie ", productPoData[productId], value)
      // var previousTechie = productPoData[productId]?.techie_id
      // setProductPoData(prevProducts => {
      //   return {
      //     ...prevProducts,
      //     [productId]: { ...prevProducts[productId], [name]: (name === "techie_id") ? value :  parseFloat(value), 'previousTechie': (name === "techie_id" && !prevProducts[productId]?.previousTechie) ? value : prevProducts[productId]?.previousTechie  }
      //   };
      // });
    
      // setProductPoData(prevProducts => ({
      //   ...prevProducts,
      //   [productId]: {
      //     ...prevProducts[productId],
      //     [name]: name === "techie_id" ? value : parseFloat(value),
      //     'previousTechie': name === "techie_id" ? (value || null) : prevProducts[productId].previousTechie
      //   }
      // }));

      setProductPoData(prevProducts => {
        const currentTechieId = prevProducts[productId]?.techie_id || null;
        return {
          ...prevProducts,
          [productId]: {
            ...prevProducts[productId],
            [name]: name === "techie_id" ? value : parseFloat(value),
            'previousTechie': name === "techie_id" ? currentTechieId : prevProducts[productId].previousTechie
          }
        };
      });
      

      setUpdated(!updated)
      // console.log("product po data ", productPoData)
      
      
      // console.log("new product po data ", productPoData)
    // setProductPoData(prevProducts => {
    //   var revenue_to_kaizenat = Math.floor((prevProducts[productId]?.percentage_split_tokaizenat / 100) * prevProducts[productId]?.po_value);
    //   var principal_in_rupees = productPoData[productId]?.po_value - ((productPoData[productId]?.percentage_split_tokaizenat / 100) * productPoData[productId]?.po_value)
    //   return {
    //     ...prevProducts,
    //     [productId]: { ...prevProducts[productId], [name]: value, revenue_to_kaizenat : revenue_to_kaizenat, principal_in_rupees : principal_in_rupees }
    //   };
    // });

    // Math.floor((productPoData[product.value]?.op_percentage_split_tokaizenat / 100) * productPoData[product.value]?.po_value)
    // //("product_id ", productId, value)
  }

  // useEffect(() => {
  //   const updatedProductPoData = {};
  //   for (const productId in productPoData) {
  //     const { techie_id, previousTechie } = productPoData[productId];
  //     updatedProductPoData[productId] = {
  //       ...productPoData[productId],
  //       isTechieDifferent: previousTechie && previousTechie !== techie_id
  //     };
  //   }
  //   setProductPoData(updatedProductPoData);
  // }, [productPoData]);


  useEffect(() => {
    console.log('use effct ', productPoData)
  }, [productPoData])

  const IconWithTooltip = () => (
    <Tooltip title="If sales activity changes and contact is selected and provide meeting summary, then a meeting will be created.">

      <HelpIcon />
    </Tooltip>
  );

  const fechDemoData = async () => {
    var postData = {
      'type': 'all',
      'opportunity_id': viewRowId
    }
    getDemosList(postData)
  }

  const fechBenchmarkData = async () => {
    var postData = {
      'type': 'all',
      'opportunity_id': viewRowId
    }
    getBenchmarksData(postData)
  }

  const fechTrainingData = async () => {
    var postData = {
      'type': 'all',
      'opportunity_id': viewRowId
    }
    getTrainingsData(postData)
  }

  
  useEffect(() => {
    getTechTeamManagersData();
    getAnsysAccountManagersData();
    getDrfStatusData();
    getProductsListData();
    getRegionsData();
    getBusinessTypesData();
    getLeadStagesData();
    if(permissions?.includes('viewOwnDemos')){
      fechDemoData();
    }
    if(permissions?.includes('viewOwnBenchmarks')){
      fechBenchmarkData();
    }
    if(permissions?.includes('viewOwnTrainings')){
      fechTrainingData();
    }
    
    getAccontManagersListData();
    fetchTechiesListData();
    getNewProductsData();
    // getTechiesListByManagerData({managerId : userData._id});
  }, []);

  const handleRotate1 = () => setRotateChevron1(!rotateChevron1);

  const statecolor = true;
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  // const poValueChanged = (e, item) => {

  //   //('Data ID:', e.target.value);
  //   //('dataset', item);

  // };

  const [products, setProducts] = useState(op_products);
  const [productsLists, setProductLists] = useState(productsList);

// console.log("products list are here",products, techTeamManagersList)
  // const onProductPoValuesChange = (productId, name, value) => {
  //   setProductPoData(prevProducts => {
  //     return {
  //       ...prevProducts,
  //       [productId]: { ...prevProducts[productId], [name]: value }
  //     };
  //   });
  // }

  const poValueChanged = (e, productId) => {
    // var productList = [...productsList]
    //("product po data ", productPoData)
    const newProducts = {
      ...productPoData,
      [productId]: {
        ...productPoData[productId],
        op_po_value: e.target.value,
      },
    };

    //("new products values ", newProducts)
    setProducts(newProducts);
    updateOEMValues(newProducts);
  };

  const updateOEMValues = (updatedProducts) => {
    const newProducts = updatedProducts.map(item => {
      const toKaizenatRupees = Math.floor((item.op_percentage_split_tokaizenat / 100) * item.op_po_value);
      const toOEMRupees = Math.floor(item.op_po_value - toKaizenatRupees);
      const toOEMDollars = Math.floor(toOEMRupees / 73.6);

      return {
        ...item,
        op_revenue_to_kaizenat: toKaizenatRupees,
        op_principal_rupees: toOEMRupees,
        op_principal_dollars: toOEMDollars,
      };
    });

    setProducts(newProducts);
  };

  const removeProduct = (productId) => {
    // Remove the product data from productPoData
    // //("product po data in deleting ", productPoData)
    // console.log("product po delete ", productId)
    // console.log("product po delete ", productPoData)
    // console.log("product po data ", )

    setProductPoData(prevProducts => {
      var newProducts = { ...prevProducts };
      // //("new products ", newProducts[productId])
      delete newProducts[productId];
      // //("new Products after deleting", newProducts)
      return newProducts;
    });

    // Remove the product from the array of products (op_products)
    setOpportunity((prevDetails) => {
      const newOpportunityDetails = { ...prevDetails };
      // //("new opportunity details ", newOpportunityDetails.op_products)
      newOpportunityDetails.op_products = newOpportunityDetails?.op_products.filter(
        (product) => product.value !== productId
      );
      // //("new opportunityDetails deleted ", newOpportunityDetails)
      return newOpportunityDetails;
    });
  };
  
  const addNewProduct = (selectedProduct, i) => {
    //("inside add new product ", selectedProduct)
    //("selectedProduct ", selectedProduct.value)
    setProductPoData((prevProducts) => ({
      ...prevProducts,
      [selectedProduct.value]: {
        po_value: NaN, // Set default values for new product
        percentage_split_tokaizenat: NaN,
        // ... other default values
      },
    }));
    
    // Assuming opportunityDetails is the state variable holding op_products
    setOpportunity((prevDetails) => {
      return {
        ...prevDetails,
        op_products: [...prevDetails.op_products, {label:undefined, value:undefined}],
      };
    });
  };

  const handleProductSelection = ( selectedItem) => {
  // console.log("values are here", selectedItem)
    const updatedSelectedItem = {label:selectedItem.name, value:selectedItem.product_id}
    // const updatedSelectedItem = selectedItem
    //("selected product ", selectedItem,  index)
    // console.log("slected product is", updatedSelectedItem)
    setSubProductsList((op_products) => [...op_products, updatedSelectedItem]);
  //     const updatedProductsList = productsLists.filter((product) => product.value !== selectedItem.value);

  // //  Now you can use updatedProductsList in your state or wherever you need it
  // setProductLists(updatedProductsList);


    if (productPoData.hasOwnProperty(updatedSelectedItem.value)){
      toast.error("Product already selected")
      setOpportunity((prevDetails) => {
        const newOpportunityDetails = { ...prevDetails };
        //("new opportunity details ", newOpportunityDetails.op_products)
        newOpportunityDetails.op_products = newOpportunityDetails.op_products.filter(
          (product) => product.value !== undefined
        );
        //("new opportunityDetails deleted ", newOpportunityDetails)
        return newOpportunityDetails;
      });
      return 
    }
    setOpportunity((prevDetails) => {
      const filteredOpProducts = prevDetails.op_products?.filter((item) => {
        // Replace 'yourCondition' with your actual condition for filtering
        return item.value !== undefined;
      });
      return {
        ...prevDetails,
        op_products: [...filteredOpProducts, updatedSelectedItem],
      };
    });
    // setOpportunity((prevDetails) => {
    //   const updatedProducts = [...prevDetails.op_products];
      
      // const productIndex = updatedProducts.findIndex(product => product.value === selectedProduct.value);
      
      // if (productIndex !== -1) {
      //     updatedProducts[productIndex] = {
      //       ...selectedProduct,
      //       key: selectedProduct.value, 
      //     };
      //   }
      
      //   return {
      //     ...prevDetails,
      //     op_products: updatedProducts,
      //   };
      // });

      if (productPoData.hasOwnProperty("undefined")){
        setProductPoData((prevProducts) => {
          // Create a copy of the previous products
          const updatedProducts = { ...prevProducts };
          const undefinedValue = updatedProducts["undefined"];      

          // if (undefinedKey !== undefined) {
            updatedProducts[updatedSelectedItem.value] = undefinedValue
            delete updatedProducts["undefined"];
          // }
    
          return updatedProducts;
        });
      }else{
        const valuesArray = Object.values(productPoData);

        // const valueAtIndex = valuesArray[index];

        //("index ", index, valueAtIndex)
      }

      setQuery('')
      setProductsListData(newproductsLists)
      // setExpandedCategories([]);
      // setExpandedProducts([]);
      // setExpandedSubproducts([]);

  };

  const getCompaniesList = async (search) => {
    setCompaniesList([]);
    const res = await axios.get(
      "/opportunities/accounts/search/" + userData._id + "/" + search.target.value
    );
    setCompaniesList(res.data.opportunitiesList);
  };

  const handleSetCompanyName = (value) => {
    // setCompanyName(value.value);
    // setCompanyName(value.value)
    // opportunity.company_name = value.value
    if (value.__isNew__ === true) {
    } else {
      // setAccountId(value.data_id);
      // setAccountId(value.data_id);
      // const keyValues = [
      //   { key: "company_name", value: value.value },
      //   { key: "account_id", value: value.data_id },
      // ];

      // opportunity.account_id = value.data_id

      // const updatedOpportunity = keyValues.reduce(
      //   (acc, { key, value }) => ({ ...acc, [key]: value }),
      //   { ...opportunity }
      // );
      // setOpportunity(updatedOpportunity, []);

      // setOpportunity({ ...opportunity, [e.target.name]: e.target.value });

      // setOpportunity({ ...opportunity, ["company_name"]: value.value }, []);
      // setOpportunity({ ...opportunity, ["account_id"]: value.data_id }, []);

      setOpportunity({
        ...opportunity,
        ["company_name"]: value.value,
        ["account_id"]: value.data_id
      }, []);

      // setNewContactAccountId(value.data_id);
    }
  };
  // setPrevSalesStage({sales_stage: opportunity.op_sales_stage, sales_confidence:opportunity.op_sales_confidence} )
  const onPoModalCancelled = () => {
    setShowWonPoModal(false)
    setOpportunity({
      ...opportunity,
      ["op_sales_stage"]: prevSalesStage.sales_stage,
      ["op_sales_confidence"]: prevSalesStage.sales_confidence
    }, []);
  }



  // }, [productPoData, op_products])
  
  const CloseOpportunityModal = () => {
    setProductPoData([])
    updateOpportunityModal(false)
  }
const statusHandle = (value) =>{
// console.log('value of the po and quation modal', value)
setPOAndQuationStatus(value)
}
  const renderSelectField = (item) => {
  // console.log("item ", item, op_multi_products)
  // console.log("op_multi_products[item.value]", op_multi_products[item.value]);
  // if(op_multi_products[item.value]){
          return (
              <TextField variant="outlined"
                select
                label="Technical Person"
                type="text" className="form-control"
                defaultValue={productPoData[item.value]?.techie_id}
                onChange={e => onProductPoValuesChange(item.value, e.target.name, e.target.value)}
                name="techie_id" >
                {
                  techiesList?.map((option, techie_id) => (
                    <MenuItem key={option.value} selected={techie_id.toString() === option.value.toString()} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                }
              </TextField>
          )
    // }
  }
{/* <TextField variant="outlined"
                select
                label={productPoData[op_products[key]?.value]?.techie_id}
                type="text" className="form-control"
                value={productPoData[op_products[key]?.value]?.techie_id}
                onChange={e => onProductPoValuesChange(productPoData[op_products[key]?.value], e.target.name, e.target.value)}
                name="techie_id" >
                {
                  techiesList?.map((option, techie_id) => (
                    <MenuItem key={option.value}  value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                }
              </TextField> */}

  
  const updateProduct = (productId, opportunityId) => {
    //  console.log("itemDetail", productId, opportunityId)
    //  console.log("itemDetail", productPoData[productId])
    //  if(!productId || !opportunityId){
    //   toast.error("Please select product")
    //   return;
    //  }
    //  console.log("item", item)
    //  console.log("opportunityId", opportunityId)
    if(productPoData[productId]?.techie_id){
      reAssignTechieData({opportunityId: opportunityId, productId: productId, techieId: productPoData[productId]?.techie_id})
    }
     
  }
  const handleChange = (itemId, value) => {
    return () => {
      onProductPoValuesChange(itemId, "techie_id", value);
    };
  };

  const OpportunityProductsView = () => {
      const response = []
      // console.log("it is printing again ")
      // console.log("op products", op_products)

      // console.log('techTeamManagersList', techTeamManagersList)
      // for(var index in op_products){
      // op_products?.map(item => {
        // console.log("op_products ", opportunityDetails?.products, op_product, productsList)
        // if(opportunityDetails && opportunityDetails?.products?.length <1 && op_products?.length < 1 && opportunityDetails?.product){
        //   return (
        //     <Typography>
        //       Previously added product is missing from the current opportunity. Please add below product to the opportunity.<br />
        //       <label>Product - {missingProductName}</label><br />
        //       <label>Po Value - {opportunityDetails?.po_value?.toLocaleString("en-IN")}</label><br />
        //       <label>Percentage To Kaizenat - {opportunityDetails?.percentage_split_tokaizenat?.toLocaleString("en-IN")}%</label><br />
        //       <label>Revenue To Kaizenat - {opportunityDetails?.revenue_to_kaizenat?.toLocaleString("en-IN")}</label><br />
        //       <label>Revenue To Principal - {opportunityDetails?.principal_in_rupees?.toLocaleString("en-IN")}</label><br />
        //       <label>Revenue To Princiapl In Dollars - {opportunityDetails?.principal_in_dollars?.toLocaleString("en-IN")}</label>
        //     </Typography>
        //   )
        // }
        // if(op_products?.length < 1 & op_product != null){
        //   var pro = productsList.filter(item => item.value === op_product)
        //   console.log("prop", pro)
        //   return (
        //     <Typography>
        //       Previously added product is missing from the current opportunity. Please add below product to the opportunity.<br />
        //       <label>Product - {missingProductName}</label><br />
        //       <label>Po Value - {opportunityDetails?.po_value?.toLocaleString("en-IN")}</label><br />
        //       <label>Percentage To Kaizenat - {opportunityDetails?.percentage_split_tokaizenat?.toLocaleString("en-IN")}%</label><br />
        //       <label>Revenue To Kaizenat - {opportunityDetails?.revenue_to_kaizenat?.toLocaleString("en-IN")}</label><br />
        //       <label>Revenue To Principal - {opportunityDetails?.principal_in_rupees?.toLocaleString("en-IN")}</label><br />
        //       <label>Revenue To Princiapl In Dollars - {opportunityDetails?.principal_in_dollars?.toLocaleString("en-IN")}</label>
        //     </Typography>
        //   )
        // }
      for( var i in op_products){

        var item = op_products[i]
        var selected = []
        var techiesLists = []
        if(userData?.user_role_id?.role_name == "techie"){
          selected = techiesListByManager?.filter(item1 => item1.value.toString() == productPoData[item?.value]?.techie_id)
          techiesLists = techiesListByManager
        }else{
          techiesLists = techTeamManagersList
        }

        if(selected?.length < 1){
          selected = techTeamManagersList?.filter(item1 => item1.value.toString() == productPoData[item?.value]?.techie_id)
          // techiesLists = techTeamManagersList
        }
        
        var selectedTechie = techiesList?.filter(item1 => item1.value.toString() == productPoData[item?.value]?.techie_id)
        // console.log("op_products[i]", op_products[i])
        // console.log("this printings on each selection", productPoData[item.value])
        // var item = op_products[index]
        response.push(
          <>
          <div className="row">
            <div className='col-md-2'>
                                { !item?.label ?
                              // <Select
                              //   placeholder="Select Product"
                              //   name="colors"
                              //   isMulti={false}
                              //   // selected={item}
                              //   options={productsList}
                              //   className="basic-multi-select"
                              //   // defaultValue={item}
                              //   onChange={(value) => handleProductSelection(value)}
                              //   // onChange={(value) => setOpportunity({...opportunity, internal_contact_person: value })}
                              //   classNamePrefix="select">

                              // </Select>
                              <div ref={searchContainerRef} className="search-container">
                                
                              <TextField
                                variant="outlined"
                                type="text"
                                value={query}
                                onChange={handleSearch}
                                onFocus={() => setIsSearchListVisible(true)}
                                className="form-control search_products_field"
                                placeholder="Select Product"
                              />
                        
                              {isSearchListVisible && (
                                <div className="dropdown_content1234">
                                  {productsListData?.map((category, index) => (
                                    <Accordion
                                      key={index}
                                      expanded={expandedCategories.includes(category.name)}
                                      onChange={handleCategoryAccordionChange(category.name)}
                                    >
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`category-${index}-content`}
                                        id={`category-${index}-header`}
                                        className="styel_summary"
                                        key={`category-${index}-header`}
                                      >
                                        <Typography  className="accordion-text">
                                          {category.name}
                                        </Typography>
                                      </AccordionSummary>
                        
                                      <AccordionDetails>
                                        <Typography className="typography_list">
                                          {category?.subproducts?.length > 0 && (
                                            <ul className="subProducts_list">
                                              {category?.subproducts.map((subproduct, subIndex) => (
                                                <Accordion
                                                  key={subIndex}
                                                  expanded={expandedProducts.includes(subproduct.name)}
                                                  onChange={handleProductAccordionChange(subproduct.name)}
                                                >
                                                  <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls={`product-${index}-${subIndex}-content`}
                                                    id={`product-${index}-${subIndex}-header`}
                                                    className="styel_summary"
                                                  >
                                                    <Typography className="accordion-text">
                                                      {subproduct.name}
                                                    </Typography>
                                                  </AccordionSummary>
                                                
                                                  <AccordionDetails>
                                                    <Typography className="typography_list">
                                                      {subproduct.childProducts.length > 0 && (
                                                        <ul className="childProducts_list">
                                                          {subproduct.childProducts.map(
                                                            (childProduct, childIndex) => (
                                                              <li
                                                                onClick={() => handleProductSelection(childProduct)}
                                                                // onClick={() =>

                                                                //   childProductDataHandle(childProduct)
                                                                // }
                                                                key={childIndex}
                                                              >
                                                                &#x2022; {childProduct.name}
                                                              </li>
                                                            )
                                                          )}
                                                        </ul>
                                                      )}
                                                    </Typography>
                                                  </AccordionDetails>
                                                </Accordion>
                                              ))}
                                            </ul>
                                          )}
                                        </Typography>
                                      </AccordionDetails>
                                    </Accordion>
                                  ))}
                                </div>
                              )}
                            </div>
                              : <span>{item?.label}</span> }
                              {/* <TextField variant="outlined"
                                  select
                                  onChange={(e) => handleProductSelection(e, item, index)}
                                  type="text" label="Product Name" name="op_po_value" className="form-control" value={item} >
                                  {
                                    productsList.map((item1) => (
                                      <MenuItem key={item1.value} selected={item1.label === item.label} value={item1.value}>{item1.label}</MenuItem>
                                    ))
                                  }
                              </TextField> */}

            </div>
            <div className="col-md-2">
              <TextField variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start"><b>₹</b></InputAdornment>
                }}

                type="number" label="Po Value " name="po_value" className="form-control" 
                value={productPoData[item?.value]?.po_value} 
                // onChange={e => onProductPoValuesChange(item.value, e.target.name, e.target.value)}
                onChange={(itemId => (e) => {
                  onProductPoValuesChange(itemId, e.target.name, e.target.value);
                })(item.value)}
                 />
            </div>
            <div className="col-md-1">
                                <TextField variant="outlined"
                                  // InputProps={{
                                  //   startAdornment: <InputAdornment position="start"></InputAdornment>
                                  // }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  type="number"  label="% KTPL" name="percentage_split_tokaizenat" className="form-control" 
                                  // value={op_percentage_split_tokaizenat} 
                                  // onChange={e => onInputChange(e)}
                                  value={productPoData[item.value]?.percentage_split_tokaizenat}
                                  // onChange={e => onProductPoValuesChange(item.value, e.target.name, e.target.value)}
                                  onChange={(itemId => (e) => {
                                    onProductPoValuesChange(itemId, e.target.name, e.target.value);
                                  })(item.value)}
                                />
                                  {/* type="number" label="% To Kaizenat" name="op_percentage_split_tokaizenat" className="form-control" value={op_percentage_split_tokaizenat} onChange={e => onInputChange(e)} /> */}
                              </div>

                              <div className="col-md-2">
                                <TextField variant="outlined"
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: <InputAdornment position="start"><b>₹</b></InputAdornment>
                                  }}

                                  type="text" label="KTPL( ₹ )" name="op_revenue_to_kaizenat" className="form-control" 
                                  value={Math.floor((productPoData[item.value]?.percentage_split_tokaizenat / 100) * productPoData[item.value]?.po_value).toLocaleString('en-IN')} 
                                  // onChange={e => onInputChange(e)} 
                                  // value = {productPoData[product.value]?.revenue_to_kaizenat}
                                  // onChange={e => onProductPoValuesChange(item.value, e.target.name, e.target.value)}
                                />
                              </div>

                              <div className="col-md-2">
                                <TextField variant="outlined"
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: <InputAdornment position="start"><b>₹</b></InputAdornment>
                                  }}
                                  type="text" label="To OEM( ₹ )" name="op_principal_rupees" className="form-control" 
                                  // value={productPoData[product.value]?.principal_in_rupees}
                                  value={Math.floor(productPoData[item.value]?.po_value - ((productPoData[item.value]?.percentage_split_tokaizenat / 100) * productPoData[item.value]?.po_value)).toLocaleString('en-IN')} 
                                  // onChange={e => onInputChange(e)} 
                                  // onChange={e => onProductPoValuesChange(item.value, e.target.name, e.target.value)}
                                  // onChange={(itemId => (e) => {
                                  //   console.log("Event object:", e, itemId, e.value);
                                  //   onProductPoValuesChange(itemId, "techie_id", e.value);
                                  // })(item.value)}
                                />
                              </div>

                              {
                                // (selected?.length > 0 || productPoData[item.value]?.techie_id === undefined) ?
                                <div className="col-md-2">
                                  <Select
                                    isMulti={false}
                                    placeholder="Select Techie"
                                    name="colors"
                                    // isDisabled={ (userData?.user_role_id?.name == "techie" && permissions.includes('canReassignProduct')) ? true : false}
                                    options={(userData?.user_role_id?.role_name == "techie") ? techiesListByManager: techTeamManagersList}
                                    className="basic-multi-select"
                                    defaultValue={selected}
                                    // onChange={(value) => handleChange(item, "techie_id", value.value)()}
                                    onChange={(itemId => (value) => onProductPoValuesChange(itemId, "techie_id", value.value))(item.value)}
                                    // onChange={value => onProductPoValuesChange(item, "techie_id", value.value, i)}
                                    // onChange={(value) => setOpportunity({...opportunity, op_product: value })}
                                    classNamePrefix="select">
                                  </Select>
                                </div>
                                  
                                // : <div className="col-md-2">
                                //     <label>{selectedTechie[0]?.label}</label>
                                //   </div>
                              }

{/* && productPoData[item.value]?.techie_id == userData._id */}
{/* userData?.user_role_id?.role_name == "techie" ||  */}
            {((productPoData[item.value]?.techie_id && productPoData[item.value]?.previousTechie) && productPoData[item.value]?.previousTechie !== productPoData[item.value]?.techie_id) ? 
                <div className="col-md-1">
                  <Button variant="outlined" className='btn btn-sm btn-success' color="secondary" onClick={(itemId => (value) => updateProduct(itemId, viewRowId))(item.value)}
                  //  onClick={() => updateProduct(productPoData[item.value], item.value, viewRowId)}
                   >
                    <AiOutlineCheck />
                  </Button>
                </div>
              :
              <div className="col-md-1">
                <Button variant="outlined" className='btn btn-sm btn-danger' color="secondary" 
                // onClick={() => removeProduct(item.value)}
                onClick={(itemId => (value) => removeProduct(itemId))(item.value)}
                >
                  <AiOutlineDelete />
                </Button>
              </div>
            } 

          </div>
          <br />
          </>
        )
      };
      return response  
  };
  
  return (
    <>

      {/*<Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>*/}

      <Modal
        show={showOpportunityModal}
        onHide={() => updateOpportunityModal(false)}
        backdrop={false}
        disableBackdrop={true}
        dialogClassName="custom-smaller-modal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            <small>{createNewOpportunityModal ? "Create Opportunity" : "View/Update Opportunity "}</small>
          </Modal.Title>
          <a onClick={CloseOpportunityModal} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body style={{ background: true }}>
        {/* onSubmit={opportunityHandleSubmit(UpdateOpportunityHandle)} */}
          <form>
            {!createNewOpportunityModal && <div className="col-md-12">
              <span className="band-left-border">Opportunity Name : <b>{opportunity.op_opportunity_name?.replaceAll("_"," ").split(' ').slice(0, -1).join(' ')}</b></span>
            </div>}
            <br />
            {!createNewOpportunityModal && <div className='row'>
                <CustomizedSteppers opportunitiesId={viewRowId} />
            </div>}
            {!createNewOpportunityModal && <div className='row'>
               <OpportunitiesViewChart rowId={viewRowId} data ={chartData}/>
            </div>}
            <br />
            <div className="row d-flex flex-row justify-content-between">
              <div className="col-md-6 headerBand1">
                <label>Organization Details</label>
              </div>
              {(!createNewOpportunityModal) && (permissions.includes("createOwnDemos") || permissions.includes("createOwnBenchmarks") || permissions.includes("createOwnTrainings")) ?
                <div className="col-md-6">
                  <div className="dropdown">
                    <a style={{ background: '#5160b4', color: '#fff' }} className="btn btn-sm btn-white bottomBarButtons dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                      Request Techie
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      {permissions.includes("createOwnDemos") ?
                        <li>
                          <>
                            <a className="dropdown-item techie_activity" onClick={() => { setShowDemoModal(true); setOpportunityShow(false) }} >Demo Request</a>
                            {showDemoModal ? <DemoModal multiProducts={subProductsList}  refreshDemoTable={() => fechDemoData()} accountId={opportunity.op_account_id}  economicBuyer={leadDetail?.economic_buyer?.contact_name} allTechieActionsDetails={opportunityDetails} show={showDemoModal} onHide={() => setShowDemoModal(false)} /> : ""}
                          </>
                        </li>
                        : false}
                      {permissions.includes("createOwnBenchmarks") ?
                        <li>
                          <>
                            <a className="dropdown-item techie_activity" onClick={() => setShowBenchmarkModal(true)} >Benchmark Request</a>
                            {showBenchmarkModal ? <BenchmarkModal  multiProducts={subProductsList} refreshBenchmarkTable={() => fechBenchmarkData()} economicBuyer={leadDetail?.economic_buyer?._id} allTechieActionsDetails={opportunityDetails} show={showBenchmarkModal} onHide={() => setShowBenchmarkModal(false)} /> : ""}
                          </>
                        </li>
                        : false}

                      {permissions.includes("createOwnTrainings") ?
                        <li>
                          <>
                            <a className="dropdown-item techie_activity" onClick={() => { setShowTrainingModal(true); }} >Training Request</a>
                            {showTrainingModal ? <TrainingModal  multiProducts={subProductsList} refreshTrainingTable={() => fechTrainingData()} allTechieActionsDetails={opportunityDetails} show={showTrainingModal} onHide={(e) => setShowTrainingModal(false)} /> : ""}
                          </>
                        </li>
                        : false}

                          <li>
                           <>
                             <a className="dropdown-item techie_activity" onClick={() => { setShowIsalesrequestModal(true); }} >Request Isales</a>
                             {showIsalesrequestModal ? <IsalesRequestModal OpportunitiesId={viewRowId} keyType={"opportunities"} show={showIsalesrequestModal} onHide={() => setShowIsalesrequestModal(false)} /> : ""}
                           </>
                         </li>
                    </ul>
                  </div>
                </div>
                : false}

            </div>
            <br />
            <div class="row">
              <div class="col-md-6">
                {
                  createNewOpportunityModal ? 

                    // <CreatableSelect
                    //   closeMenuOnSelect={true}
                    //   className="basic-single"
                    //   classNamePrefix="select"
                    //   isSearchable="true"
                    //   options={companiesList}
                    //   placeholder="Search Organization Name*"
                    //   onChange={handleSetCompanyName}
                    //   onKeyDown={getCompaniesList}
                    // >
                    // </CreatableSelect>

                    <Select
                      placeholder="Search Organization Name*"
                      onKeyDown={getCompaniesList}
                      options={companiesList}
                      className="basic-multi-select"
                      onChange={(value) => setOpportunity({...opportunity, company_name: value.value, account_id: value.data_id })}
                      classNamePrefix="select">
                    </Select>

                  : 
                  <TextField variant="outlined"
                    {...opportunityRegister("op_company_name", {
                      minLength: {
                        value: 3,
                        message: "Organization Name must be at least 3 characters"
                      }
                    })}
                    required
                    helperText={opportunityErrors.op_company_name ? opportunityErrors.op_company_name.message : ''}
                    error={Boolean(opportunityErrors.op_company_name)}
                    label="Organization Name"
                    type="text" className="form-control" value={op_company_name} onChange={e => onInputChange(e)} name="op_company_name" />
                }
                  </div>
              <div class="col-md-6">
                <TextField variant="outlined"
                  {...opportunityRegister("op_region", {
                    minLength: {
                      value: 3,
                      message: "Region must be at least 3 characters"
                    }
                  })}
                  required
                  select
                  helperText={opportunityErrors.op_region ? opportunityErrors.op_region.message : ''}
                  error={Boolean(opportunityErrors.op_region)}
                  label="Region"
                  value={op_region}
                  type="text" className="form-control" onChange={e => onInputChange(e)} id="op_region" name="op_region"  >
                  {
                    regionsList.map((option, op_region) => (
                      <MenuItem key={option.value} selected={op_region === option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </div>
            </div>
            <br />

            <div className='row'>
              <div className="col-md-6">
                <TextField variant="outlined"
                  {...opportunityRegister("op_ansys_account_manager", {
                    minLength: {
                      value: 3,
                      message: "Ansys Account Manager must be at least 3 characters"
                    }
                  })}
                  select
                  helperText={opportunityErrors.op_ansys_account_manager ? opportunityErrors.op_ansys_account_manager.message : ''}
                  error={Boolean(opportunityErrors.op_ansys_account_manager)}
                  label="Ansys Account Manager"
                  type="text" className="form-control" onChange={e => onInputChange(e)} value={op_ansys_account_manager} name="op_ansys_account_manager"
                >
                  {
                    ansysAccountManagers?.map((option, op_ansys_account_manager) => (
                      <MenuItem key={option.value} selected={op_ansys_account_manager === option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))
                  }
                </TextField>

              </div>

              <div className="col-md-6">
                      <TextField variant="outlined"
                        {...opportunityRegister("op_drf_status", {
                          minLength: {
                            value: 1,
                            message: "DRF Status must be at least 3 characters"
                          }
                        })}
                        select
                        helperText={opportunityErrors.op_drf_status ? opportunityErrors.op_drf_status.message : ''}
                        error={Boolean(opportunityErrors.op_drf_status)}
                        type="text"
                        label="DRF Status"
                        name="op_drf_status"
                        required
                        className="form-control"
                        onChange={e => onInputChange(e)}
                        value={op_drf_status}
                      >
                        {
                          drfStatusList.map((option, op_drf_status) => (
                            <MenuItem key={option.value} selected={op_drf_status === option.value} value={option.value}>

                              {option.label}
                            </MenuItem>
                          ))
                        }
                      </TextField>

                    </div>
            </div>
            <br />

            <div className='row'>
              <div className='col-md-6'>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      className="form-control"
                      label="DRF Expiry Date"
                      inputVariant="outlined"
                      value={op_drf_expiry_date}
                      onChange={changeDrfExpiryDate}
                      required
                      showTodayButton
                      name="op_drf_expiry_date"
                      InputLabelProps={{
                        shrink: true,
                      }}

                    />

                  </MuiPickersUtilsProvider>
              </div>
              <div className="col-md-6">
                  <TextField variant="outlined" autoComplete="none"
                      {...opportunityRegister("department", {
                          
                          // minLength: {
                          //     value: 3,
                          //     message: "Department must be at least 3 characters"
                          // }
                      })}
                      
                      InputLabelProps={{
                          shrink: true,
                      }}
                      
                      onChange={e => onInputChange(e)}
                      value={department}
                      // helperText={opportunityErrors.department ? opportunityErrors.department.message : ''}
                      // error={Boolean(opportunityErrors.department)}
                      
                      className="form-control" id="department" type="text" name="department" label="Department" />
                 </div>
            </div>
            <br />

            <div className="row">
            </div>
            <div className="container container_tabs">
              <div className="lock_tabs" style={{ whiteSpace: "nowrap", maxWidth: '1000px', overflowX: 'auto', marginTop: "1rem" }}>


                {!createNewOpportunityModal && <a className={activeTab === 2 ? "tab active_tab" : "tab"}
                  onClick={() => toggleTab(2)} >
                  Meetings
                </a> }


                 <a className={activeTab === 1 ? "tab active_tab" : "tab"}
                  onClick={() => toggleTab(1)} >
                  Forecast details
                </a>

                {!createNewOpportunityModal && <a className={activeTab === 3 ? "tab active_tab" : "tab"}
                  onClick={() => toggleTab(3)} >
                  Add New Task
                </a> }

                {!createNewOpportunityModal && <a className={activeTab === 4 ? "tab active_tab" : "tab"}
                  onClick={() => toggleTab(4)} >
                  MEDDIC
                </a> }

                {(permissions.includes('viewOwnDemos') && !createNewOpportunityModal) ?
                  <a className={activeTab === 6 ? "tab active_tab" : "tab"}
                    onClick={() => toggleTab(6)} >
                    Demo
                  </a>
                  : ""}

                {(permissions.includes('viewOwnBenchmarks') && !createNewOpportunityModal) ?
                  <a className={activeTab === 7 ? "tab active_tab" : "tab"}
                    onClick={() => toggleTab(7)} >
                    Benchmark
                  </a>
                  : ""}

                {(permissions.includes('viewOwnTrainings') && !createNewOpportunityModal) ?
                  <a className={activeTab === 8 ? "tab active_tab" : "tab"}
                    onClick={() => toggleTab(8)} >
                    Training
                  </a>
                  : ""}

              </div>

              <div className="tab_content">
                <div className={activeTab === 1 ? "content active_content" : "content"}>
                  <div className="row">

                    <div className="col-md-4">
                          {/* <Select
                            isMulti
                            placeholder="Select Product"
                            name="colors"
                            options={productList}
                            className="basic-multi-select"
                            onChange={(value) => setOpportunity({...opportunity, op_product: value })}
                            classNamePrefix="select">

                          </Select> */}


                    {/* <Select
                      isMulti
                      name="op_products"
                      label="Product"
                      placeholder="Select Products"
                      options={productsList}
                      className="basic-multi-select"
                      onChange={value => onInputChange({target: {name: "op_products", value:value}})}
                      value={op_products}
                      classNamePrefix="select">

                    </Select> */}

                        {/* <TextField
                          variant="outlined"
                          type="text"
                          label="Product"
                          name="op_product"
                          className="form-control"
                          value={op_product}
                          select
                          isMulti={true}
                          helperText={opportunityErrors.op_product ? opportunityErrors.op_product.message : ''}
                          error={Boolean(opportunityErrors.op_product)}
                          onChange={e => onInputChange(e)}
                        >
                          {
                            productsList?.map((option, op_product) => (
                              <MenuItem key={option.value} selected={op_product === option.value} value={option.value}>

                                {option.label}
                              </MenuItem>
                            ))
                          }
                        </TextField> */}

                        </div>

                  </div>
                  <br />
                  
                  {/* <div className='row'> */}
                      {/* {op_products?.map((product, index) => ( */}
                        {/* <div key={index}> */}
                          {/* <div className="row"> */}
                            {/* <div className="col-md-3"> */}
                              {/* <TextField
                                variant="outlined"
                                InputProps={{
                                  startAdornment: <InputAdornment position="start"><b>₹</b></InputAdornment>
                                }}
                                type="text"
                                label={`PO - ${product.label}`}  
                                name="po_value"
                                className="form-control"
                                value={productPoData[product?.value]?.po_value}
                                onChange={e => onProductPoValuesChange(product.value, e.target.name, e.target.value)}
                              /> */}
                              

                  {/* {productPoData && techTeamManagersList?.length>0 && OpportunityProductsView() } */}

                
                {op_products && op_products?.map(item => {
                  console.log("productsleadsbyteam", item)
                // var item = op_products[i]
                var selected = []
                var techiesLists = []
                if(userData?.user_role_id?.role_name == "techie"){
                  selected = techiesListByManager?.filter(item1 => item1.value.toString() == productPoData[item?.value]?.techie_id)
                  techiesLists = techiesListByManager
                }else{
                  techiesLists = techTeamManagersList
                }

                if(selected?.length < 1){
                  selected = techTeamManagersList?.filter(item1 => item1.value.toString() == productPoData[item?.value]?.techie_id)
                  // techiesLists = techTeamManagersList
                }

                var selectedTechie = techiesList?.filter(item1 => item1.value.toString() == productPoData[item?.value]?.techie_id);
                // console.log("op_products[i]", op_products[i])
                // console.log("this printings on each selection", productPoData[item.value])
                // var item = op_products[index]

                  return(
                    <div className="row" style={{marginTop:'10px'}}>
                      <div className='col-md-2'>
                                          { !item?.label ?
                                        // <Select
                                        //   placeholder="Select Product"
                                        //   name="colors"
                                        //   isMulti={false}
                                        //   // selected={item}
                                        //   options={productsList}
                                        //   className="basic-multi-select"
                                        //   // defaultValue={item}
                                        //   onChange={(value) => handleProductSelection(value)}
                                        //   // onChange={(value) => setOpportunity({...opportunity, internal_contact_person: value })}
                                        //   classNamePrefix="select">

                                        // </Select>
                                        <div ref={searchContainerRef} className="search-container">
                                          
                                        <TextField
                                          variant="outlined"
                                          type="text"
                                          value={query}
                                          onChange={handleSearch}
                                          onFocus={() => setIsSearchListVisible(true)}
                                          className="form-control search_products_field"
                                          placeholder="Select Product"
                                        />
                                  
                                        {isSearchListVisible && (
                                          <div className="dropdown_content1234">
                                            {productsListData?.map((category, index) => (
                                              <Accordion
                                                key={index}
                                                expanded={expandedCategories.includes(category.name)}
                                                onChange={handleCategoryAccordionChange(category.name)}
                                              >
                                                <AccordionSummary
                                                  expandIcon={<ExpandMoreIcon />}
                                                  aria-controls={`category-${index}-content`}
                                                  id={`category-${index}-header`}
                                                  className="styel_summary"
                                                  key={`category-${index}-header`}
                                                >
                                                  <Typography  className="accordion-text">
                                                    {category.name}
                                                  </Typography>
                                                </AccordionSummary>
                                  
                                                <AccordionDetails>
                                                  <Typography className="typography_list">
                                                    {category?.subproducts?.length > 0 && (
                                                      <ul className="subProducts_list">
                                                        {category?.subproducts.map((subproduct, subIndex) => (
                                                          <Accordion
                                                            key={subIndex}
                                                            expanded={expandedProducts.includes(subproduct.name)}
                                                            onChange={handleProductAccordionChange(subproduct.name)}
                                                          >
                                                            <AccordionSummary
                                                              expandIcon={<ExpandMoreIcon />}
                                                              aria-controls={`product-${index}-${subIndex}-content`}
                                                              id={`product-${index}-${subIndex}-header`}
                                                              className="styel_summary"
                                                            >
                                                              <Typography className="accordion-text">
                                                                {subproduct.name}
                                                              </Typography>
                                                            </AccordionSummary>
                                                          
                                                            <AccordionDetails>
                                                              <Typography className="typography_list">
                                                                {subproduct.childProducts.length > 0 && (
                                                                  <ul className="childProducts_list">
                                                                    {subproduct.childProducts.map(
                                                                      (childProduct, childIndex) => (
                                                                        <li
                                                                          onClick={() => handleProductSelection(childProduct)}
                                                                          // onClick={() =>

                                                                          //   childProductDataHandle(childProduct)
                                                                          // }
                                                                          key={childIndex}
                                                                        >
                                                                          &#x2022; {childProduct.name}
                                                                        </li>
                                                                      )
                                                                    )}
                                                                  </ul>
                                                                )}
                                                              </Typography>
                                                            </AccordionDetails>
                                                          </Accordion>
                                                        ))}
                                                      </ul>
                                                    )}
                                                  </Typography>
                                                </AccordionDetails>
                                              </Accordion>
                                            ))}
                                          </div>
                                        )}
                                      </div>
                                        : <span>{item?.label}</span> }
                                        {/* <TextField variant="outlined"
                                            select
                                            onChange={(e) => handleProductSelection(e, item, index)}
                                            type="text" label="Product Name" name="op_po_value" className="form-control" value={item} >
                                            {
                                              productsList.map((item1) => (
                                                <MenuItem key={item1.value} selected={item1.label === item.label} value={item1.value}>{item1.label}</MenuItem>
                                              ))
                                            }
                                        </TextField> */}

                      </div>
                      <div className="col-md-2">
                        <TextField variant="outlined"
                          InputProps={{
                            startAdornment: <InputAdornment position="start"><b>₹</b></InputAdornment>
                          }}

                          type="number" label="Po Value " name="po_value" className="form-control" 
                          value={productPoData[item?.value]?.po_value} 
                          // onChange={e => onProductPoValuesChange(item.value, e.target.name, e.target.value)}
                          onChange={(itemId => (e) => {
                            onProductPoValuesChange(itemId, e.target.name, e.target.value);
                          })(item.value)}
                          />
                      </div>
                      <div className="col-md-1">
                                          <TextField variant="outlined"
                                            // InputProps={{
                                            //   startAdornment: <InputAdornment position="start"></InputAdornment>
                                            // }}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            type="number"  label="% KTPL" name="percentage_split_tokaizenat" className="form-control" 
                                            // value={op_percentage_split_tokaizenat} 
                                            // onChange={e => onInputChange(e)}
                                            value={productPoData[item.value]?.percentage_split_tokaizenat}
                                            // onChange={e => onProductPoValuesChange(item.value, e.target.name, e.target.value)}
                                            onChange={(itemId => (e) => {
                                              onProductPoValuesChange(itemId, e.target.name, e.target.value);
                                            })(item.value)}
                                          />
                                            {/* type="number" label="% To Kaizenat" name="op_percentage_split_tokaizenat" className="form-control" value={op_percentage_split_tokaizenat} onChange={e => onInputChange(e)} /> */}
                                        </div>

                                        <div className="col-md-2">
                                          <TextField variant="outlined"
                                            InputProps={{
                                              readOnly: true,
                                              startAdornment: <InputAdornment position="start"><b>₹</b></InputAdornment>
                                            }}

                                            type="text" label="KTPL( ₹ )" name="op_revenue_to_kaizenat" className="form-control" 
                                            value={Math.floor((productPoData[item.value]?.percentage_split_tokaizenat / 100) * productPoData[item.value]?.po_value).toLocaleString('en-IN')} 
                                            // onChange={e => onInputChange(e)} 
                                            // value = {productPoData[product.value]?.revenue_to_kaizenat}
                                            // onChange={e => onProductPoValuesChange(item.value, e.target.name, e.target.value)}
                                          />
                                        </div>

                                        <div className="col-md-2">
                                          <TextField variant="outlined"
                                            InputProps={{
                                              readOnly: true,
                                              startAdornment: <InputAdornment position="start"><b>₹</b></InputAdornment>
                                            }}
                                            type="text" label="To OEM( ₹ )" name="op_principal_rupees" className="form-control" 
                                            // value={productPoData[product.value]?.principal_in_rupees}
                                            value={Math.floor(productPoData[item.value]?.po_value - ((productPoData[item.value]?.percentage_split_tokaizenat / 100) * productPoData[item.value]?.po_value)).toLocaleString('en-IN')} 
                                            // onChange={e => onInputChange(e)} 
                                            // onChange={e => onProductPoValuesChange(item.value, e.target.name, e.target.value)}
                                            // onChange={(itemId => (e) => {
                                            //   console.log("Event object:", e, itemId, e.value);
                                            //   onProductPoValuesChange(itemId, "techie_id", e.value);
                                            // })(item.value)}
                                          />
                                        </div>

                                        {
                                          // (selected?.length > 0 || productPoData[item.value]?.techie_id === undefined) ?
                                          <div className="col-md-2">
                                            <Select
                                              isMulti={false}
                                              placeholder="Select Techie"
                                              name="colors"
                                              // isDisabled={ (userData?.user_role_id?.name == "techie" && permissions.includes('canReassignProduct')) ? true : false}
                                              options={(userData?.user_role_id?.role_name == "techie") ? techiesListByManager: techTeamManagersList}
                                              className="basic-multi-select"
                                              defaultValue={selected}
                                              // onChange={(value) => handleChange(item, "techie_id", value.value)()}
                                              onChange={(itemId => (value) => onProductPoValuesChange(itemId, "techie_id", value.value))(item.value)}
                                              // onChange={value => onProductPoValuesChange(item, "techie_id", value.value, i)}
                                              // onChange={(value) => setOpportunity({...opportunity, op_product: value })}
                                              classNamePrefix="select">
                                            </Select>
                                          </div>
                                            
                                          // : <div className="col-md-2">
                                          //     <label>{selectedTechie[0]?.label}</label>
                                          //   </div>
                                        }

                  {/* && productPoData[item.value]?.techie_id == userData._id */}
                  {/* userData?.user_role_id?.role_name == "techie" ||  */}
                      {((productPoData[item.value]?.techie_id && productPoData[item.value]?.previousTechie) && productPoData[item.value]?.previousTechie !== productPoData[item.value]?.techie_id) ? 
                          <div className="col-md-1">
                            <Button variant="outlined" className='btn btn-sm btn-success' color="secondary" onClick={(itemId => (value) => updateProduct(itemId, viewRowId))(item.value)}
                            //  onClick={() => updateProduct(productPoData[item.value], item.value, viewRowId)}
                            >
                              <AiOutlineCheck />
                            </Button>
                          </div>
                        :
                        <div className="col-md-1">
                          <Button variant="outlined" className='btn btn-sm btn-danger' color="secondary" 
                          // onClick={() => removeProduct(item.value)}
                          onClick={(itemId => (value) => removeProduct(itemId))(item.value)}
                          >
                            <AiOutlineDelete />
                          </Button>
                        </div>
                      } 
<br />
                    </div>
                    
                  )
                })
                }

                  <br />
                  {/* </div> */}
                    <div className="row">
                      <div className="mx-auto col-md-3">
                        <Button variant="outlined" className="btn btn-small btn-primary" color="primary" onClick={addNewProduct}>
                          Add New Product
                        </Button>
                      </div>
                    </div>

                  <br />
                  {/* <div className="row">
                    
                    <div className="col-md-4">
                      <TextField variant="outlined"
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><b>₹</b></InputAdornment>
                        }}

                        type="text" label="PO Value" name="op_po_value" className="form-control" 
                        // value={op_po_value} 
                        value={Object.values(productPoData)
                          .map(productData => Number(productData.po_value) || 0)
                          .reduce((sum, currentValue) => sum + currentValue, 0)}
                        readOnly
                        // onChange={e => onInputChange(e)}
                         />
                    </div>

                    {/* <div className="col-md-4">
                      <TextField variant="outlined"
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><b>%</b></InputAdornment>
                        }}
                        
                        type="number" label="% To Kaizenat" name="op_percentage_split_tokaizenat" className="form-control" value={op_percentage_split_tokaizenat} onChange={e => onInputChange(e)} />
                    </div> */}

                  <div className="row">
                    <div className="col-md-4">
                      <TextField variant="outlined"
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><b>₹</b></InputAdornment>
                        }}

                        type="text" label="PO Value" name="op_po_value" className="form-control" 
                        // value={op_po_value} 
                        value={Object.values(productPoData)
                          .map(productData => Number(productData.po_value) || 0)
                          .reduce((sum, currentValue) => sum + currentValue, 0)}
                        readOnly
                        // onChange={e => onInputChange(e)}
                         />
                    </div>
                    <div className="col-md-4">
                      <TextField variant="outlined"
                        InputProps={{
                          readOnly: true,
                          startAdornment: <InputAdornment position="start"><b>₹</b></InputAdornment>
                        }}
                        type="text" label="To Kaizenat( ₹ )" name="op_revenue_to_kaizenat" className="form-control" 
                        // value={Math.floor((op_percentage_split_tokaizenat / 100) * op_po_value).toLocaleString('en-IN')} 
                        ref={toKaizenatValue}
                        value={Object.values(productPoData)
                          .map(productData => {
                            const { percentage_split_tokaizenat, po_value } = productData;
                            return Math.floor((percentage_split_tokaizenat / 100) * po_value) || 0;
                          })
                          .reduce((sum, currentValue) => sum + currentValue, 0)
                          .toLocaleString('en-IN')} 
                        onChange={e => onInputChange(e)} />
                    </div>
                    <div className="col-md-4 ">
                      <TextField variant="outlined"
                        InputProps={{
                          readOnly: true,
                          startAdornment: <InputAdornment position="start"><b>₹</b></InputAdornment>
                        }}
                        type="text" label="To OEM( ₹ )" name="op_principal_rupees" className="form-control" 
                        ref={toOEMValue}
                        value={Object.values(productPoData)
                          .map(productData => {
                            const { percentage_split_tokaizenat, po_value } = productData;
                            return Math.floor(po_value - ((percentage_split_tokaizenat / 100) * po_value)) || 0;
                          })
                          .reduce((sum, currentValue) => sum + currentValue, 0)
                          .toLocaleString('en-IN')} 

                        // value={Math.floor(op_po_value - ((op_percentage_split_tokaizenat / 100) * op_po_value)).toLocaleString('en-IN')} 
                        onChange={e => onInputChange(e)} />
                    </div>
                  </div>
                  <br /> 
                  <div class="row">
                    
                    {/* <div className="col-md-4 ">
                      <TextField variant="outlined"
                        InputProps={{
                          readOnly: true,
                          startAdornment: <InputAdornment position="start"><b>₹</b></InputAdornment>
                        }}
                        type="text" label="To OEM( ₹ )" name="op_principal_rupees" className="form-control" value={Math.floor(op_po_value - ((op_percentage_split_tokaizenat / 100) * op_po_value)).toLocaleString('en-IN')} onChange={e => onInputChange(e)} />
                    </div> */}
                    {/* <div className="col-md-4">
                      <TextField variant="outlined"
                        InputProps={{
                          readOnly: true,
                          startAdornment: <InputAdornment position="start"><b>$</b></InputAdornment>
                        }}
                        type="text" name="op_principal_dollars" label="To OEM( $ )" value={Math.floor((op_po_value - ((op_percentage_split_tokaizenat / 100) * op_po_value)) / 73.6).toLocaleString('en-IN')} className="form-control" readOnly={true} />
                    </div> */}
                  </div>
                  <br />
                  <div class="row">
                    {/* <div className="col-md-4 ">
                      <TextField variant="outlined"
                        InputProps={{
                          readOnly: true,
                          startAdornment: <InputAdornment position="start"><b>₹</b></InputAdornment>
                        }}
                        type="text" label="To OEM( ₹ )" name="op_principal_rupees" className="form-control" value={Math.floor(op_po_value - ((op_percentage_split_tokaizenat / 100) * op_po_value)).toLocaleString('en-IN')} onChange={e => onInputChange(e)} />
                    </div> */}
                    <div className="col-md-4">
                      <TextField variant="outlined"
                        InputProps={{
                          readOnly: true,
                          startAdornment: <InputAdornment position="start"><b>$</b></InputAdornment>
                        }}
                        ref={toOEMinDollarsValue}
                        type="text" name="op_principal_dollars" label="To OEM( $ )" 
                        value={Object.values(productPoData)
                          .map(productData => {
                            const { percentage_split_tokaizenat, po_value } = productData;
                            return Math.floor((po_value - ((percentage_split_tokaizenat / 100) * po_value)) / 73.6) || 0;
                          })
                          .reduce((sum, currentValue) => sum + currentValue, 0)
                          .toLocaleString('en-IN')} 
                        // value={Math.floor((op_po_value - ((op_percentage_split_tokaizenat / 100) * op_po_value)) / 73.6).toLocaleString('en-IN')} 
                        className="form-control" 
                        readOnly={true}
                      />
                    </div>
                    <br />
                    {showPoDate ?
                      <div className="col-md-4">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            className="form-control"
                            label="Select PO Date"
                            inputVariant="outlined"
                            value={poDate}
                            onChange={handlePoDateChange}
                            showTodayButton
                            {...showPoDate ? "required" : ""}
                            inputRef={op_po_date}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </MuiPickersUtilsProvider>

                      </div>
                      : ""}
                    <br />
                    <div className="col-md-4">
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        label="Closure Quarter"
                        className="form-control"
                        name="op_closing_quarter"
                        disabled
                        value={op_closure_qtr}
                      >
                      </TextField>
                    </div>
                                        <div className="col-md-4">
                      <TextField variant="outlined"
                        {...opportunityRegister("op_business_type", {
                          minLength: {
                            value: 3,
                            message: "Business Type must be at least 3 characters"
                          }
                        })}
                        select
                        helperText={opportunityErrors.op_business_type ? opportunityErrors.op_business_type.message : ''}
                        error={Boolean(opportunityErrors.op_business_type)}
                        label="Business Type"
                        type="text" className="form-control" onChange={e => onInputChange(e)} value={op_business_type} name="op_business_type" >

                        {
                          businessTypesData?.map((option, op_business_type) => (
                            <MenuItem key={option.value} selected={op_business_type === option.value} value={option.value}>

                              {option.label}
                            </MenuItem>
                          ))
                        }
                      </TextField>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-4">
                      <TextField variant="outlined" type="text" label="Sales Stage"
                        name="op_sales_stage" className="form-control"
                        select
                        value={op_sales_stage} onChange={e => onInputChange(e)} >
                        {
                          leadSalesStagesList.map((option, op_sales_stage) => (
                            <MenuItem key={option.value} data-id={option.confidence_level} data-label={option.label} selected={op_sales_stage === option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))
                        }
                      </TextField>
                    </div>
                    <div className="col-md-4">
                      <TextField variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled
                        // isDisabled={true}
                        type="text" label="Sales Confidence"
                        InputProps={{
                          readOnly: true,
                        }}
                        name="op_sales_confidence" className="form-control" value={opportunity.op_sales_confidence} readOnly={true} />
                    </div>
                    <div className="col-md-4">
                      <TextField variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        select
                        type="text" label="Timeline"
                        onChange={timelineChange}
                        name="op_timeline"
                        className="form-control" value={op_timeline} >
                        {
                          timeLineDateList.map((option, op_timeline) => (
                            <MenuItem key={option} value={option} selected={(op_timeline == option) ? true : ''}>
                              {option}
                            </MenuItem>
                          ))
                        }
                      </TextField>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div>
                    {/* props.opportunityType === "Open" ||  */}
                    {/* props.disableUpdate !== true &&  */}

                      {((permissions.includes('updateOwnOpportunities') && (opportunityDetails?.sales_confidence !== 'Won')) || permissions.includes("updateAnyOpportunities"))

                        ? <a disabled={disable} className="btn btn-sm   upadte_common_btn" onClick={() => updateForecastDetails()} >Update All </a> : false}
                    </div>
                  </div>
                  <br />
                </div>
                <div className={activeTab === 2 ? "content active_content" : "content"}>
                  <div className="meeting_border shadow withText" style={{ border: '1px solid #c4c4c4', padding: '1rem', background: '#fff' }}>
                    <label className='labels'>Meeting Details (<span style={{ fontSize: '10px' }}>All fields mandatory to add new meeting</span>)</label>
                    <br />
                    <div className="row">
                      <div className="col-md-6">

                        <TextField variant="outlined" type="text" label="Sales Activity *"
                          name="op_sales_activity" className="form-control"
                          select
                          value={op_sales_activity} onChange={e => onInputChange(e)} >
                          {
                            salesActivitiesList.map((option, op_sales_activity) => (
                              <MenuItem key={option.value} selected={op_sales_activity === option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))
                          }
                        </TextField>

                      </div>
                      <div className="col-md-6">
                        <CreatableSelect
                          closeMenuOnSelect={true}
                          className="basic-single"
                          classNamePrefix="select"
                          isSearchable="true"
                          options={contactsList}
                          placeholder="Search Contact Person *"
                          inputRef={contact_name}
                          name="contact_name"
                          onChange={handleSetContactName}
                          styles={colourStyles}
                          autoComplete="none"
                          formatCreateLabel={() => `Add New Contact`}
                          isValidNewOption={() => true}
                          required
                        >
                        </CreatableSelect>
                      </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-md-6'>

                          <Select
                            isMulti
                            placeholder="Select Internal Contact"
                            name="colors"
                            options={accountManagersList}
                            className="basic-multi-select"
                            onChange={(value) => setOpportunity({...opportunity, internal_contact_person: value })}
                            classNamePrefix="select">
                          </Select>
              </div>
              <div className='col-md-6'>
                <TextField variant="outlined"
                  {...opportunityRegister("meeting_type", {
                    required: {
                      value: true,
                      message: "Meeting Type is required"
                    },
                    minLength: {
                      value: 3,
                      message: "Meeting Type must be at least 3 characters"
                    }
                  })}
                  select
                  value={meeting_type}
                  type="text" className="form-control" name="meeting_type" label="Meeting Type" onChange={e => onInputChange(e)} >
                  {
                    ["Online", "Offline"].map((item, leadType) => (
                      <MenuItem key={item} selected={leadType} value={item}>{item}</MenuItem>
                    ))
                  }

                </TextField>
              </div>
                        </div>
                        <br/>
                    <div className="row">
                      <div className="col-md-12">
                        <TextField
                          InputProps={{
                            endAdornment: <IconWithTooltip />
                          }}

                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          multiline
                          label="Meeting Summary *"
                          className="form-control"
                          name="op_meeting_summary"
                          onChange={e => onInputChange(e)}
                          value={op_meeting_summary}
                        >
                        </TextField>
                      </div>
                    </div>
                    <br />
                    <div className="row">

                      <div className="col-md-6">

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DateTimePicker
                            className="form-control"
                            label="Meeting Date *"
                            inputVariant="outlined"
                            value={meetingDate}
                            onChange={handleMeetingDateChange}
                            labelFunc={formatWeekSelectLabel}
                            showTodayButton
                            maxDate={twodaysdate}
                            minDate={twodayless}
                            inputRef={op_meeting_date}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div>
                      {/* && props.disableUpdate !== true && props.opportunityType === "Open" */}
                        {(permissions.includes('updateOwnOpportunities') || permissions.includes("updateAnyOpportunities")) 
                          ? <a disabled={disable} className="btn btn-sm   upadte_common_btn" onClick={() => addSalesActivity()} >Add Meeting</a> : false}
                      </div>
                    </div>

                    <div>
                      <MettingTable keyData="viewOpportunitiesMeetings" mettingDataInViewOpportunities={meetingsList} />
                    </div>
                  </div>
                  <br />
                </div>
                <div className={activeTab === 3 ? "content active_content" : "content"} >
                  <div class="row">
                    <div style={{ marginLeft: '.2rem' }}>
                      <div className="headerBand" onClick={() => setShowNewActionItemDiv(!showNewActionItemDiv)}>
                        <label style={{ cursor: 'pointer' }}>{showNewActionItemDiv ? "Add New Task" : " Add New Task"}</label>
                        <IoIosArrowDropdown className={`filters__chevron arrow_button ${rotateChevron1 ? "rotate" : ''}`} onClick={handleRotate1} />
                      </div>
                    </div>
                    <>
                      {showNewActionItemDiv ?
                        <div>
                          <br />
                          <div className="row">
                            <div className="col-md-10">
                              <TextField variant="outlined"
                                type="text" label="Action Item Name"
                                inputRef={ta_task_name} name="ta_task_name" className="form-control" />
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-md-10 ">
                              <TextField multiline rows={2} variant="outlined" type="text" label="Action Item Description" inputRef={ta_task_description} name="task_description" className="form-control" />
                            </div>
                          </div>
                          <br />
                          <br />
                          <div className="row">
                            <div className="col-md-5">
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                  className="form-control"
                                  label="Action Date"
                                  inputVariant="outlined"
                                  value={actionDate}
                                  onChange={handleActionDateChange}
                                  labelFunc={formatWeekSelectLabel}
                                  showTodayButton
                                  inputRef={ta_action_date}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />

                              </MuiPickersUtilsProvider>

                            </div>

                            <div className="col-md-5">
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                  className="form-control"
                                  label="Reminder Date"
                                  inputVariant="outlined"
                                  value={reminderDate}
                                  onChange={handleReminderDateChange}
                                  labelFunc={formatWeekSelectLabel}
                                  showTodayButton
                                  inputRef={ta_reminder_date}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </MuiPickersUtilsProvider>

                            </div>

                          </div>
                          <br />
                          <div className="row">
                            <div>
                            {/* && props.disableUpdate !== true && props.opportunityType === "Open" */}
                              {(permissions.includes('updateOwnOpportunities') || permissions.includes("updateAnyOpportunities")) 
                                ? <a disabled={disable} className="btn btn-sm   upadte_common_btn" onClick={() => addNewOpportunityTask()} >Add New Task</a> : false}
                            </div>
                          </div>
                          <br />
                        </div>
                        : " "}
                    </>

                    <br />
                    <div style={{ marginTop: '1rem' }}>
                      <TasksListTable data={opportunitiesTasks} rowId={viewRowId}/>
                    </div>
                  </div>

                </div>
                <div className={activeTab === 4 ? "content active_content" : "content"}>

                  <>
                    <div className="sales_approach_main">
                      <div className="sales_approach_sub1" style={{ background: "#113667" }}>
                        <select className={(leadDetail?.meddic?.m === "2" || leadDetail?.meddic?.m === 2) && "yes_no1 yes_no_green" || (leadDetail?.meddic?.m === 0 || leadDetail?.meddic?.m === "0") && "yes_no1 yes_no_red" || (leadDetail?.meddic?.m === 1 || leadDetail?.meddic?.m === "1") && "yes_no1 yes_no_partial" || "yes_no1"} name="m" value={leadDetail?.meddic?.m} >
                          <option value="">Select</option>
                          <option className='text_opion' value="2">Yes</option>
                          <option className='text_opion' value="0">No</option>
                          <option value="1">Partial</option>
                        </select>
                        <div className="sales_approach_heading1">
                          <p><span className='font-30'>M</span>etric</p>
                          <p>Economic Impact of the solution</p>
                        </div>
                        <div className='sales_approach_content1'>
                          <div className="about_soluctiondiv1">
                            <div className="content_textpart1">

                              <span>{leadDetail?.metric_notes}</span>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="sales_approach_sub" style={{ background: "#225798" }}>
                        <select className={(leadDetail?.meddic?.eb === 2 || leadDetail?.meddic?.eb === "2") && "yes_no yes_no_green" || (leadDetail?.meddic?.eb === 0 || leadDetail?.meddic?.eb === "0") && "yes_no yes_no_red" || (leadDetail?.meddic?.eb === 1 || leadDetail?.meddic?.eb === "1") && "yes_no yes_no_partial" || "yes_no"} name="da_eb" value={leadDetail?.meddic?.eb} >
                          <option value="">Select</option>
                          <option className='text_opion' value="2">Yes</option>
                          <option className='text_opion' value="0">No</option>
                          <option value="1">Partial</option>
                        </select>
                        <div className="sales_approach_heading">
                          <p><span className='font-30'>E</span>conomic Buyer</p>
                          <p>Discretionary use of funds/budget</p>
                        </div>
                        <div className='sales_approach_content'>
                          <div className="about_soluctiondiv">
                            <div className="content_textpart dropdown_box">
                              <TextField variant="outlined"
                                multiline row={3}

                                InputLabelProps={{
                                  shrink: true,
                                }}

                                id="da_economic_buyer"

                                type="text" label="" name="da_economic_buyer" className="form-control"
                                value={leadDetail?.economic_buyer?.contact_name}
                              >

                              </TextField>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sales_approach_sub" style={{ background: "#3278ca" }}>
                        <select className={(leadDetail?.meddic?.dc === 2 || leadDetail?.meddic?.dc === "2") && "yes_no yes_no_green" || (leadDetail?.meddic?.dc === 0 || leadDetail?.meddic?.dc === "0") && "yes_no yes_no_red" || (leadDetail?.meddic?.dc === 1 || leadDetail?.meddic?.dc === "1") && "yes_no yes_no_partial" || "yes_no"} name="dc" value={leadDetail?.meddic?.dc} >
                          <option value="">Select</option>
                          <option className='text_opion' value="2">Yes</option>
                          <option className='text_opion' value="0">No</option>
                          <option value="1">Partial</option>
                        </select>
                        <div className="sales_approach_heading">
                          <p><span className='font-30'>D</span>ecision criteria</p>
                          <p>Key player driving the opportunity</p>
                        </div>
                        <div className='sales_approach_content'>


                          <div className="about_soluctiondiv">
                            <div className="content_textpart">


                              <div className='decision_criteria_main'>
                                <div className="decision_criteria_sub">
                                  <div className="sub_heading">
                                    <h6>Financial Justification(ROI)</h6>
                                  </div>
                                  <div className="descrion_subhading_textdropdown">
                                    <select className='descision_yes_no' name="da_decision_criteria_financial_justification_bool" value={leadDetail?.dc_fj_bool}  >
                                      <option value="yes">Yes</option>
                                      <option value="no">No</option>
                                    </select>
                                  </div>
                                  <div className="decision_textb">
                                    {leadDetail?.dc_fj_bool === "yes" ?
                                      <div className="browse_file">

                                        <input type="file" className='custom-file-input' id="file" name="file" accept=".xlsx, .xls, .csv" style={{ display: "none" }} />
                                        {
                                          leadDetail.roi_sheet &&
                                          <a target="_blank" href={leadDetail.roi_sheet} className='browse_btn'>View file</a>
                                        }
                                      </div>
                                      :
                                      <>
                                        <TextField variant="outlined"

                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          value={leadDetail?.financial_justification}
                                          type="text" label="" className="form-control" >

                                        </TextField>
                                      </>
                                    }

                                  </div>
                                </div>
                                <div className="decision_criteria_sub">
                                  <div className="sub_heading">
                                    <h6>Technical Validation</h6>
                                  </div>
                                  <div className="descrion_subhading_textdropdown">
                                    <select className='descision_yes_no' name="da_decision_criteria_technical_validation_bool" value={leadDetail?.dc_tv_bool} >
                                      <option value="yes">Yes</option>
                                      <option value="no">No</option>
                                      <option value="partial">Partial</option>
                                    </select>
                                  </div>
                                  <div className="decision_textb">
                                    {leadDetail?.dc_tv_bool === "yes" ? ""
                                      :
                                      <TextField variant="outlined"


                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        value={leadDetail?.technical_validation}
                                        id="da_dc_technical_validation_notes"
                                        type="text" label="" name="technical_validation" className="form-control"  >

                                      </TextField>
                                    }

                                  </div>
                                </div>
                              </div>

                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="sales_approach_sub" style={{ background: "#4397ff" }}>
                        <select className={(leadDetail?.meddic?.dp === 2 || leadDetail?.meddic?.dp === "2") && "yes_no yes_no_green" || (leadDetail?.meddic?.dp === 0 || leadDetail?.meddic?.dp === "0") && "yes_no yes_no_red" || (leadDetail?.meddic?.dp === 1 || leadDetail?.meddic?.dp === "1") && "yes_no yes_no_partial" || "yes_no"} name="dp" value={leadDetail?.meddic?.dp} >
                          <option value="">Select</option>
                          <option className='text_opion' value="2">Yes</option>
                          <option className='text_opion' value="0">No</option>
                          <option value="1">Partial</option>
                        </select>
                        <div className="sales_approach_heading">
                          <p><span className='font-30'> D</span>ecision process</p>
                          <p>Compare vendors offeringsy</p>
                        </div>
                        <div className='sales_approach_content_extra'>

                          <div className="about_soluctiondiv">
                            <div className="content_textpart">
                              <span className='proccess_follow_main'>{leadDetail?.decision_processes}</span>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="sales_approach_sub" style={{ background: "#75b2ff" }}>
                        <select className={(leadDetail?.meddic?.ip === 2 || leadDetail?.meddic?.ip === "2") && "yes_no yes_no_green" || (leadDetail?.meddic?.ip === 0 || leadDetail?.meddic?.ip === "0") && "yes_no yes_no_red" || (leadDetail?.meddic?.ip === 1 || leadDetail?.meddic?.ip === "1") && "yes_no yes_no_partial" || "yes_no"} name="ip" value={leadDetail?.meddic?.ip} >
                          <option value="">Select</option>
                          <option className='text_opion' value="2">Yes</option>
                          <option className='text_opion' value="0">No</option>
                          <option value="1">Partial</option>
                        </select>
                        <div className="sales_approach_heading">
                          <p><span className='font-30'>I</span>dentify pain</p>
                          <p>Pain Points: Define the cosst of doing nothing</p>
                        </div>
                        <div className='sales_approach_content'>

                          <div className="about_soluctiondiv">
                            <div className="content_textpart">
                              <TextField variant="outlined"
                                multiline rows={2}

                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={leadDetail?.identify_pain_notes}
                                id="da_identify_pain_notes"

                                type="text" label="" name="da_identify_pain_notes" className="form-control"  >
                              </TextField>
                            </div>

                          </div>
                        </div>
                      </div>

                      <div className="sales_approach_sub" style={{ background: "#a5ceff" }}>
                        <select className={(leadDetail?.meddic?.ch === 2 || leadDetail?.meddic?.ch === "2") && "yes_no yes_no_green" || (leadDetail?.meddic?.ch === 0 || leadDetail?.meddic?.ch === "0") && "yes_no yes_no_red" || (leadDetail?.meddic?.ch === 1 || leadDetail?.meddic?.ch === "1") && "yes_no yes_no_partial" || "yes_no"} name="da_ch" value={leadDetail?.meddic?.ch} >
                          <option value="">Select</option>
                          <option className='text_opion' value="2">Yes</option>
                          <option className='text_opion' value="0">No</option>
                          <option value="1">Partial</option>
                        </select>
                        <div className="sales_approach_heading">
                          <p><span className='font-30'>C</span>hampion</p>
                          <p>Key player with power and influence</p>
                        </div>
                        <div className='sales_approach_content'>

                          <div className="about_soluctiondiv">
                            <div className="content_textpart dropdown_box">
                              <TextField variant="outlined"
                                multiline row={3}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                id="da_champion_contact"
                                value={leadDetail?.champion_contact?.contact_name}
                                type="text" label="" name="da_champion_contact" className="form-control" >

                              </TextField>


                            </div>

                          </div>
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <span><b>Score - {leadDetail?.meddic?.score} %</b></span>&nbsp;&nbsp;&nbsp;
                        MEDDIC Guidance - &nbsp;
                        {(leadDetail?.meddic?.score > 74) && <span style={{ backgroundColor: '#000', color: 'lightgreen', fontSize: '20px' }}><b>Forecast</b></span>}
                        {(leadDetail?.meddic?.score > 49 && leadDetail?.meddic?.score < 74) && <span style={{ backgroundColor: '#000', color: 'lightblue', fontSize: '20px' }}>Upside</span>}
                        {(leadDetail?.meddic?.score > 26 && leadDetail?.meddic?.score < 49) && <span style={{ backgroundColor: '#000', color: 'yellow', fontSize: '20px' }}>Qualified Pipeline </span>}
                        {(leadDetail?.meddic?.score < 26) && <span style={{ backgroundColor: '#000', color: '#fff', fontSize: '20px' }}>Unqualified Pipeline</span>}
                      </div>


                      <br />
                      <div className="row" id="decisioinParameters">
                        <br />
                        <span>Existing CAD/CAE Software Licenses </span>

                      </div>
                      <br />

                      <div style={{ marginLeft: '10px' }} className='row' id="da_budget">
                        {
                          (leadDetail?.license_product_list && leadDetail?.license_product_list?.length > 0) ?
                          leadDetail?.license_product_list.map(item => {
                              return (
                                <>
                                  <span>{item[0]} - {item[1]} license</span>
                                </>
                              )
                            })
                            : false
                        }
                      </div>
                      <br />
                      <div className="row">
                        <div className='col-lg-6' style={{ marginTop: '15px' }}>
                          <TextField
                            InputProps={{
                              startAdornment: <InputAdornment position="start"><b>₹</b></InputAdornment>
                            }}
                            variant="outlined"
                            type="number" name="da_budget" label="Budget*"
                            className="form-control" value={leadDetail?.budget} />
                        </div>

                        <div className='col-md-6' style={{ marginTop: '15px' }}>
                          <TextField variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            type="text" label="Timeline*" name="da_timeline" className="form-control" value={leadDetail?.timeline}>
                          </TextField>
                        </div>
                      </div>
                      <br />
                    </div>
                  </>

                </div>
                <div className={activeTab === 6 ? "content active_content" : "content"} >
                  <div class="row">
                    <DemoTable data={demosList} />
                  </div>
                </div>
                <div className={activeTab === 7 ? "content active_content" : "content"} >
                  <div class="row">
                    <BenchmarkTable data={benchmarksList} />
                  </div>
                </div>
                <div className={activeTab === 8 ? "content active_content" : "content"} >
                  <div class="row">
                    <TrainingTable data={trainingsList} />
                  </div>
                </div>
              </div>
            </div>
          </form>

        </Modal.Body>
      </Modal >
      {/* <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => openmodal()} >Add new Contact</Button> */}
                      {AddcontactModal ? <AddNewContact show={AddcontactModal} keyData="CommonKeyData" otherCompanyName={opportunity.op_company_name} otherAccountId={opportunity.op_account_id} onHide={() => dispatch(ContactActions.AddContactcloseModal())}/> : ""}
                      {showWonPoModal ? <WonOpportunitiesPoModal show={showWonPoModal} onCancelled={() => onPoModalCancelled()} onHide={() => setShowWonPoModal(false)} poquationStatus={statusHandle} opportunitiesId={viewRowId}  /> : ""}
      {/* {showAddContactModal ? <AddNewContact show={showAddContactModal} companyName={opportunity.op_company_name} accountId={opportunity.op_account_id} refreshContactslist={() => refreshContactsList()} returnValue={(contactId) => returnValue(contactId)} onHide={() => setShowAddContactModal(false)} /> : ""} */}

    </>
  )
});

const mapStateToProps = (state) => ({
  user : state.auth.user,
  opportunityDetails : state.opportunities.opportunityDetails,
  leadDetail : state.opportunities.leadDetail,
  meetingsList : state.opportunities.meetingsList,
  contactsList : state.opportunities.contactsList,
  opportunitiesTasks : state.opportunities.opportunitiesTasks,
  chartData : state.opportunities.chartData,
  viewRowId : state.opportunities.viewRowId,
  viewAccountId : state.opportunities.viewAccountId,
  accountManagersList : state.leads.accountManagersList,
  ansysAccountManagers : state.opportunities.ansysAccountManagers,
  drfStatusList : state.leads.drfStatusList,
  regionsList : state.dashboard.regionsList,
  businessTypesData : state.dashboard.businessTypesData,
  leadSalesStagesList : state.dashboard.leadSalesStagesList,
  salesActivitiesList : state.dashboard.salesActivitiesList,
  demosList : state.dashboard.demosList,
  benchmarksList : state.dashboard.benchmarksList,
  trainingsList : state.dashboard.trainingsList,
  showOpportunityModal : state.opportunities.showOpportunityModal,
  productsList : state.dashboard.productsList,
  AddcontactModal : state.Contactlist.AddcontactModal,
  techiesList : state.dashboard.techiesList,
  rowid : state.opportunities.rowId,
  newproductsLists :state.dashboard.newproductsList,
  createNewOpportunityModal : state.opportunities.createNewOpportunityModal,
  activeTab : state.opportunities.activeTab,
  techTeamManagersList : state.opportunities.techTeamManagersList,
  techiesListByManager : state.opportunities.techiesListByManager,
  missingProductName : state.opportunities.missingProductName
});

const mapDispatchToProps = (dispatch) => ({
  getAccontManagersListData : () => dispatch(getAccontManagersList()),
  addSalesActivityData : (data) => dispatch(addSalesActivityDetails(data)),
  addNewOpportunityTaskData : (data) => dispatch(addNewOpportunityTask(data)),
  updateForecastData : (data) => dispatch(updateForecast(data)),
  UpdateOpportunityHandleData : (data) => dispatch(UpdateOpportunityHandleDetails(data)),
  getEconomicBuyersData : (data) => dispatch(getEconomicBuyersList(data)),
  getAnsysAccountManagersData : () => dispatch(getAnsysAccountManagersList()),
  getDrfStatusData : () => dispatch(getDrfStatusList()),
  getProductsListData : () => dispatch(getProductsList()),
  getRegionsData : () => dispatch(getRegionsList()),
  getBusinessTypesData : () => dispatch(getBusinessTypesList()),
  getLeadStagesData : () => dispatch(getLeadStagesList()),
  getDemosList : (data) => dispatch(getDemosListData(data)),
  getBenchmarksData : (data) => dispatch(getBenchmarksList(data)),
  getTrainingsData : (data) => dispatch(getTrainingsList(data)),
  updateOpportunityModal : (data) => dispatch(opportunitiesActions.updateOpportunityModal(data)),
  openmodal :(data) => dispatch(ContactActions.AddContactopenModal(data)),
  fetchTechiesListData : () => dispatch(fetchTechiesList()),
  getNewProductsData :() => dispatch(getNewProductsList()),
  createNewOpportunityData : (data) => dispatch(createNewOpportunity(data)),
  // updateViewRowData : (data) => dispatch(updateViewRowDetails(data)),
  updateActiveTabData : (data) => dispatch(opportunitiesActions.updateActiveTab(data)),
  getTechTeamManagersData : (data) => dispatch(getTechTeamManagers(data)),
  getTechiesListByManagerData : (data) => dispatch(getTechiesListByManager()),
  reAssignTechieData : (data) => dispatch(reAssignTechie(data))
});

export default connect(mapStateToProps,mapDispatchToProps)(ViewOpportunities);