import React, { useEffect, useState, useCallback } from 'react';
import { getMuiTheme } from "../../components/commonColumns/commonComponents";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { meetingColumns, options } from './commonProps';
import RenderDateFilter from "./RenderDateFilter";
import { getEventReports } from "../../store/EventTracker";
import { connect } from 'react-redux';

const EventMeetings = (props) => {

    const { filters, setFilters, isFetching, getMeetings, meetings } = props

    const fetchData = useCallback(() => {
        let input = {
            event_type: "meetings",
            start_date: filters.startDate,
            end_date: filters.endDate
        }
        getMeetings(input)
    })

    useEffect(() => {
        fetchData()
    }, [filters.startDate, filters.endDate])

    window.addEventListener('keydown', (e) => {

        // e.preventDefault();
        console.log("e.key", e.key)
        if (e.ctrlKey && (e.key === 's' || e.key === 'p')) {
            e.preventDefault();
        }
        // Prevent default actions for Print Screen (PrtScn)
        if (e.key === 'PrintScreen') {
              console.log("print screen")
              e.preventDefault();
        }
      
        // Prevent default actions for the Windows key
        if (e.key === 'Meta') { // Meta key represents the Windows key
          console.log("meta")
              e.preventDefault();
        }
    
      });

    return (
        <>
        <div
            onContextMenu={(e) => {
                e.preventDefault(); // Prevent right-click menu
            }}
            style={{ position: 'relative' }} // Optional: Ensure proper layout
        >
            <React.Fragment>
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={<div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }} >
                            <b>Meetings List</b>&nbsp;&nbsp;&nbsp;
                            <RenderDateFilter filters={filters} setFilters={setFilters} />
                        </div>
                        }
                        data={meetings}
                        columns={meetingColumns}
                        options={options(isFetching)}
                        className="no-select" 
                    />
                </MuiThemeProvider>
            </React.Fragment>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    isFetching: state.EventTracker.isFetching,
    meetings: state.EventTracker.meetings
})

const mapDispatchToProps = (dispatch) => ({
    getMeetings: (params) => dispatch(getEventReports(params))
})

export default connect(mapStateToProps, mapDispatchToProps)(EventMeetings);