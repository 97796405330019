import React, { useEffect, useState,useMemo } from 'react';
import MUIDataTable from "mui-datatables";
import moment from 'moment-timezone'; 
import { getMuiTheme, headerProps } from './commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";
import { connect, useSelector } from 'react-redux';
import {meetingColumnUpdateddata,getmeetingColumnListHandle} from "../../store/Common";
import{getColumnsList, MeetingActions, fetchMeetingsList, updateColumnsList} from "../../store/Meeting"

const MettingTable = React.memo ((props) => {
    const { meetingupdatedata,
            meetinglistdata,
            isFetching,
            userVariable,
            ColumnsList,
            Meeting_columns,
            columnsList,
            getColumnsList,
            mettingDataInViewOpportunities,
            keyData,
            pdateColumnsListData,
            fetchMeetingsPaginationData, 
            meetingsListNew,
            totalRows,updateColumnsListData
         } =props


         const {user} = useSelector(state => state.auth)
         const userData = user.user;

    const [allTableData, setAllTableData] = useState([]);
    const [isFetchingCondition,setIsFetchingCondition] = useState(isFetching)
    useEffect(() => {
        var listArray = []

        if(keyData === "viewOpportunitiesMeetings"){
          listArray = mettingDataInViewOpportunities
          setIsFetchingCondition(false)
        }else{
          listArray = meetinglistdata
          setIsFetchingCondition(false)
        }
        setAllTableData(listArray)

        getColumnsList();
    },[meetinglistdata,mettingDataInViewOpportunities,keyData])

    // useEffect(() => {
    //     console.log("columnsList ", columnsList)
    // }, [columnsList])

    // const LoaderText = () => {
    //    return   isFetching ? <div className="parent-div">
    //    <div className="loader"></div>
    //  </div>:"No Data Found.." 
    // }
    const LoaderText = () => {
        return isFetchingCondition ? (
          <div className="parent-div">
            <div className="loader"></div>
          </div>
        ) : allTableData?.length === 0 ? (
          "No Data Found.."
        ) : null;
      };

    const options = {
        textLabels: {
            body: {
                noMatch: LoaderText()
            },
           
        },
    
        rowsPerPage: window.innerHeight > 720 ? 20 : 15,
        filter: true,
        filterType: "dropdown",
        responsive: "simple",
        tableBodyHeight: (window.innerHeight - 220).toString() + 'px',
        tableBodyMaxHeight: (window.innerHeight - 220).toString() + 'px',
        fixedHeader: true,
        fixedSelectColumn: false,
        selectableRows: "none",
        download: false,
        print: false,
        onViewColumnsChange: async (changedColumn, action) => {

            var columns = Object.assign([], columnsList)
            if (action === "add") {
                columns.push(changedColumn);
            }
            if (action === "remove") {

                // var index = columns.indexOf(changedColumn);
                // if (index !== -1) {
                // columns.splice(index, 1);
                // setColumnsList(columns);
                // }
                var columns = columnsList.filter(item => item !== changedColumn);
            }

            const updatedData = {
                user_id: userData ? userData._id : null,
                table_name: "meetings",
                columns_list: columns,
     
            }
            
            meetingupdatedata(updatedData)
            updateColumnsListData(columns)
           
            
        },

        onDownload: (buildHead, buildBody, columns, data) => {
            let rowDatas = data.map(item => {
                const temp = [
                    (item.data[0] !== null && "user_id" in item.data[0] && item.data[0].user_id !== null) ? item.data[0]['user_id'].first_name : "NA",
                    item.data[1] ? item.data[1].contact_name : "NA",
                    item.data[2] ? item.data[2].company_name : "NA",
                    item.data[3] ? item.data[3].name : "NA",
                    item.data[4] ? item.data[4].remarks : "NA",
                    item.data[5] ? item.data[5].replace("T", " ").replace("Z", " ") : "NA",
                    item.data[6] ? item.data[6].replace("T", " ").replace("Z", " ") : "NA"
                ]
                return { data: temp }
            })
            let val = `${buildHead(columns)}${buildBody(rowDatas)}`
            return val
        },

        // serverSide: true,
        // count: totalRows,
        // onTableChange: (action, tableState) => {
        //     switch (action) {
        //       case "changePage":
        //         fetchMeetingsPaginationData({
        //           page: tableState.page + 1, // MUIDataTable starts indexing pages from 0
        //           perPage: tableState.rowsPerPage,
        //           user_id: user._id,
        //         });
        //         break;
        //       case "changeRowsPerPage":
        //         fetchMeetingsPaginationData({
        //           page: 1,
        //           perPage: tableState.rowsPerPage,
        //           user_id: user._id,
        //         });
        //         break;
        //       default:
        //         break;
        //     }
        // }
    };

 

    const columns = [
        {
            name: "account_manager",
            label: "Account Manager",
            options: {
                // display: columnsList?.includes('account_manager') ? true : false,
                display: true,
                filter: true,
                setCellHeaderProps: headerProps,
            }
        },
        {
            name: "contact_person",
            label: "Contact Person",
            options: {
                display: true,
                // display: columnsList?.includes('contact_person') ? true : false,
                filter: (user?.user_role_id?.role_name !== "user") ? true : false,
                setCellHeaderProps: headerProps,
            }
        },
        {
            name: "internal_contact_person",
            label: "Internal Contact Person",
            options: {
                display:  columnsList?.includes('internal_contact_person') ? true : false,
                filter: (user?.user_role_id?.role_name !== "user") ? true : false,
                setCellHeaderProps: headerProps,
              }
        },
        {
            name: "company_name",
            label: "Organization Name",
            options: {
                display: columnsList?.includes('company_name') ? true : false,
                filter: (user?.user_role_id?.role_name !== "user") ? true : false,
                setCellHeaderProps: headerProps,
            }
        },
        {
            name: "lead_stage",
            label: "Lead Stage",
            options: {
                display: columnsList?.includes('lead_stage') ? true : false,
                filter: (user?.user_role_id?.role_name !== "user") ? true : false,
                setCellHeaderProps: headerProps,
            }
        },
        {
            name: "meeting_type",
            label: "Meeting Type",
            options: {
                display:columnsList?.includes('meeting_type') ? true : false,
                filter: (user?.user_role_id?.role_name !== "user") ? true : false,
                customBodyRender: (value, tableMeta, updateValue) => (
                  (value ? value : "NA")
                ),
                setCellHeaderProps: headerProps,
              }
        },
        {
            name: "meeting_discussion",
            label: "Meeting Discussion",
            options: {
                setCellProps: (value, tableMeta) => (
                    {
                      style: {
                        whiteSpace: "nowrap",
                        textColor: '#FFFFFF',
                        fontWeight: 'bold',
                        width: '500px',
                        maxWidth: '500px',
                        overflow: 'auto',
                        textOverflow: 'ellipsis',
                        color: '#243d6a',
                      }
                    }),
                display: columnsList?.includes('meeting_discussion') ? true : false,
                filter: (user?.user_role_id?.role_name !== "user") ? true : false,
                setCellHeaderProps: headerProps,
            }
        },
        {
            name: "updated_at",
            label: "Meeting Date ",
            options: {
                display: columnsList?.includes('updated_at') ? true : false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
                ),
                filter: true,
                setCellHeaderProps: headerProps,
            }
        },
    ]

    window.addEventListener('keydown', (e) => {

        // e.preventDefault();
        console.log("e.key", e.key)
        if (e.ctrlKey && (e.key === 's' || e.key === 'p')) {
            e.preventDefault();
        }
        // Prevent default actions for Print Screen (PrtScn)
        if (e.key === 'PrintScreen') {
              console.log("print screen")
              e.preventDefault();
        }
      
        // Prevent default actions for the Windows key
        if (e.key === 'Meta') { // Meta key represents the Windows key
          console.log("meta")
              e.preventDefault();
        }
    
      });
    
    return (
        <>
        <div
            onContextMenu={(e) => {
                e.preventDefault(); // Prevent right-click menu
            }}
            style={{ position: 'relative' }} // Optional: Ensure proper layout
        >
            <React.Fragment>
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={"Meetings List"}
                        data={allTableData}
                        columns={columns}
                        options={options}
                        className="no-select" 

                    />
                </MuiThemeProvider>
            </React.Fragment>
            </div>
        </>
    ) 

});
const mapStateToProps = (state) => ({ 
    user : state.auth.user,
    userVariable: state.auth,
    meetinglistdata:state.meetinglist.meetinglistdata,
    Meeting_columns : state.meetinglist.Meeting_columns,
    isFetching : state.meetinglist.isFetching,
    columnsList:state.meetinglist.columnsList,
    meetingsListNew : state.meetinglist.meetingsListNew,
    totalRows : state.meetinglist.totalRows
});


const mapDispatchToProps = (dispatch) => ({
    ColumnsList:(data) => dispatch(getmeetingColumnListHandle(data)),
    meetingupdatedata:(data) => dispatch(updateColumnsList(data)),
    getColumnsList:(data) => dispatch(getColumnsList()),
    updateColumnsListData : (data) => dispatch(MeetingActions.updateColumnsList(data)),
    fetchMeetingsPaginationData : (data) => dispatch(fetchMeetingsList(data)),
});
export default connect(mapStateToProps,mapDispatchToProps) (MettingTable)

