import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import { getMuiTheme, headerProps } from './commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";
import { useSelector,useDispatch, connect } from 'react-redux';
import {getColumnsList} from "../../store/Testimonials"

const TestimonialTable = React.memo (({meetingcolumn,gettestimoniallist,isFetching,getColumnsListdata,columnsList}) => {
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  useEffect(() => {
    getColumnsListdata();
   
  }, []);

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        display: false,
        sort: false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "techie_name",
      label:"Techie Name",
      options: {
        filter: true,
        display: columnsList?.includes('techie_name') ? true : false,
        sort: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "base_url",
      label: "base url",
      options: {
        filter: true,
        display: columnsList?.includes('base_url') ? true : false,
        sort: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "account",
      label: "Account Name",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          (value ? value.company_name : "")
        ),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textColor: '#FFFFFF',
            color: '#243d6a',
            fontWeight: 'bold',
            maxWidth: '500px',
            overflow: 'auto',
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            background: "#e6e6e6",
            fontWeight: 'bold',
            maxWidth: '500px',
            overflow: 'auto',
          }
        })
      }
    },

    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('description') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: 'attachments',
      label: 'Attachments',
      width: 200,
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('attachments') ? true : false,
        setCellHeaderProps: headerProps,
        customBodyRender: (value, tableMeta, updateValue) => {

          var base_url = tableMeta.rowData[1];

          if (value.length == 0) {
            return (
              <p>No Attachments</p>
            )
          } else {
            return (
              <div className='row' style={{ display: "flex" }}>
                {value.map((newvalue) => {
                  return (
                    <div className="col-md-4 d-flex flex-row">
                      <a href={`${base_url}/${newvalue}`} download>
                        Download File
                      </a>
                    </div>
                  )
                })}

              </div>
            );
          }

        }
      }
    },
  ];

  const LoaderText = () => {
    return   isFetching ? <div className="parent-div">
    <div className="loader"></div>
  </div>:"No Data Found.." 
 }

  const options = {
    textLabels: {
      body: {
         noMatch: LoaderText()
      }
    },
    rowsPerPage: window.innerHeight > 720 ? 20 : 15,
    filter: false,
    filterType: false,
    responsive: "standard",
    tableBodyHeight: (window.innerHeight - 210).toString() + 'px',
    tableBodyMaxHeight: (window.innerHeight - 210).toString() + 'px',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: "none",
    onViewColumnsChange: async (changedColumn, action) => {

      if (action === "add") {
        columnsList.push(changedColumn);
      }
      if (action === "remove") {

        var index = columnsList.indexOf(changedColumn);
        if (index !== -1) {
            columnsList.splice(index, 1);
            columnsList(columnsList);
        }
    }

      const updatedData = {
        user_id: userData._id,
        table_name: "testimonials",
        columns_list: columnsList,
      }
      meetingcolumn(updatedData)

    
    },
    download: false,
    print: false,
    viewColumns: false,
  };

  window.addEventListener('keydown', (e) => {

    // e.preventDefault();
    console.log("e.key", e.key)
    if (e.ctrlKey && (e.key === 's' || e.key === 'p')) {
        e.preventDefault();
    }
    // Prevent default actions for Print Screen (PrtScn)
    if (e.key === 'PrintScreen') {
          console.log("print screen")
          e.preventDefault();
    }
  
    // Prevent default actions for the Windows key
    if (e.key === 'Meta') { // Meta key represents the Windows key
      console.log("meta")
          e.preventDefault();
    }

  });

  return (
    <>
    <div
            onContextMenu={(e) => {
                e.preventDefault(); // Prevent right-click menu
            }}
            style={{ position: 'relative' }} // Optional: Ensure proper layout
        >
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={"Testimonial List"}
          data={gettestimoniallist}
          columns={columns}
          options={options}
          className="no-select" 

        />
      </MuiThemeProvider>

</div>
    
    </>
  )


});
const mapStateToProps = (state) => ({
    user : state.auth.user,
    gettestimoniallist:state.Testimoniallist.gettestimoniallist,
    isFetching : state.Testimoniallist.isFetching,
    columnsList:state.Testimoniallist.columnsList
});


const mapDispatchToProps = (dispatch) => ({
  getColumnsListdata:(data) => dispatch(getColumnsList())
});
export default connect(mapStateToProps,mapDispatchToProps) (TestimonialTable)

