import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import { getMuiTheme, headerProps } from './commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";   
import UpdateWebinarModal from '../pageModals/UpdateWebinarModal';
import moment from 'moment-timezone';
import axios from 'axios';
import {ContactColumnUpdateddata} from "../../store/Common"
import { connect, useDispatch, useSelector } from 'react-redux';
import{WebinarActions,updateColumns,getColumnsList} from "../../store/Webinar"
const WebinarTable = React.memo (({getwebinarlistdata,isFetching,webinarUpdateColumnsdata,updateColumnsList,getColumnsList,columnsList}) => {
  const webinardata = getwebinarlistdata?.data
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const dispatch = useDispatch();
  const permissions = user.permissions;
  const [open, setOpen] = React.useState(false);
  // const [columnsList, setColumnsList] = useState([]);
  const [showUpdateTimesheetModal, setShowUpdateTimesheetModal] = useState(false);
  const [rowId, setRowId] = useState("");
  const [rowMetaData, setRowMetaData] = useState("");


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getColumnsList();
  }, []);
  const handleRowClick = (rowData, rowMeta) => {
    
    setShowUpdateTimesheetModal(true);   
    setRowMetaData(rowData);
    setRowId(webinardata[rowMeta.dataIndex]._id) 
  };


  const columns = [
    {
      name: "_id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
        setCellHeaderProps: headerProps,
      }
    },
    {
        name: "account_manager",
        label: "Account Manager",
        options: {
          display: columnsList?.includes('account_manager') ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => (
            value ? value : ""
          ),
          filter: true,
          setCellHeaderProps: headerProps,
        }
      },
    {
      name: "title",
      label: "Title",
      options: {
        display: true,
        // display: columnsList?.includes('title') ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => (
            value ? value : ""
          ),
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "type",
      label: "Event Type",
      options: {
        display: true,
        // display: columnsList?.includes('type') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value : ""
        ),
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
     {
      name: "speakers",
      label: "Speakers",
      options: {
        display: columnsList?.includes('speakers') ? true : false,
        setCellHeaderProps: headerProps,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value  : "user not found"
        ),
        filter: true,
        sort: true,
       
      }
    },
    {
      name: "persons_involved",
      label: "Persons Involved",
      options: {
        display: columnsList?.includes('persons_involved') ? true : false,
        setCellHeaderProps: headerProps,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value  : "user not found"
        ),
        filter: true,
        sort: true,
       
      }
    },

    {
        name: "start_date",
        label: "Webinar Date",
        options: {
          display: columnsList?.includes('start_date') ? true : false,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
            ),
            setCellHeaderProps: headerProps,
          }
      },
    {
      name: "start_date",
      label: "Start Time",
      options: {
        display: columnsList?.includes('start_date') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
            value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('LT') : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "end_date",
      label: "End Time",
      options: {
        display: columnsList?.includes('end_date') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
            value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('LT') : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "description",
      label: "Description",
      
      options: {
        setCellProps: (value, tableMeta) => (
            {
              style: {
                whiteSpace: "nowrap",
                textColor: '#FFFFFF',
                fontWeight: 'bold',
                width: '310px',
                maxWidth: '310px',
                overflow: 'auto',
                color: '#243d6a',
              }
            }),
            display: columnsList?.includes('description') ? true : false,
            filter: true,
            setCellHeaderProps: headerProps,
          }
    },
    {
        name: "agenda",
        label: "Agenda",
        options: {
          setCellProps: (value, tableMeta) => (
              {
                style: {
                  whiteSpace: "nowrap",
                  textColor: '#FFFFFF',
                  fontWeight: 'bold',
                  width: '310px',
                  maxWidth: '310px',
                  overflow: 'auto',
                  color: '#243d6a',
                }
              }),
              display: columnsList?.includes('agenda') ? true : false,
              filter: true,
              setCellHeaderProps: headerProps,
            }
      },
      {
        name: "speakers_list",
        label: "Speakers List",
        options: {
          filter: true,
          sort: true,
          display: columnsList?.includes('speakers_list') ? true : false,
          setCellHeaderProps: headerProps,
        }
      },
      {
        name: "persons_involved_list",
        label: "persons List",
        options: {
          filter: true,
          sort: true,
          display: columnsList?.includes('persons_involved_list') ? true : false,
          setCellHeaderProps: headerProps,
        }
      },
  ];

  const LoaderText = () => {
    return   isFetching ? <div className="parent-div">
    <div className="loader"></div>
  </div>:"No Data Found.." 
 }
  const options = {
    textLabels: {
      body: {
        noMatch: LoaderText()
      }
    },
    rowsPerPage: window.innerHeight > 720 ? 20 : 15,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: (window.innerHeight - 130).toString() + 'px',
    tableBodyMaxHeight: (window.innerHeight - 130).toString() + 'px',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: "none",
    print: false,
    setRowProps: (row, dataIndex, rowIndex) => {
        if (row[22]) {
          if (row[22]['postponed_date']) {
            var difference = moment(moment(row[22]['postponed_date']), "DD/MM/YYYY HH:mm:ss").diff(moment(), "DD/MM/YYYY HH:mm:ss");
          } else {
            var difference = moment(moment(row[22]['action_date']), "DD/MM/YYYY HH:mm:ss").diff(moment(), "DD/MM/YYYY HH:mm:ss");
          }
          return {
          };
        } else {
          return {
          }
        }
      },
  
      onViewColumnsChange: async (changedColumn, action) => {
        var columns = Object.assign([], columnsList)
        if (action === "add") {
          columns.push(changedColumn);
        }
  
        if (action === "remove") {
           columns = columns.filter(item => item !== changedColumn);
        }

        const updatedData = {
          user_id: userData._id,
          table_name: "webinar",
          columns_list: columns,
        }
        // Emailcampaignupdatedata(updatedData)
        webinarUpdateColumnsdata(updatedData)
        updateColumnsList(columns)

     
    },
    download: false,
    onRowClick: handleRowClick,
  };

  window.addEventListener('keydown', (e) => {

    // e.preventDefault();
    console.log("e.key", e.key)
    if (e.ctrlKey && (e.key === 's' || e.key === 'p')) {
        e.preventDefault();
    }
    // Prevent default actions for Print Screen (PrtScn)
    if (e.key === 'PrintScreen') {
          console.log("print screen")
          e.preventDefault();
    }
  
    // Prevent default actions for the Windows key
    if (e.key === 'Meta') { // Meta key represents the Windows key
      console.log("meta")
          e.preventDefault();
    }

  });


  return (
    
   <>
   <div
            onContextMenu={(e) => {
                e.preventDefault(); // Prevent right-click menu
            }}
            style={{ position: 'relative' }} // Optional: Ensure proper layout
        >
            <React.Fragment>
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={"Event list"}
                        data={webinardata}
                        columns={columns}
                        options={options}
                        className="no-select" 

                    />
                </MuiThemeProvider>
            </React.Fragment>
                 {showUpdateTimesheetModal ? <UpdateWebinarModal  onHide={() => setShowUpdateTimesheetModal(false)} taskId={rowId} metaData={rowMetaData} /> : ""}
            </div>
    </>
  )

});

const mapStateToProps = (state) => ({
  user : state.auth.user,
  getwebinarlistdata:state.Webinarslist.webinarlists,
isFetching : state.Webinarslist.isFetching,
columnsList:state.Webinarslist.columnsList
});

const mapDispatchToProps = (dispatch) => ({
  webinarUpdateColumnsdata:(data) => dispatch(updateColumns(data)),
  updateColumnsList:(data) => dispatch(WebinarActions.updateColumns(data)),
  getColumnsList:(data) => dispatch(getColumnsList(data)),
});

export default connect(mapStateToProps,mapDispatchToProps) (WebinarTable)

