import React, { useState,useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import Snackbar from '@material-ui/core/Snackbar';
import { getMuiTheme, headerProps } from './commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment-timezone';
import { Button } from 'react-bootstrap';
import BattleCardModal from '../pageModals/BattleCardModal';
import { connect, useDispatch, useSelector } from 'react-redux';
import {BattlecardActions} from "../../store/Battlecard"

const BattleCardsTable = React.memo(({getbattlecards,battlecardslist,showbattleupdateModal,isFetching}) => {
  const battlecarddata = battlecardslist?.list
  const {user} = useSelector(state => state.auth)
  const dispatch = useDispatch();
  const permissions = user.permissions;
  const [columnsList, setColumnsList] = useState([]);

  const[payslipid,setPayslipId] = useState()
  
    const [open, setOpen] = useState(false);
 
  const [showBattleCardModal, setShowBattleCardModal] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };


  useEffect(() => {
    handleClose();
  }, [battlecarddata]);

  const columns = [
    {
      name: "user_id",
      label: "Added by",
      options: {
        filter: true,
        sort: false,
        display:true,
       setCellHeaderProps: headerProps,
       customBodyRender: (value, tableMeta, updateValue) => (
         value ? (value.first_name + ' '+ value.last_name) : "user not found"
       ),
     
      }
     },

    {
      name: "title",
      label: "Title",
      options: {
        filter: true,
        sort: false,
        display:true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "battlecard_pdf",
      label: "View BattleCard",
      options: {
        filter: true,
        sort: false,
        display:true,
        setCellHeaderProps: headerProps,


        customBodyRender : (value,tableMeta,updateValue) => {
          
          return (
            <>
            <a target = "_blank" href = {value} download>  
          <b> View File </b>  
          </a>  
            </>
          )
        }
      }
    },
    {
     name: "created_at",
     label: "Added on",
     options: {
      filter: true,
      sort: false,
      display:true,
      setCellHeaderProps:headerProps,
      customBodyRender: (value, tableMeta, updateValue) => (
        value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
      ),
     }
    },
   ];

   const LoaderText = () => {
    return   isFetching ? <div className="parent-div">
    <div className="loader"></div>
  </div>:"No Data Found.." 
 }

   const options = {
    
    textLabels: {
      body: {
        noMatch: LoaderText()
      }
    },
    rowsPerPage: window.innerHeight > 720 ? 20 : 15,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: (window.innerHeight - 280).toString() + 'px',
    tableBodyMaxHeight: (window.innerHeight - 280).toString() + 'px',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: "none",
    print: false,
    setRowProps: (row, dataIndex, rowIndex) => {
        if (row[22]) {
          if (row[22]['postponed_date']) {
            var difference = moment(moment(row[22]['postponed_date']), "DD/MM/YYYY HH:mm:ss").diff(moment(), "DD/MM/YYYY HH:mm:ss");
          } else {
            var difference = moment(moment(row[22]['action_date']), "DD/MM/YYYY HH:mm:ss").diff(moment(), "DD/MM/YYYY HH:mm:ss");
          }
          return {
          };
        } else {
          return {
          }
        }
      },
    onViewColumnsChange: async (changedColumn, action) => {

      if (action === "add") {
        columnsList.push(changedColumn);
      }

      if (action === "remove") {
        var index = columnsList.indexOf(changedColumn);
        if (index !== -1) {
          columnsList.splice(index, 1);
          setColumnsList(columnsList);
        }
      }

     
    },
  };

  window.addEventListener('keydown', (e) => {

    // e.preventDefault();
    console.log("e.key", e.key)
    if (e.ctrlKey && (e.key === 's' || e.key === 'p')) {
        e.preventDefault();
    }
    // Prevent default actions for Print Screen (PrtScn)
    if (e.key === 'PrintScreen') {
          console.log("print screen")
          e.preventDefault();
    }
  
    // Prevent default actions for the Windows key
    if (e.key === 'Meta') { // Meta key represents the Windows key
      console.log("meta")
          e.preventDefault();
    }

  });

  return (
    <>
    <div
            onContextMenu={(e) => {
                e.preventDefault(); // Prevent right-click menu
            }}
            style={{ position: 'relative' }} // Optional: Ensure proper layout
        >
       <React.Fragment>
             <MuiThemeProvider theme={getMuiTheme()}>
                 <MUIDataTable
              
              title={<div><b>Battle Cards List </b> &nbsp;&nbsp; <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => dispatch(BattlecardActions.battlecard_openModal())} >Create Battle Card</Button>
                {showbattleupdateModal ? <BattleCardModal />: ""}</div>}
                options={options}
                data={battlecarddata}
                columns={columns}
                className="no-select" 
                />
                </MuiThemeProvider>
              </React.Fragment>  
              </div>
             
    </>
  );
});

const mapStateToProps = (state) => ({
  user : state.auth.user,
battlecardslist:state.Battlecardlist.battlecardslist,
showbattleupdateModal:state.Battlecardlist.showBattleCardModal,
isFetching : state.Battlecardlist.isFetching,

});
const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps,mapDispatchToProps)(BattleCardsTable)