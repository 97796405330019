import axios from "axios";
import React, { useEffect,useState } from "react";
import { getMuiTheme, headerProps } from './commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { FaUserEdit, FaUserAltSlash } from "react-icons/fa";
import UpdateUserModal from '../pageModals/UpdateUserModal';

const Users = () => {
    
    const [allUsersList, setAllUsersList] = useState([]);
    const [allRoles, setAllRoles] = useState([])
    const [userModal, setUserModal] = useState(false)
    const [snackBarMessage, setSnackBarMessage] = useState(false);
    const [open, setOpen] = React.useState(false);

    const getUsersList = async() => {
        var res = await axios.get('/user/list-all-users');
        setAllUsersList(res.data.usersList)
    }

    const handleClick = () => {
        setOpen(true);
      };
    
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };

    const getUserRoles = async () => {
        var res = await axios.get('/user/user-roles');
        setAllRoles(res.data.userRoles)
    }

    const updateUser = async (value) => {
        setUserModal(value)
    }

    const blockUser = async (value, status) => {
        
        try{
            var input = {user_id : value, status:status}
            var res = await axios.post('/user/profile/block-user', input);

            setTimeout(() => {
                setOpen(true);
                setSnackBarMessage("User Updated Successfully ...!");
                getUsersList()
            }, 1000);

            setSnackBarMessage("User Updated Successfully ...!");
            handleClick();

        }catch(err){
            alert(err)
        }
    }

    useEffect(() => {
        getUsersList()
        getUserRoles()
    }, [])
    
    const columns = [
        {
          name: "_id",
          label: "Action",
          options: {
            filter: false,
            sort: false, 
            display: true,
            setCellHeaderProps: headerProps,
            customBodyRender: (value, tableMeta, updateValue) => {

                
                return (
                    <>
                        <button title="Update user" onClick={() => updateUser(value)} className="btn btn-sm btn-primary"><FaUserEdit /></button>&nbsp;
                        
                        {(tableMeta.rowData[9] === "false")  && <button title="Block User" onClick={() => blockUser(value, "block")} className="btn btn-sm btn-danger"><FaUserAltSlash /></button> }

                        {(tableMeta.rowData[9] === "true")  && <button title="Activate User" onClick={() => blockUser(value, "activate")} className="btn btn-sm btn-success"><FaUserAltSlash /></button> }
                    </>
                )
            },
          }
        },
        {
            name:"profile_pic",
            label:"Profile",
            options:{
                filter:false,
                sort:false,
                display:true,
                setCellHeaderProps: headerProps,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <img src={value}  style={{height:'100px', width:'100px'}} />
                  ),
            }
        },
        {
         name: "first_name",
         label: "First Name",
         options: {
          filter: true,
          sort: true,
          display: true,
          setCellHeaderProps: headerProps,
         }
        },
        {
         name: "email",
         label: "Email",
         options: {
          filter: true,
          sort: false,
          display:true,
          setCellHeaderProps:headerProps,
         }
        },
        {
            name: "user_role_id",
            label: "Team Role",
            options: {
             filter: true,
             sort: false,
             display:true,
             setCellHeaderProps:headerProps,    
             customBodyRender: (value, tableMeta, updateValue) => (
                value? value?.role_name : "No Role assigned"
              ),
            }
        },
        {
            name: "manager_id",
            label: "Manager",
            options: {
             filter: true,
             sort: false,
             display:true,
             setCellHeaderProps:headerProps,    
             customBodyRender: (value, tableMeta, updateValue) => (
                value? value.first_name + " "+value.last_name : "No Role assigned"
              ),
            }
        },
        {
            name: "job_title",
            label: "Designation",
            options: {
             filter: true,
             sort: false,
             display:true,
             setCellHeaderProps:headerProps,
             
            }
           },
        {
         name: "company",
         label: "Organization",
         options: {
          filter: true,
          sort: false,
          display:true,
          setCellHeaderProps:headerProps,    
         }
        },
         {
         name: "status",
         label: "Status",
         options: {
          filter: true,
          sort: false,
          display:true,
          setCellHeaderProps:headerProps,    
         }
        },
        {
            name: "blocked",
            label: "Blocked",
            options: {
             filter: true,
             sort: false,
             display:true,
             setCellHeaderProps:headerProps,    
            }
        }

       ];

       const options = {
        textLabels: {
          body: {
            noMatch: allUsersList.length < 1 ? "No Records Found" : "No Data Found."
          }
        },
        filter: true,
        filterType: false,
        responsive: "simple",
        fixedHeader: true,
        fixedSelectColumn: false,
        selectableRows: "none",
        download: false,
        print: false,
      };

      window.addEventListener('keydown', (e) => {

        // e.preventDefault();
        console.log("e.key", e.key)
        if (e.ctrlKey && (e.key === 's' || e.key === 'p')) {
            e.preventDefault();
        }
        // Prevent default actions for Print Screen (PrtScn)
        if (e.key === 'PrintScreen') {
              console.log("print screen")
              e.preventDefault();
        }
      
        // Prevent default actions for the Windows key
        if (e.key === 'Meta') { // Meta key represents the Windows key
          console.log("meta")
              e.preventDefault();
        }
    
      });

    return (
        <>
        <div
            onContextMenu={(e) => {
                e.preventDefault(); // Prevent right-click menu
            }}
            style={{ position: 'relative' }} // Optional: Ensure proper layout
        >
            <React.Fragment>
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={"All Users"}
                        data={allUsersList}
                        columns={columns}
                        options={options}
                        className="no-select" 

                    />
                </MuiThemeProvider>
            </React.Fragment>
            <Snackbar
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                message={snackBarMessage}
                action={
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                    <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
                }
            />
            { userModal && <UpdateUserModal refreshRawDatas = {()=> getUsersList()} user_id={userModal} show={userModal ? true : false} userRoles={allRoles} usersList={allUsersList} onHide={() => setUserModal(null)} /> }
</div>
        </>
        
    )

    
}

export default Users;