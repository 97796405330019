import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import { getMuiTheme, headerProps } from './commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";
import RawdataaddingwebsiteModal from '../pageModals/RawdataaddingwebsiteModal';
import moment from 'moment-timezone';
import RawdataAddNewLeadModal from '../pageModals/RawdataAddNewLeadModal';
import RawdataUpdateModal from '../pageModals/RawdataUpdateModal';
import RawdataExistingcustomerModal from '../pageModals/RawdataExistingcustomerModal';
import AssignRawdatauserModal from '../pageModals/AssignRawdatauserModal';
import { Button } from 'react-bootstrap';
import { connect, useSelector,useDispatch } from 'react-redux';
import {RawdataActions, RawDatabaseAddActivityModal, fetchRawdataPagination,updateColumns, getColumnsList,} from "../../store/Rawdatabase"
import CreateRawDatabaseDCRModal from "../pageModals/CreateRawDatabaseDCRModal";
import InactiveRawDataModal from '../pageModals/InactiveRawDataModal';

  const  RawDataTable = React.memo((props) => {
    
    const { showAddRawDCR,
      updateShowAddRawDCR, 
      rawdatalist,
      getColumnsListdata,
      isFetching,
      RawDataExistingCustomerModal,
      RawDatadetailsModal,
      UpdateRawdataModal,
      AddingWebsiteModal,
      AssignRawdataModels,
      Updaterawdata_openmodal,
      setRowMetaData,setRowId,
      setAddwebsiteRowid,
      showAddwebistemodal,
      showAddnewlead,
      RawDatacustomeropen_modal,
      leadid_data,
      invalidOpenModal,
      showInactiveRawDataModal,
      fetchRawdataPaginationData, 
      totalRows, 
      rawDatabasePagination,
      updateColumnsListData,
      rawdatabaseupdatedata,
      updateColumns,
      columnsList} = props

    const rawData = rawdatalist
    const dispatch = useDispatch();
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const permissions = user.permissions;
  // const [columnsList, setColumnsList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [rawDataTypeActive, setRawDataTypeActive] = useState("active");
  
  // const [rowId, setRowId] = useState("");
  
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [searchForm, setSearchForm] = useState({search:null, column:null})

  // const [rowMetaData, setRowMetaData] = useState("");
  // const [showRawDataExistingCustomerModal, setShowRawDataExistingCustomerModal] = useState(false);
  // const [showRawDatadetailsModal, setShowRawDatadetailsModal] = useState(false);
  // const [showUpdateRawdataModal, setShowUpdateRawdataModal] = useState(false);
  // const [showAddingWebsiteModal,setShowAddingWebsiteModal] = useState(false);
  // const[showAssignRawdataModel,setShowAssignRawdataModel] = useState(false);

  const handleRowClick = (rowData, rowMeta) => {
    // setShowUpdateRawdataModal(true);   
    // dispatch(RawdataActions.showUpdateRawdataModal_openmodal())
    // setRowMetaData(rowData);
    // setRowId(rawdatalist?.list[rowMeta.dataIndex]._id) 
    Updaterawdata_openmodal()
    // updaterawdatasetRowMetadata(rowData)
    // updaterawdatasetRowId(rawdatalist?.list[rowMeta.dataIndex]._id)
    setRowMetaData(rowData)
    setRowId(tableData[rowMeta.dataIndex]._id)
    
  };

  const addDCR = async (event, data, meta) => {
    event.stopPropagation();
    let newRowData = tableData[meta]

    updateShowAddRawDCR({_id: newRowData._id, company_name: newRowData.company_name})
  }

  const assignHandle = async (event, data, meta) => {
    event.stopPropagation();
    let newRowData = tableData[meta]
    if(newRowData.website===null){
      setRowId(newRowData._id)
      // setRowId(newRowData._id)
      // setAddwebsiteRowid(newRowData._id)
      showAddwebistemodal()
      // dispatch(RawdataActions.showAddingWebsiteModal_openmodal())
      // setShowAddingWebsiteModal(true)
    }else if(newRowData.is_existing_customer === "false"){
      setRowId(newRowData._id)
      showAddnewlead()
      // setRowId(newRowData._id)
      // dispatch(RawdataActions.showRawDatadetailsModal_openmodal())
      // setShowRawDatadetailsModal(true)
     
    
    }else if(newRowData.is_existing_customer === "true"){
      setRowId(newRowData._id)
      // dispatch(RawdataActions.showRawDataExistingCustomerModal_openmodal()
      // RawDataExistingCustomerModalSetRowId(newRowData._id)
      RawDatacustomeropen_modal()
        //  setShowRawDataExistingCustomerModal(true)
    }else{
      return;
    }    
   
  }
  const inValidHandle = (event, data, meta) => {
    event.stopPropagation();
    let newRowData = tableData[meta]._id
    setRowId(newRowData)
    
     invalidOpenModal();
  }

  const dummyhandle = async(event,data,meta) => {
    event.stopPropagation();
    dispatch(RawdataActions.showRawDataExistingCustomerModal_openmodal())
    // setShowRawDataExistingCustomerModal(true)

  }

 

  const columns = [
    {
      name: "_id",
      label: "Id",
      options: {
        filter: false,
        
        display: false,
        setCellHeaderProps: headerProps,
      }
      
    },
     {
        name: "assigned_to",
        label: "Assigned To",
         options: {
            display: true,
            filter: true,
            setCellHeaderProps: headerProps,
            customBodyRender: (value, tableMeta, updateValue) => (
              value ? (value.first_name + ' '+ value.last_name) : ""
            ),
          
          }
        },
        

         {
        name: "company_name",
        label: "Company Name",
        options: {
          sort: true,
          filter: true,
          display: true,
          setCellProps: () => ({
            style: {
              whiteSpace: "nowrap",
              position: "sticky",
              left: "0",
              background: "white",
              zIndex: 100,
              color: '#243d6a',
              fontWeight: 'bold',
              maxWidth: '500px',
              overflow: 'auto',
            }
          }),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: "nowrap",
              position: "sticky",
              left: 0,
              background: "#e6e6e6",
              zIndex: 101,
              maxWidth: '500px',
              overflow: 'auto',
            }
          })
        }
      },
      {
        name: "dailyCallRecord_id",
        label: "Call Objective",
    
         options: {
          setCellProps: (value, tableMeta) => (
            {
              style: {
                whiteSpace: "nowrap",
                textColor: '#FFFFFF',
                fontWeight: 'bold',
                width: '320px',
                maxWidth: '320px',
                overflow: 'auto',
                color: '#243d6a',
              }
            }),
            display:columnsList?.includes('dailyCallRecord_id') ? true : false,
            filter: true,
            setCellHeaderProps: headerProps,
            customBodyRender: (value, tableMeta, updateValue) => (
              value ? value?.call_objective : ""
            ),
          }
      },
      {
        name: "dailyCallRecord_id",
        label: "Call Type",
         options: {
          display:columnsList?.includes('dailyCallRecord_id') ? true : false,
            filter: true,
            setCellHeaderProps: headerProps,
            customBodyRender: (value, tableMeta, updateValue) => (
              value ? value?.call_type : ""
            ),
          }
      },
      {
        name: "dailyCallRecord_id",
        label: "Called Date&Time",
         options: {
          display:columnsList?.includes('dailyCallRecord_id') ? true : false,
            filter: true,
            setCellHeaderProps: headerProps,
     
            customBodyRender: (value, tableMeta, updateValue) => (
              value ? moment(value?.meeting_date_time?.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY LT') : ""
            ),
          }
      },
      {
        name: "dailyCallRecord_id",
        label: "Call Remarks",
         options: {
          setCellProps: (value, tableMeta) => (
            {
              style: {
                whiteSpace: "nowrap",
                textColor: '#FFFFFF',
                fontWeight: 'bold',
                width: '320px',
                maxWidth: '320px',
                overflow: 'auto',
                color: '#243d6a',
              }
            }),
            display:columnsList?.includes('dailyCallRecord_id') ? true : false,
            filter: true,
            
            setCellHeaderProps: headerProps,
            customBodyRender: (value, tableMeta, updateValue) => (
              value ? value?.remarks : ""
            ),
          }

          
      },
      {
        name: "account_type",
        label: "Account Type",
        options: {
          display:columnsList?.includes('account_type') ? true : false,
          filter: true,
          setCellHeaderProps: headerProps,
        }
      },
      {
        name: "industry",
        label: "Industry",
         options: {
          display:columnsList?.includes('industry') ? true : false,
            filter: true,
            setCellHeaderProps: headerProps,
          }
      },
      {
        name: "_id",
        label: "Add activity",
        options : {
          filter:true,
          display:columnsList?.includes('_id') ? true : false,
          setCellHeaderProps:headerProps,

          customBodyRender : (value,tableMeta,updateValue) => {
                 
                  return (
                    <>
                      <div style={{ display: "flex" }}>
                        <>
                            <a style={{ textAlign: 'center' }} className="btn-sm btn-group-sm btn btn-table-commone btn-success "onClick={(e) => { addDCR(e, value, tableMeta.rowIndex) }}>
                                <span style={{ cursor: 'pointer', textAlign: 'center', paddingTop:'5px', width: '58px', height: '30px' }}>DCR</span>
                            </a>
                        </>
                      </div>
                    </>
                  );
          }
      }
      },
      {
        name:"status",
        label:"Actions",
        options : {
            filter:true,
            sort:true,
            display:permissions.includes("viewRawDataCovertLead") ? true :false,
            setCellHeaderProps:headerProps,

            customBodyRender : (value,tableMeta,updateValue) => {
                   
                    return (
                      <>
                      {value === "active" && permissions.includes("viewRawDataCovertLead") ? 
                      <div style={{ display: "flex", gap:"10px"}}>
                      <>
                          <a style={{ textAlign: 'center' }} className="btn-sm btn-group-sm btn btn-table-commone btn-success " onClick={(e) => { assignHandle(e, value, tableMeta.rowIndex) }}>
                              <span style={{ cursor: 'pointer', textAlign: 'center', paddingTop:'5px', width: '58px', height: '30px' }}>Convert Lead</span>
                          </a>
                          <a style={{ textAlign: 'center' }} className="btn-sm btn-group-sm btn btn-table-commone btn-success " onClick={(e) => { inValidHandle(e, value, tableMeta.rowIndex) }}>
                              <span style={{ cursor: 'pointer', textAlign: 'center', paddingTop:'5px', width: '58px', height: '30px' }}>InValid</span>
                          </a>
                      </>
  
                    </div>
                   : ""} 
                      
                      </>
                
                  
                    );
                 
            }
        }

      },
      {
        name: "website",
        label: "Website",
         options: {
          display:columnsList?.includes('website') ? true : false,
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => (
              (value ? <a style={{ color: '#243d6a', }} href={value} target="_blank" ><u>{value}</u></a> : "")
            ),
            setCellHeaderProps: headerProps,
          }
      },
      {
        name: "address",
        label: "Address",

        options: {
          setCellProps: (value, tableMeta) => (
              {
                style: {
                  whiteSpace: "nowrap",
                  textColor: '#FFFFFF',
                  fontWeight: 'bold',
                  width: '320px',
                  maxWidth: '320px',
                  overflow: 'auto',
                  color: '#243d6a',
                }
              }),
              display:columnsList?.includes('address') ? true : false,
              filter: true,
              setCellHeaderProps: headerProps,
            }
      },
      {
        name: "city",
        label: "City",
         options: {
          display:columnsList?.includes('city') ? true : false,
            filter: true,
            setCellHeaderProps: headerProps,
          }
      },
      {
        name: "state",
        label: "State",
         options: {
          display:columnsList?.includes('state') ? true : false,
            filter: true,
            setCellHeaderProps: headerProps,
          }
      },
      {
        name: "postal_code",
        label: "Postal Code",
         options: {
          display:columnsList?.includes('postal_code') ? true : false,
            filter: true,
            setCellHeaderProps: headerProps,
          }
      },
      {
        name: "country",
        label: "Country",
         options: {
          display:columnsList?.includes('country') ? true : false,
            filter: true,
            setCellHeaderProps: headerProps,
          }
      },
    {
      name: "contact_person_name",
      label: "Contact Name",
      options: {
        display:columnsList?.includes('contact_person_name') ? true : false,
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
     {
      name: "mobile",
      label: "Mobile",
      options: {
        setCellHeaderProps: headerProps,
        filter: true,
        display:columnsList?.includes('mobile') ? true : false,
      }
    },
    {
      name: "phone",
      label: "Phone Number",
       options: {
        display:columnsList?.includes('phone') ? true : false,
          filter: true,
          setCellHeaderProps: headerProps,
        }
    },
   
    {
      name: "designation",
      label: "Designation",
       options: {
        display:columnsList?.includes('designation') ? true : false,
          filter: true,
          setCellHeaderProps: headerProps,
        }
    },
    {
      name: "department",
      label: "Department",
       options: {
        display:columnsList?.includes('department') ? true : false,
          filter: true,
          setCellHeaderProps: headerProps,
        }
    },
    {
      name: "job_level",
      label: "Job Level",
       options: {
        display:columnsList?.includes('job_level') ? true : false,
          filter: true,
          setCellHeaderProps: headerProps,
        }
        
    },
    {
      name: "meddic_role",
      label: "Meddic Role",
       options: {
        display:columnsList?.includes('meddic_role') ? true : false,
          filter: true,
          setCellHeaderProps: headerProps,
        }
    },
   
    {
      name: "email",
      label: "Email",
       options: {
        display:columnsList?.includes('email') ? true : false,
          filter: true,
          setCellHeaderProps: headerProps,
        }
    },
    {
      name: "no_of_employees",
      label: "No.of Employess",
       options: {
        display:columnsList?.includes('no_of_employees') ? true : false,
          filter: true,
          setCellHeaderProps: headerProps,
        }
    },

      {
        name: "is_existing_customer",
        label: "Existing Customer",
         options: {
           display:columnsList?.includes('is_existing_customer') ? true : false,
            filter: true,
            setCellHeaderProps: headerProps,
          }
      },
      {
        name: "source",
        label: "Source",
         options: {
          display:columnsList?.includes('source') ? true : false,
            filter: true,
            setCellHeaderProps: headerProps,
          }
      },
      {
        name: "status",
        label: "Status",
         options: {
          display:columnsList?.includes('status') ? true : false,
            filter: true,
            setCellHeaderProps: headerProps,
          }
      },     
     {
        name: "user_id",
        label: "Added By",
         options: {
          display:columnsList?.includes('user_id') ? true : false,
            filter: true,
            setCellHeaderProps: headerProps,
            customBodyRender: (value, tableMeta, updateValue) => (
              value ? (value.first_name + ' '+ value.last_name) : "user not found"
            ),
          
          }
      },
      {
        name: "remark",
        label: "Remarks",
         options: {
          display:columnsList?.includes('remark') ? true : false,
            filter: true,
            setCellHeaderProps: headerProps,
          }
      },
      
      {
        name: "created_at",
        label: "Added On",
         options: {
          display:columnsList?.includes('created_at') ? true : false,
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => (
              value ? moment(value.replace("T", " ").replace("Z", " ").replace("GMT" ,"")).tz("Asia/Kolkata").format('D MMM  YYYY'): ""
            ),
            setCellHeaderProps: headerProps,
          }
      },
      
    
      ];

      const LoaderText = () => {
        return   isFetching ? <div className="parent-div">
        <div className="loader"></div>
      </div>:"No Data Found.." 
     }
  
  const options = {
    textLabels: {
      body: {
        noMatch: isFetching ? "Loading " : null
      }
    },
    rowsPerPage: window.innerHeight > 720 ? 20 : 15,
    rowsPerPageOptions : [20, 50, 100, 500, 1000, 1500, 2000, 2500, 5000, 10000],
    filter: true,
    print: false,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: (window.innerHeight - 130).toString() + 'px',
    tableBodyMaxHeight: (window.innerHeight - 130).toString() + 'px',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows:permissions.includes("canAssignRawdata")? "multiple" : "none",
    onRowsSelect: (currentRowsSelected, allRowsSelected) => {
      setSelectedRows(allRowsSelected);
    },
    onChangePage: () => {
      setSelectedRows([]); // Clear selected rows when changing page
    },
    onChangeRowsPerPage: (numberOfRows) => {
      fetchRawdataPaginationData({
        page: 1,
        perPage: numberOfRows,
        searchText : searchForm.search, 
        searchColumn: searchForm.column || "company_name"
      });
      setRowsPerPage(numberOfRows); // Update rowsPerPage state
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      var lead_ids_data = selectedRows.data.map(item1 => {
        return displayData[item1.index]?.data[0];
      })
      leadid_data(lead_ids_data)
      return (
        <div className="assing_to_isales">
          {permissions.includes("canAssignRawdata") ?
          <>
            <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => dispatch(RawdataActions.AssignRawdataModel_openmodal())} >Assign To</Button>
            {AssignRawdataModels ? <AssignRawdatauserModal/>: ""}
          </>
          :false}
      </div>
      )
    },
    setRowProps: (row, dataIndex, rowIndex) => {
        if (row[22]) {
          if (row[22]['postponed_date']) {
            var difference = moment(moment(row[22]['postponed_date']), "DD/MM/YYYY HH:mm:ss").diff(moment(), "DD/MM/YYYY HH:mm:ss");
          } else {
            var difference = moment(moment(row[22]['action_date']), "DD/MM/YYYY HH:mm:ss").diff(moment(), "DD/MM/YYYY HH:mm:ss");
          }
          return {
          };
        } else {
          return {
          }
        }
      },
    onViewColumnsChange: async (changedColumn, action) => {
      // if (action === "add") {
      //   columnsList.push(changedColumn);
      // }
      // if (action === "remove") {
      //   var index = columnsList.indexOf(changedColumn);
      //   if (index !== -1) {
      //     columnsList.splice(index, 1);
      //     setColumnsList(columnsList);
      //   }
      // }

      var columns = Object.assign([], columnsList)
      if (action === "add") {
          columns.push(changedColumn);
      }
      if (action === "remove") {

          // var index = columns.indexOf(changedColumn);
          // if (index !== -1) {
          // columns.splice(index, 1);
          // setColumnsList(columns);
          // }
          var columns = columnsList.filter(item => item !== changedColumn);
      }


      const updatedData = {
        user_id: userData ? userData._id : null,
        table_name: "Rawdatabase",
        columns_list: columns,

    }
    
    rawdatabaseupdatedata(updatedData) 
    updateColumns(columns)


    },
    download: false,
    onRowClick: handleRowClick,
    onDownload: (buildHead, buildBody, columns, data) => {
      let rowDatas = data.map(item => {

        const temp = [

          item.data[0] ? item.data[0].first_name : "NA",
          item.data[1] ? item.data[1] : "NA",
          item.data[2] ? item.data[2].name : "NA",
          item.data[3] ? item.data[3].replace("T", " ").replace("Z", " ") : "NA",
          item.data[4] ? item.data[4].name : "NA",
          item.data[5] ? item.data[5] : "NA",
          item.data[6] ? item.data[6] : "NA",
          item.data[7] ? item.data[7] : "NA",
          item.data[8] ? item.data[8] : "NA",
          item.data[9] ? item.data[9] : "NA",
          item.data[10] ? item.data[10] : "NA",
          item.data[11] ? item.data[11] : "NA",
          item.data[12] ? item.data[12].replace("T", " ").replace("Z", " ") : "NA",
          item.data[13] ? item.data[13].name : "NA",
          item.data[14] ? item.data[14].name : "NA",
          item.data[15] ? item.data[15] : "NA",
          item.data[16] ? item.data[16] : "NA",
          item.data[17] ? item.data[17].replace("T", " ").replace("Z", " ") : "NA",
          item.data[18] ? item.data[18].replace("T", " ").replace("Z", " ") : "NA",
        ]
        return { data: temp }
      })

      let val = `${buildHead(columns)}${buildBody(rowDatas)}`
      return val


      
    },
    serverSide: true,
    count: totalRows,
    onTableChange: (action, tableState) => {
        switch (action) {
          case "changePage":
            fetchRawdataPaginationData({
              page: tableState.page + 1,
              perPage: rowsPerPage,
              searchText : searchForm.search, 
              searchColumn: searchForm.column || "company_name"
            });
            break;
          case "changeRowsPerPage":
            fetchRawdataPaginationData({
              page: 1,
              perPage: rowsPerPage,
              searchText : searchForm.search, 
              searchColumn: searchForm.column || "company_name"
            });
            break;
          default:
            break;
        }
    }
  };
  const filterRawDataType = async (type) => {
  setRawDataTypeActive(type)
    var data = [];
      data = rawDatabasePagination.filter(function (item) {
        if (item.status === type) {
          return item;
        }
      });
    // }

    setTableData(data);
  }

  useEffect(() => {
    // setTableData(hrisdata);
    filterRawDataType("active")
    getColumnsListdata();

  }, [rawDatabasePagination,rawdatalist]);

  const onRawdatabaseSearch = () => {
    
    fetchRawdataPaginationData({        
      page: 1,
      perPage: rowsPerPage,
      searchText : searchForm.search, 
      searchColumn: searchForm.column || "company_name"})
  }

  const onSearchFormChange = (e) => {
    if(e.target.name === "columns"){
      setSearchForm({ ...searchForm, column: e.target.value });
    }else{
      setSearchForm({ ...searchForm, search: e.target.value });
    }
  }

  window.addEventListener('keydown', (e) => {

    // e.preventDefault();
    console.log("e.key", e.key)
    if (e.ctrlKey && (e.key === 's' || e.key === 'p')) {
        e.preventDefault();
    }
    // Prevent default actions for Print Screen (PrtScn)
    if (e.key === 'PrintScreen') {
          console.log("print screen")
          e.preventDefault();
    }
  
    // Prevent default actions for the Windows key
    if (e.key === 'Meta') { // Meta key represents the Windows key
      console.log("meta")
          e.preventDefault();
    }

  });

  return (
    
   <>
   <div
            onContextMenu={(e) => {
                e.preventDefault(); // Prevent right-click menu
            }}
            style={{ position: 'relative' }} // Optional: Ensure proper layout
        >
            <React.Fragment>
                {/* <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={"Raw Database List"}
                        data={rawData}
                        columns={columns}
                        options={options}
                    />
                </MuiThemeProvider> */}
                  <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={<div style={{ display: "flex", flexDirection: "row", justifyContent:"flex-start", alignItems:"center" }} >
              <b>Raw Database List</b>&nbsp;&nbsp;&nbsp;
              <span onClick={() => filterRawDataType("active")} className={rawDataTypeActive === "active" ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> Active </span>&nbsp;&nbsp;
              <span onClick={() => filterRawDataType("inactive")} className={rawDataTypeActive === "inactive" ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> Inactive </span>&nbsp;&nbsp;
              <select className='form-control' name="columns" onChange={onSearchFormChange} style={{width:'200px'}} >
                <option disabled defaultValue>Select Column</option>
                <option value="company_name">Company Name</option>
                <option value="account_type">Account Type</option>
                <option value="industry">Industry</option>
                <option value="website">Website</option>
                <option value="remark">Remarks</option>
                <option value="source">Source</option>
                <option value="designation">Designation</option>
                {/* <option value="added_by">Added By</option> */}
              </select>
              <input type="text" style={{width:'250px'}} onChange={onSearchFormChange} placeholder='Search here'  className='form-control'/>
              <Button onClick={onRawdatabaseSearch}>Search</Button>
            
            </div>
           
            }
            data={tableData}
            columns={columns}
            options={{...options, rowsPerPage: rowsPerPage}}
            className="no-select" 

          />
        </MuiThemeProvider>
            </React.Fragment>
            
            {RawDataExistingCustomerModal ? <RawdataExistingcustomerModal /> : ""}
            {RawDatadetailsModal ? <RawdataAddNewLeadModal/>: ""}
            {UpdateRawdataModal ? <RawdataUpdateModal /> : ""}
            {AddingWebsiteModal ? <RawdataaddingwebsiteModal /> : ""}
            {showAddRawDCR ? <CreateRawDatabaseDCRModal />: ""}
            {showInactiveRawDataModal ? <InactiveRawDataModal /> :""}
           </div>
    </>    
  )

});


const mapStateToProps = (state) => ({
  user : state.auth.user,
  rawdatalist:state.Rawdatalist.rawdatalist,
  AssignRawdataModels :state.Rawdatalist.AssignRawdataModel,
  RawDataExistingCustomerModal:state.Rawdatalist.showRawDataExistingCustomerModal,
  RawDatadetailsModal:state.Rawdatalist.showRawDatadetailsModal,
  UpdateRawdataModal:state.Rawdatalist.showUpdateRawdataModal, 
  AddingWebsiteModal:state.Rawdatalist.showAddingWebsiteModal,
  isFetching : state.Rawdatalist.isFetching,
  showAddRawDCR  : state.Rawdatalist.showAddRawDCR,
  showInactiveRawDataModal: state.Rawdatalist.inactiveModal,
  totalRows: state.Rawdatalist.totalRows,
  rawDatabasePagination : state.Rawdatalist.rawDatabasePagination,
  columnsList:state.Rawdatalist.columnsList,

});

const mapDispatchToProps = (dispatch) => ({
  Updaterawdata_openmodal:(data) => dispatch(RawdataActions.showUpdateRawdataModal_openmodal(data)),
  setRowMetaData:(data) => dispatch(RawdataActions.setrowMetaData(data)),
  setRowId:(data) => dispatch(RawdataActions.setRowId(data)),
  setAddwebsiteRowid:(data) => dispatch(RawdataActions.setAddwebisteRowId(data)),
  showAddwebistemodal:(data) => dispatch(RawdataActions.showAddingWebsiteModal_openmodal(data)),
  RawDataExistingCustomerModalSetRowId:(data) => dispatch(RawdataActions.setRawDataExistingCustomerModalSetRowId(data)),
  RawDatacustomeropen_modal:(data) => dispatch(RawdataActions.showRawDataExistingCustomerModal_openmodal(data)),
  showAddnewlead:(data) => dispatch(RawdataActions.showRawDatadetailsModal_openmodal(data)),
  leadid_data:(data) => dispatch(RawdataActions.setleadid_data(data)),
  updateShowAddRawDCR: (data) => dispatch(RawDatabaseAddActivityModal(data)),
  invalidOpenModal:(data) => dispatch(RawdataActions.inactiveModalOpen(data)),
  fetchRawdataPaginationData: (data) => dispatch(fetchRawdataPagination(data)),
 rawdatabaseupdatedata:(data) => dispatch(updateColumns(data)),
  getColumnsListdata:(data) => dispatch(getColumnsList()),
  updateColumns:(data) => dispatch(RawdataActions.updateColumns(data)),
});
// RawdataActions.showAddRawDCR
export default connect(mapStateToProps,mapDispatchToProps)(RawDataTable)









