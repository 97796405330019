import React, { useState } from 'react';
import MUIDataTable from "mui-datatables";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment-timezone';
import { getMuiTheme, headerProps } from './commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";

const LeadMeetingsTable = (props) => {
  const [defaultTableText, setDefaultTableText] = useState('Loading...');
  const [snackBarMessage, setsnackBarMessage] = useState(false);
  const [open, setOpen] = useState(false);
const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

    const Taskcolumns = [
      {
          name: "account_manager",
          label: "Account Manager",
          options: {
              filter: true,
              setCellHeaderProps: headerProps,
          }
      },
      {
          name: "contact_person",
          label: "Contact Person",
          options: {
              setCellHeaderProps: headerProps,
          }
      },
      {
          name: "internal_contact_person",
          label: "Internal Contact Person",
          options: {
              display:  true,
              setCellHeaderProps: headerProps,
            }
      },
      {
          name: "company_name",
          label: "Organization Name",
          options: {
              display: true,
              setCellHeaderProps: headerProps,
          }
      },
      {
          name: "lead_stage",
          label: "Lead Stage",
          options: {
              setCellHeaderProps: headerProps,
          }
      },
      {
          name: "meeting_type",
          label: "Meeting Type",
          options: {
              display:  true,
              customBodyRender: (value, tableMeta, updateValue) => (
                (value ? value : "NA")
              ),
              setCellHeaderProps: headerProps,
            }
      },
      {
          name: "meeting_discussion",
          label: "Meeting Discussion",
          options: {
              setCellProps: (value, tableMeta) => (
                  {
                    style: {
                      whiteSpace: "nowrap",
                      textColor: '#FFFFFF',
                      fontWeight: 'bold',
                      width: '500px',
                      maxWidth: '500px',
                      overflow: 'auto',
                      color: '#243d6a',
                    }
                  }),
              setCellHeaderProps: headerProps,
          }
      },
      {
          name: "updated_at",
          label: "Meeting Date ",
          options: {
              customBodyRender: (value, tableMeta, updateValue) => (
                  value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
              ),
              filter: true,
              setCellHeaderProps: headerProps,
          }
      },
  ]


  const tasksOptions = {
    textLabels: {
      body: {
        noMatch: props.tasksList?.length < 1 ? "No Records Found" : defaultTableText
      }
    },
    filter: false,
    filterType: false,
    responsive: "simple",
    tableBodyHeight: "300px",
    tableBodyMaxHeight: "300px",
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: "none",
    download: false,
    print: false,
    onDownload: (buildHead, buildBody, columns, data) => {
      let rowDatas = data.map(item => {

        const temp = [
          item.data[0] ? item.data[0] : "NA",
          item.data[1] ? item.data[1].replace("T", " ").replace("Z", " ") : "NA",
          item.data[2] ? item.data[2].replace("T", " ").replace("Z", " ") : "NA",
          item.data[3] ? item.data[3] : "NA",
        ]
        return { data: temp }
      })

      let val = `${buildHead(columns)}${buildBody(rowDatas)}`
      return val

    }
  };

  window.addEventListener('keydown', (e) => {

    // e.preventDefault();
    console.log("e.key", e.key)
    if (e.ctrlKey && (e.key === 's' || e.key === 'p')) {
        e.preventDefault();
    }
    // Prevent default actions for Print Screen (PrtScn)
    if (e.key === 'PrintScreen') {
          console.log("print screen")
          e.preventDefault();
    }
  
    // Prevent default actions for the Windows key
    if (e.key === 'Meta') { // Meta key represents the Windows key
      console.log("meta")
          e.preventDefault();
    }

  });

  return (
    <>
    <div
            onContextMenu={(e) => {
                e.preventDefault(); // Prevent right-click menu
            }}
            style={{ position: 'relative' }} // Optional: Ensure proper layout
        >
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={"Meetings List"}
          data={props.tasksList}
          columns={Taskcolumns}
          options={tasksOptions}
          className="no-select" 
        />
      </MuiThemeProvider>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message={snackBarMessage}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      </div>
    </>

  )
}
export default LeadMeetingsTable;