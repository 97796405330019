import React, { useEffect, useState, useRef } from 'react';
import MUIDataTable from "mui-datatables";
import moment from 'moment-timezone';

import AddMetricModal from '../pageModals/AddIndustryMetric';
import { EditorState, ContentState} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { getMuiTheme, headerProps } from './commonComponents';
import '../../pages/home/home.css';
import { MuiThemeProvider } from "@material-ui/core/styles";
import { connect, useDispatch,useSelector } from 'react-redux';
import {getColumnsList, IndustrysActions, updateColumns} from "../../store/Industrymetric"

const IndustryMetricTable = React.memo (({showMetricModal,getindustrylists,isFetching,industrymetricupdatecolumns,updateColumns,columnsList,getColumnsListData,}) => {
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const dispatch = useDispatch();
  const [showResourceModal, setShowResourceModal] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);
 
  const html = `<p>  Description...<br /><br /></p>`;
  var contentBlock = htmlToDraft(html);
  var contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
  var editorStateTe = EditorState.createWithContent(contentState)
  const [editorState, setEditorState] = useState(editorStateTe)
  const mailBody = useRef();

  const onEditorStateChange = (data) => {
    
    setEditorState(data)
}

  const editResource = (index) => {
    setSelectedResource(getindustrylists?.[index])
    setShowResourceModal(true)
    
  }

  useEffect(() => {
    if(selectedResource){
      
      contentBlock = htmlToDraft(selectedResource?.description);
      contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      editorStateTe = EditorState.createWithContent(contentState);
      setEditorState(editorStateTe)
    }
  }, [selectedResource])


  const addNewResource =() => {
    setSelectedResource(null)
    setShowResourceModal(true)
  }

  const addNewMetric = () => {
    dispatch(IndustrysActions.MetricopenModal()); 
    // setShowMetricModal(true)
  }

  useEffect(() => {
    getColumnsListData()
  }, [getindustrylists]);

  const columns = [
    {
        name: "metric_name",
        label: "Metric Name",
        options: {
            filter: true,
            display: true,
            setCellHeaderProps: headerProps,
        }
    },
    {
        name: "industry",
        label: "Industry",
        options: {
          display: columnsList?.includes('industry') ? true : false,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? value.name : "Industry not found"
            ),
            setCellHeaderProps: headerProps,
        }
    },
    {
        name: "product",
        label: "Product",
        options: {
          display: columnsList?.includes('product') ? true : false,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? value.name : "Not Found"
            ),
            setCellHeaderProps: headerProps,
        }
    },
    {
        name: "file_path",
        label: "File",
        options: {
          display: columnsList?.includes('file_path') ? true : false,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? <a target="_blank" href={value} >View File</a> : "Not Found"
            ),
            setCellHeaderProps: headerProps,
        }
    },

    {
        name: "uploaded_by",
        label: "Uploaded By",
        options: {
          display: columnsList?.includes('uploaded_by') ? true : false,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? value.first_name + ' '+value.last_name : "Not Found"
            ),
            setCellHeaderProps: headerProps,
        }
    },
    {
        name: "updated_at",
        label: "Updated At ",
        options: {
          display: columnsList?.includes('updated_at') ? true : false,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
            ),
            filter: true,
            setCellHeaderProps: headerProps,
        }
    },
]

const LoaderText = () => {
  return   isFetching ? <div className="parent-div">
  <div className="loader"></div>
</div>:"No Data Found.." 
}


const options = {
    textLabels: {
      body: {
        noMatch: LoaderText()
      }
    },
    rowsPerPage: window.innerHeight > 720 ? 20 : 15,
    filter: true,
    download: false,
    print: false,
    filterType: "dropdown",
    responsive: "simple",
    tableBodyHeight: (window.innerHeight - 130).toString() + 'px',
    tableBodyMaxHeight: (window.innerHeight - 130).toString() + 'px',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows:"none",



    onViewColumnsChange: async (changedColumn, action) => {
      var columns = Object.assign([], columnsList)
      
      if (action === "add") {
        columns.push(changedColumn);
      }
      if (action === "remove") {
        columns = columns.filter(item => item !== changedColumn);
     }
    const updatedData = {
      user_id: userData ? userData._id : null,
      table_name: "industrymetric",
      columns_list: columns,
    }
    industrymetricupdatecolumns(updatedData)
    updateColumns(columns)
    },


   


 
  download: false,
 
}

window.addEventListener('keydown', (e) => {

  // e.preventDefault();
  console.log("e.key", e.key)
  if (e.ctrlKey && (e.key === 's' || e.key === 'p')) {
      e.preventDefault();
  }
  // Prevent default actions for Print Screen (PrtScn)
  if (e.key === 'PrintScreen') {
        console.log("print screen")
        e.preventDefault();
  }

  // Prevent default actions for the Windows key
  if (e.key === 'Meta') { // Meta key represents the Windows key
    console.log("meta")
        e.preventDefault();
  }

});


  return (
    <>
<div
            onContextMenu={(e) => {
                e.preventDefault(); // Prevent right-click menu
            }}
            style={{ position: 'relative' }} // Optional: Ensure proper layout
        >
      <div className='container-fluid'>
          <div className='row'>

          <React.Fragment>
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={<div><b>Meetings List</b> <button onClick={addNewMetric} class="btn btn-sm btn-primary">Add New Metric</button></div>}
                        data={getindustrylists}
                        columns={columns}
                        options={options}
                        className="no-select" 
                    />
                </MuiThemeProvider>
            </React.Fragment>
          </div>
      </div>

  
      {showMetricModal ? <AddMetricModal /> : ""}
      </div>
    </>
  )
});

const mapStateToProps = (state) => ({
  user : state.auth.user,
  showMetricModal:state.Industrylist.showMetricModal,
  getindustrylists:state.Industrylist.getindustrylists,
  isFetching : state.Industrylist.isFetching,
  columnsList : state.Industrylist.columnsList,

});

const mapDispatchToProps = (dispatch) => ({
  industrymetricupdatecolumns : (data) => dispatch(updateColumns(data)),
  updateColumns: (data) => dispatch(IndustrysActions.updateColumns(data)),
  getColumnsListData : () => dispatch(getColumnsList()),
  
})


export default connect(mapStateToProps,mapDispatchToProps) (IndustryMetricTable)





