import React, { useContext, useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import AssignDemoModal from '../pageModals/AssignDemoModal';
import moment from 'moment-timezone';
import { getMuiTheme, headerProps } from './commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";
import ViewAccount from "../../components/pageModals/ViewAccount";
import AddTechActivityModal from "../pageModals/AddTechActivityModal";
import {ContactColumnUpdateddata,CommonsliceActions} from "../../store/Common"
import { connect, useDispatch, useSelector } from 'react-redux';
import {startDemolist,completedDemolist,deleteDemoRequestlist,DemoActions,getColumnlist} from "../../store/Demo"

export const DemoTable = React.memo(({user,show_demoModal,showModal_assign,showModal_activity,startDemodata,completeddemo,deleteddemo,contactupdate,demolistdata,isFetching,openmodal,demorowid,viewaccountopenmodal,rowMetaData,rowId,addActivityRowid,activityopenmodal,getColumnlistdata,columnlists,updateColumnsList}) => {
  const Demodata = demolistdata
  const dispatch = useDispatch();
  const userData = user.user;
  const permissions = user.permissions;
  const [leadTypeActive, setLeadTypeActive] = useState("all");
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    getColumnlistdata();
     }, []);

  const startDemo = async (id, element) => {
    var updatedData = {
      'status': "started",
      'demo_id': id
    }
    startDemodata(updatedData)
  }
  const completedDemo = async (id, element) => {
    var updatedData = {
      'status': "completed",
      'demo_id': id
    }
    completeddemo(updatedData)
   
  }
  const assignTechnical = async (event, data, meta) => {
    event.stopPropagation();
    openmodal()

    // dispatch(DemoActions.assign_openModal()); 
    // setDemoListId(meta);

    demorowid({id: meta[0], products: meta[7]})

    //demorowid(meta)

  }
  const onCellClickHandler = (meta) => {
    if (meta.colIndex === 1) {
      viewaccountopenmodal()
      rowMetaData(meta);
      rowId(tableData[meta.dataIndex].account_id?._id)
    }
  }
  useEffect(() => {
    filterDemoType("pending")
    setLeadTypeActive("pending");
  }, [Demodata]);


  const updateActivity =  async (event, data, meta)=> {
      event.stopPropagation();
      addActivityRowid(meta)
      activityopenmodal()
  }
  
  const filterDemoType = async (type) => {
    setLeadTypeActive(type);
    var data = [];
    if (type === "all") {
      data = Demodata;
    } else {
      data = data.filter(function (item) {
        if (item.approval_status === type) {
          return item;
        }
      });
    }
    setTableData(data);
  }
  
  const deleteDemoRequest =  async (event, data, meta) => {
    event.stopPropagation();
      var updatedData = {
        'demo_id': meta,
      }
      deleteddemo(updatedData)  
  }

  const columns = [
    {
      name: "_id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "account_id",
      label: "Organization Name",
      options: {
        customBodyRender: (value) => (
          value ? value.company_name : "Organization Name not found"
        ),
        display: true,
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "user_id",
      label: "Requested by",
      options: {
        customBodyRender: (value) => (
          value ? value.first_name + " " + value.last_name : "user not found"
        ),
        display: columnlists?.includes('user_id') ? true : false,
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "assigned_to",
      label: "Techie Name",
      options: {
        customBodyRender: (value) => (
          value ? value : "Not assigned  yet"
        ),
        display: columnlists?.includes('assigned_to') ? true : false,
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "revenue_of_prospect",
      label: "Revenue of Prospect",
      options: {
        filter: true,
        sort: true,
        display: columnlists?.includes('revenue_of_prospect') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "approval_status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        empty: true,
        display: columnlists?.includes('approval_status') ? true : false,
        onRowClick: false,
        setCellHeaderProps: headerProps,                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
        customBodyRender: (value, tableMeta, updateValue) => {
          var status = tableMeta.rowData[6]
          if (permissions.includes('canAssignDemos')) {
            return (
              <div style={{ display: "flex" }}>
                <a onClick={(e) => { assignTechnical(e, value, tableMeta.rowData) }}>
                  <button className="btn-sm btn-group-sm btn btn-table-commone btn-success">{value === "pending" ? "Assign" : "Re-Assign"}</button>
                </a>

              </div>
            );
          }
          else {
            return <>
                {
                  permissions.includes('deleteOwnDemos') && value === "pending" ? 
                    <button onClick={(e) => deleteDemoRequest(e, value, tableMeta.rowData[0])} className='btn btn-sm btn-danger'>Delete</button>
                    : <span style={{ textTransform: 'capitalize' }}>{value}</span>
                }
            
            </>
          }
        }

      }
    },
    {
      name: "status",
      label: "Actions",
      options: {
        filter: true,
        sort: false,
        empty: true,
        display: columnlists?.includes('status') ? true : false,
        onRowClick: false,
        setCellHeaderProps: headerProps,
        customBodyRender: (value, tableMeta, updateValue) => {
          var status = tableMeta.rowData[5]
          if (value === "pending" && status === "approved") {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
            return (
              <div style={{ display: "flex" }}>                                                                        
                {permissions.includes("updateOwnDemosTimings") || permissions.includes("updateOwnDemosTimings") ?
                  <a onClick={startDemo.bind(value, tableMeta.rowData[0])}>
                    <button style={{ background: '#cd485c' }}  className="btn-sm btn-group-sm btn btn-table-commone btn-danger">Start</button>
                  </a>
                  : false}
              </div>
            );
          } else if (value === "started") {
            return (
              <div style={{ display: "flex" }}>
                {permissions.includes("updateOwnDemosTimings") || permissions.includes("updateOwnDemosTimings") ?
                  <a onClick={completedDemo.bind(value, tableMeta.rowData[0])}>
                    <button style={{ background: '#cd485c' }}  className="btn-sm btn-group-sm btn btn-table-commone btn-danger">Complete</button>
                  </a>
                  : false}
              </div>
            );
          } else if (value === "completed") {
            return "Completed"
          } else {
            return "Approval Pending"
          }
        }

      }
    },
    {
      name: "application",
      label: "Product",
      options: {
        customBodyRender: (value, tableMeta) => (
          value ? value : "Software not found"
        ),
        filter: true,
        sort: true,
        display: columnlists?.includes('application') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "duration",
      label: "Duration",
      options: {
        filter: true,
        sort: true,
        display: columnlists?.includes('duration') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "date_time",
      label: "Demo Start Date & Time",
      options: {
        filter: true,
        sort: true,
        display: columnlists?.includes('date_time') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "status",
      label: "Add Activity",
      options: {
        filter: true,
        sort: false,
        empty: true,
        display: columnlists?.includes('approval_status') ? true : false,
        onRowClick: false,
        setCellHeaderProps: headerProps,
        customBodyRender: (value, tableMeta, updateValue) => {
          var status = tableMeta.rowData[6]
          if (value === "started" && status !== "completed") {
            return (
              <div style={{ display: "flex" }}>
                <a onClick={(e) => { updateActivity(e, value, tableMeta.rowData[0]) }}>
                  <button className="btn-sm btn-group-sm btn btn-table-commone btn-success">Add Activity</button>
                </a>
              </div>
            );
          }
          else {
            return <span style={{ textTransform: 'capitalize' }}>{value}</span>;
          }
        }

      }
    },
    {
      name: "closure_date",
      label: "Deal Closure date",
      options: {
        filter: true,
        sort: true,
        display: columnlists?.includes('closure_date') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "economic_impact_of_solution",
      label: "Economic Impact",
      options: {
        filter: true,
        sort: true,
        display: columnlists?.includes('economic_impact_of_solution') ? true : false,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
            color: '#243d6a',
            maxWidth: '500px',
            overflow: 'auto',
            fontWeight: 'bold'
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 1,
            background: "#e6e6e6",
            zIndex: 101,
            textColor: '#FFFFFF',
            maxWidth: '500px',
            overflow: 'auto',
          }
        }),
      }
    },
    {
      name: "economic_buyer",
      label: "Economic Buyer",
      options: {
        filter: true,
        sort: true,
        display: columnlists?.includes('economic_buyer') ? true : false,
        customBodyRender: (value, tableMeta) => (
          value ? value.contact_name : "Contact Name not found"

        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "decision_criteria",
      label: "Decision Criteria",
      options: {
        filter: true,
        sort: true,
        display: columnlists?.includes('decision_criteria') ? true : false,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
            color: '#243d6a',
            maxWidth: '500px',
            overflow: 'auto',
            fontWeight: 'bold'
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 1,
            background: "#e6e6e6",
            zIndex: 101,
            textColor: '#FFFFFF',
            maxWidth: '500px',
            overflow: 'auto',
          }
        }),
      }
    },
    {
      name: "timeline_validation_approval",
      label: "Timeline Validation Approval",
      options: {
        filter: true,
        sort: true,
        display: columnlists?.includes('timeline_validation_approval') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "pain_areas_compelling_events",
      label: "Pain Areas Compelling Events",
      options: {
        filter: true,
        sort: true,
        display: columnlists?.includes('pain_areas_compelling_events') ? true : false,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
            color: '#243d6a',
            maxWidth: '500px',
            overflow: 'auto',
            fontWeight: 'bold'
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 1,
            background: "#e6e6e6",
            zIndex: 101,
            textColor: '#FFFFFF',
            maxWidth: '500px',
            overflow: 'auto',
          }
        }),
      }
    },
    {
      name: "sell_on_behalf_inside_company",
      label: "Sell on behalf Inside Organization",
      options: {
        filter: true,
        sort: true,
        display: columnlists?.includes('sell_on_behalf_inside_company') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "initial_discussion",
      label: "Comments",
      options: {
        filter: true,
        sort: true,
        display: columnlists?.includes('initial_discussion') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
  ];
  const LoaderText = () => {
    return   isFetching ? <div className="parent-div">
    <div className="loader"></div>
  </div>:"No Data Found.." 
 }
  const options = {
    textLabels: {
      body: {
        noMatch: LoaderText()
      }
    },
    rowsPerPage: window.innerHeight > 720 ? 20 : 15,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: (window.innerHeight - 130).toString() + 'px',
    tableBodyMaxHeight: (window.innerHeight - 130).toString() + 'px',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: "none",
    print: false,

    onViewColumnsChange: async (changedColumn, action) => {

      var columns = Object.assign([], columnlists)
      if (action === "add") {
        columns.push(changedColumn);
      }
      if (action === "remove") {

        // var index = columns.indexOf(changedColumn);
        // if (index !== -1) {
        //   columns.splice(index, 1);
        // }
        var columns = columns.filter(item => item !== changedColumn);
      }


      const updatedData = {
        user_id: userData._id,
        table_name: "demos",
        columns_list: columns,
      }
      contactupdate(updatedData)
      updateColumnsList(columns)

    },

    download: false,
    onCellClick: (colData, cellMeta) => onCellClickHandler(cellMeta),
  };

  window.addEventListener('keydown', (e) => {

    // e.preventDefault();
    console.log("e.key", e.key)
    if (e.ctrlKey && (e.key === 's' || e.key === 'p')) {
        e.preventDefault();
    }
    // Prevent default actions for Print Screen (PrtScn)
    if (e.key === 'PrintScreen') {
          console.log("print screen")
          e.preventDefault();
    }
  
    // Prevent default actions for the Windows key
    if (e.key === 'Meta') { // Meta key represents the Windows key
      console.log("meta")
          e.preventDefault();
    }

  });

  return (
    <>
    <div
            onContextMenu={(e) => {
                e.preventDefault(); // Prevent right-click menu
            }}
            style={{ position: 'relative' }} // Optional: Ensure proper layout
        >
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={<div style={{ display: "flex", flexDirection: "row" }} >
          <b>Demos List</b>&nbsp;&nbsp;&nbsp;
          <span onClick={() => filterDemoType("all")} className={leadTypeActive === "all" ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> All </span>&nbsp;&nbsp;
          <span onClick={() => filterDemoType("pending")} className={leadTypeActive === "pending" ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> Pending </span>&nbsp;&nbsp;
        </div>}
          data={tableData}
          columns={columns}
          options={options}
          className="no-select" 
        />
      </MuiThemeProvider>
      {showModal_assign ? <AssignDemoModal /> : ""}
      {showModal_activity ? <AddTechActivityModal /> : ""}
      {show_demoModal ? <ViewAccount /> : ""}
      </div>
    </>
  )
});

const mapStateToProps = (state) => ({
    user : state.auth.user,
     demolistdata:state.Demolist.demolistdata,
     getdemocolumn:state.Demolist.getdemocolumn,
     isFetching : state.Demolist.isFetching,
     show_demoModal : state.Commonlist.demo_showModal,
     showModal_assign:state.Demolist.showModal_assign,
     showModal_activity: state.Commonlist.showModal_activity,
     showModal_Viewcontact : state.Demolist.showModal_Viewcontact,
     columnlists : state.Demolist?.columnlists
  });
  
  const mapDispatchToProps = (dispatch) => ({
    startDemodata :(data) => dispatch(startDemolist(data)),
    completeddemo :(data) => dispatch(completedDemolist(data)),
    deleteddemo :(data) => dispatch(deleteDemoRequestlist(data)),
    contactupdate:(data) => dispatch(ContactColumnUpdateddata(data)),
    openmodal:(data) => dispatch(DemoActions.assign_openModal(data)),
    demorowid:(data) => dispatch(DemoActions.setDemoRowId(data)),
    viewaccountopenmodal:(data) => dispatch(CommonsliceActions.demo_openModal(data)),
    rowMetaData:(data) => dispatch(CommonsliceActions.setrowMetaData(data)),
    rowId:(data) => dispatch(CommonsliceActions.setRowId(data)),
    addActivityRowid:(data) => dispatch(CommonsliceActions.setaddActivityRowId(data)),
      activityopenmodal:(data) => dispatch(CommonsliceActions.activity_openModal(data)),
      getColumnlistdata:() => dispatch(getColumnlist()),
      updateColumnsList:(data) => dispatch(DemoActions.updateColumnsList(data))
  });
  
  export default connect(mapStateToProps,mapDispatchToProps) (DemoTable)

